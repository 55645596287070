import { api } from "api/settings"
import { resources } from "api/resources"
import { get, post, put, del } from "api/index"
import { StatePayload } from "api/models/customer-journey"
import { noop } from "lodash-es"

export default class CustomerJourneyClient {

	private static readonly customerJourneyEndpoint = `${ api.endpoint }${ resources.company.customerJourney }`

	async getStateById<T>(id: string, converter: (value: any) => StatePayload<T>): Promise<StatePayload<T> | undefined> {
		return get(`${ CustomerJourneyClient.customerJourneyEndpoint }/${ id }`)
			.then(async response => converter(await response.json()))
			.catch(() => undefined)
	}

	async createState<T>(id: string, state: StatePayload<T>): Promise<void> {
		post(`${ CustomerJourneyClient.customerJourneyEndpoint }/${ id }`, state)
			.then(noop)
			.catch(noop)
	}

	async updateState<T>(id: string, state: StatePayload<T>): Promise<void> {
		put(`${ CustomerJourneyClient.customerJourneyEndpoint }/${ id }`, state)
			.then(noop)
			.catch(noop)
	}

	async deleteState(id: string): Promise<void> {
		del(`${ CustomerJourneyClient.customerJourneyEndpoint }/${ id }`)
			.catch(noop)
	}
}
