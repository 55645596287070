import Vue from "vue"
import Vuex, { Store } from "vuex"
import ModelFactory from "@lib/model/store/ModelFactory"
import { storageOptions, storageController } from "@lib/storage/session"
import { Documents } from "./documents/types"
import messages from "./messages"
import company from "./company"
import documents from "./documents"
import authentication from "./authentication"
import participant from "./participant"
import user from "./user"
import premium from "./premium"
import eventBus from "@lib/vue/eventBus"
import { IDENTIFICATION_FAILED, AUTHORIZATION_FAILED, ACCESS_REVOKED, ACCESS_GRANTED } from "@lib/vue/events"
import { MutationType } from "./authentication/mutations"
import load from "./load"
import persist from "@lib/vuex/plugins/persist"
import { USER_MUTATIONS } from "./user"
import { AUTHENTICATION_METHOD_KEY, AuthenticationMethod } from "utils/session-factory"
import localStorageClient from "api/clients/local-storage-client"

Vue.use(Vuex)

export interface RootState {
	documents: Documents
}

export const namespaces = {
	messages: "messages",
	company: "company",
	documents: "documents",
	authentication: "authentication",
	contactDetails: "contactDetails",
	participant: "participant",
	user: "user",
	premium: "premium"
}

export enum ROOT_ACTIONS {
	INITIALIZE = "INITIALIZE"
}

const loadingTimeout = 500

const plugins = [
	persist({
		...storageOptions,
		key: "persist",
		whitelist: [
			namespaces.documents,
			namespaces.messages,
			namespaces.authentication,
			namespaces.company,
			namespaces.user,
			namespaces.premium
		],
		deactivate: s => !s.state[namespaces.authentication].loggedIn
	}),
	async (store: Store<RootState>) => {
		eventBus.on([IDENTIFICATION_FAILED, AUTHORIZATION_FAILED, ACCESS_REVOKED], async () => {
			// FIXME: Find a better way to do this.
			if (!process.env.SERVER) {
				storageController.discard("session")
				sessionStorage.clear()
				if (!location.href.includes("login.html") && !location.href.includes("activate-account.html")) {
					await store.dispatch("authentication/logout")
					location.href = "./login.html"
				}
			}
		})
		eventBus.on(ACCESS_GRANTED, async () => {
			await store.dispatch(ROOT_ACTIONS.INITIALIZE)
		})

	}
]

const rootStore = new Store<RootState>({
	modules: {
		[namespaces.messages]: messages,
		[namespaces.company]: company,
		[namespaces.documents]: documents,
		[namespaces.authentication]: authentication,
		[namespaces.participant]: participant,
		[namespaces.user]: user,
		[namespaces.premium]: premium
	},
	plugins,
	actions: {
		async [ROOT_ACTIONS.INITIALIZE](context): Promise<void> {
			context.commit(`${ namespaces.authentication }/${ MutationType.LOGIN }`)
			context.commit(`${ namespaces.authentication }/${ MutationType.TIMESTAMP }`)
			context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_LOADING }`, true)
			const faAuthentication = localStorageClient.getItem("2faAuthentication")
			if(faAuthentication === "1")
			{
				context.commit(`${namespaces.user}/${USER_MUTATIONS.SET_LOADING}`, false)
			} else {
				return load(context)
					.then(() =>  {
						const authMethod = localStorageClient.getItem(AUTHENTICATION_METHOD_KEY) as AuthenticationMethod
						if (authMethod === AuthenticationMethod.CREDENTIALS || authMethod === AuthenticationMethod.CONTROL_ROOM) {
							context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_LOADING }`, false)
							if (!process.env.SERVER && (location.href.includes("login.html") || location.href.includes("impersonate.html") || location.href.includes("activate-account.html"))) {
								location.href = "./index.html"
							}
						}
					})
					.catch(async error => {
						await context.dispatch(`${ namespaces.authentication }/logout`) // Logout the user since his/her data is not loaded correctly.
						context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_ERROR }`, true)

						throw error
					})
					.finally(() => setTimeout(() => context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_LOADING }`, false), loadingTimeout))
			}
		}
	}
})

export default rootStore

const factory = new ModelFactory<RootState>(rootStore, storageOptions)

export const expire = (): void => factory.expire()
