import { RequestMethod, Parameters, ContentType, RequestOptions, RequestHeader, Request } from "../types/request"

import { queryString, formData, containsFile, json, headers } from "../request/parameters"

import { isUndefined } from "lodash-es"

/**
 * A wrapper around `fetch` that provides a slightly more intuitive interface and handles file uploads and data serialization
 * depending on the `Content-Type` header.
 */
export default (
	method: RequestMethod,
	input: string,
	data?: Parameters,
	options?: RequestOptions
): Request => {
	const requestInit: RequestInit = {
		method,
		...options
	}

	requestInit.headers = headers(options?.headers || {})
	let query: string = ""

	if (!isUndefined(data)) {
		const requestHeaders = requestInit.headers as Headers
		if (method === RequestMethod.GET || method === RequestMethod.DELETE || method === RequestMethod.HEAD) {
			query = queryString(data)
			requestInit.body = null
		} else if (containsFile(data)) {
			// File upload.
			requestInit.body = formData(data)
			// The request fails in the presence of a content type header.
			requestHeaders.delete(RequestHeader.CONTENT_TYPE)
		} else {

			switch (requestHeaders.get(RequestHeader.CONTENT_TYPE)) {
				case ContentType.APPLICATION_JSON:
					requestInit.body = json(data)
					break
				case ContentType.APPLICATION_FORM_URLENCODED:
					requestInit.body = formData(data)
					break
			}
		}
	}

	const controller = new AbortController()
	requestInit.signal = controller.signal

	const abort = (): void => {
		controller.abort()
	}

	const response = fetch(input + query, requestInit)
	return { response, abort }
}
