
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	import PIcon from "@portal-components/ui/PIcon.vue"
	import PNavigationSubTabItem, { SUB_TAB_COMPONENT_TAG } from "@portal-components/ui/navigation/PNavigationSubTabItem.vue"

	@Component({
		components: {
			PIcon
		}
	})
	export default class PNavigationTabItem extends Vue {
		@Prop({ type: Boolean, default: false }) mobile!: boolean
		@Prop({ type: String }) title?: string
		@Prop({ type: String }) id?: string

		active = false
		activeSubTabIndex = 0

		mounted(): void {
			this.setActiveSubTab(0)
		}

		get subTabs(): Array<PNavigationSubTabItem> {
			return this.$children.filter(child => child.$vnode.componentOptions?.tag?.includes(SUB_TAB_COMPONENT_TAG)) as Array<PNavigationSubTabItem>
		}

		setActiveSubTab(activeSubTabIndex: number): void {
			if (!this.subTabs) {
				return
			}

			for (const [index, subTab] of this.subTabs.entries()) {
				subTab.active = activeSubTabIndex === index
			}

			this.activeSubTabIndex = activeSubTabIndex
		}
	}
