export enum CustomerJourneySlide {
	INTRO = "INTRO",
	OUTRO = "OUTRO"
}

export enum CustomerJourneyStatus {
	PENDING = "PENDING",
	STARTED = "STARTED",
	COMPLETED = "COMPLETED"
}

export interface StepState {
	startDateTime?: Date
	endDateTime?: Date
	weight: number
}

export interface StepStates {
	[key: string]: StepState
}

export interface StatePayload<T> {
	version: string
	startDateTime?: Date
	endDateTime?: Date
	status: CustomerJourneyStatus
	data: T,
	steps: StepStates
}

export interface OutroItem {
	title: string,
	description: string,
	toStep?: string,
	url?: string,
	newTab?: boolean
}
