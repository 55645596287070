
import { GetterTree } from "vuex"
import { RootState } from "store/index"
import { User, UserState } from "./types"

const getters: GetterTree<UserState, RootState> = {
	user(state: UserState): User {
		return state.user
	},
	isUserLoading(state: UserState): boolean {
		return state.loading
	},
	hasError(state: UserState): boolean {
		return state.hasError
	},
	id(state: UserState): string | undefined {
		return state.user.id
	}
}

export default getters
