
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Watch } from "vue-property-decorator"

	import PNavigationTabItem from "@portal-components/ui/navigation/PNavigationTabItem.vue"
	import PIcon from "@portal-components/ui/PIcon.vue"
	import PCollection from "@portal-components/generic/PCollection.vue"

	@Component({
		components: {
			PNavigationTabItem,
			PIcon,
			PCollection
		}
	})
	export default class PNavigationTabs extends Vue {
		activeTabIndex = 0
		activeSubTabIndex = 0
		tabs: Array<PNavigationTabItem> = []

		get navigationTabsStyle(): string {
			return `transform: translateX(${ this.activeTabIndex * -100 }%)`
		}

		get tabItems(): Array<PNavigationTabItem> {
			return this.tabs.filter(tab => !tab.mobile)
		}

		get hasSubTabs(): boolean {
			return this.tabItems.some(tab => tab.subTabs.length > 0)
		}

		mounted(): void {
			this.tabs = this.$children as Array<PNavigationTabItem>
			this.activeTabIndexChanged()
		}

		@Watch("activeTabIndex")
		activeTabIndexChanged(): void {
			for (const [index, tab] of this.tabItems.entries()) {
				tab.active = index === this.activeTabIndex
			}
		}

		setActiveTab(id: string): void {
			const tab = this.tabItems.find(t => t.id === id)

			if (!tab) {
				return
			}

			this.activeTabIndex = this.tabItems.indexOf(tab)
		}
	}
