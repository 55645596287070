
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch, Emit } from "vue-property-decorator"

	import { HTMLEvent } from "@portal-components/ui/utils/events"

	import { isArray, isUndefined } from "lodash-es"

	interface FileUpload extends EventTarget {
		files: FileList
	}

	@Component
	export default class PFileUpload extends Vue {
		@Prop({ type: String, default: "*.*" }) accepted!: string
		@Prop({ type: Boolean, default: false }) multiple!: boolean
		@Prop({ type: Boolean, default: false }) noLabel!: boolean
		@Prop({ required: false }) value: Array<File> | File | undefined

		internalValue: Array<File> = []
		fieldValue = ""

		@Watch("value", { immediate: true })
		valueChange(newValue: Array<File> | File | undefined): void {
			if (isUndefined(newValue)) {
				this.internalValue = []
				return
			}
			this.internalValue = isArray(newValue) ? newValue as Array<File> : [newValue] as Array<File>
		}

		@Emit(HTMLEvent.INPUT)
		onChange(event: Event): Array<File> | File | undefined {
			this.internalValue = this.extractFiles(event.target as FileUpload)
			this.fieldValue = ""
			return this.multiple ? this.internalValue : this.internalValue[0]
		}

		get fileNames(): string {
			return this.internalValue.map(file => file?.name).join(", ")
		}

		private extractFiles(target: FileUpload): Array<File> {
			return Array.from(target.files)
		}
	}
