export const AUTHORIZATION_FAILED = "authorization-failed"
export const IDENTIFICATION_FAILED = "identification-failed"
export const ACCESS_DENIED = "access-denied"
export const ACCESS_GRANTED = "access-granted"
export const ACCESS_GRANTED_SSO = "access-granted-sso"
export const ACCESS_RESTORED = "access-restored"
export const ACCESS_REVOKED = "access-revoked"
export const ACCESS_ALLOWED = "access-allowed"
export const SESSION_TIMEOUT_REACHED = "session-timeout-reached"
export const REQUIRES_2FA = "requires-2fa"
export const LOADED = "app-loaded"

export const GATEWAY_ERROR = "gateway-error"
