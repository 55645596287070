import { ActionTree, ActionContext } from "vuex"
import { Documents } from "./types"
import { RootState } from ".."
import { MutationType } from "./mutations"
import convertDocuments from "./conversion"
import { getJson, getBlob } from "api/index"
import { resources } from "api/resources"
import { api } from "api/settings"

const documentsResources = resources.documents

const actions: ActionTree<Documents, RootState> = {
	async getDocuments(context: ActionContext<Documents, RootState>, bucket: string): Promise<void> {
		const body = await getJson(api.endpoint + documentsResources.getDocuments(bucket))
		const documents = convertDocuments(body) || []

		context.commit(MutationType.HYDRATE, [...documents].sort((a, b) => b.created.getTime() - a.created.getTime()))
	},
	async downloadAttachment(context: ActionContext<Documents, RootState>, documentId: string): Promise<Blob> {
		const blob = await getBlob(api.endpoint + documentsResources.getDocument(documentId))
		context.commit(MutationType.DOWNLOADS, documentId)

		return blob
	}
}

export default actions
