
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Emit } from "vue-property-decorator"

	const validatorFn = (v: string) => ["primary", "secondary"].includes(v)

	@Component
	export default class NextStepButton extends Vue {
		@Prop({ type: String, default: "primary", validator: validatorFn }) type!: string

		@Emit("click")
		onClick(event: MouseEvent): MouseEvent {
			return event
		}
	}
