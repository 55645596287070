
	import LoadingComponent from "custom/LoadingComponent"
	import Component from "vue-class-component"
	import { Emit, Prop, Ref } from "vue-property-decorator"
	import CustomerJourneyStep from "custom/customer-journey/CustomerJourneyStep.vue"

	import Wizard from "custom/wizard/Wizard.vue"
	import WizardSlide from "custom/wizard/WizardSlide.vue"
	import PButton from "@portal-components/ui/input/PButton.vue"
	import PFileUpload from "@portal-components/ui/input/PFileUpload.vue"

	import SignUpInstructions from "custom/customer-journey/sign-up-employees/SignUpInstructions.vue"
	import DownloadTemplate from "custom/customer-journey/sign-up-employees/DownloadTemplate.vue"
	import UploadSheet from "custom/customer-journey/sign-up-employees/UploadSheet.vue"

	import Loader from "custom/Loader.vue"
	import { SignUpEmployeesVariant } from "pages/SignUpEmployees.vue"
	import Vue from "vue"

	@Component({
		components: {
			CustomerJourneyStep,
			Wizard,
			WizardSlide,
			SignUpInstructions,
			PButton,
			PFileUpload,
			DownloadTemplate,
			Loader,
			UploadSheet
		}
	})
	export default class SignUpEmployeesWizard extends LoadingComponent {
		@Prop({ type: String, required: true }) variant!: SignUpEmployeesVariant

		@Ref() readonly uploadSheet!: UploadSheet

		employeeData: { file?: File } = {
			file: undefined
		}

		currentSlide = 0
		showSuccess = false
		showUploadButton = false
		showFinishButton = false
		dateSelected = new Date()

		readonly baseTranslationKey = "signUpEmployees.provideEmployeeData"

		get showNextButton(): boolean {
			return this.currentSlide === 0 || this.currentSlide === 1
		}

		get showWarning(): boolean {
			return this.currentSlide === 0 && [SignUpEmployeesVariant.SIGN_UP, SignUpEmployeesVariant.START].includes(this.variant)
		}

		getExplanationText(baseTranslationKey: string): string {
			const translationKey = `${ baseTranslationKey }`
			const translatedValue = Vue.filter("locale")(translationKey)
			return Vue.filter("template")(translatedValue)
		}

		nextSlide(): void {
			this.currentSlide++
		}

		previousSlide(): void {
			this.showFinishButton = false
			this.currentSlide--
		}

		async upload(): Promise<void> {
			await this.manageLoadingState(this.uploadSheet.upload(this.dateSelected))
		}

		onDateSelected(dateChanged: Date): void {
			this.dateSelected = dateChanged
		}

		onFileSelected(fileSelected: boolean): void {
			this.showUploadButton = fileSelected

			if (fileSelected) {
				this.showFinishButton = false
			}
		}

		onUploadFinished(): void {
			this.showFinishButton = true
		}

		@Emit("next-step")
		onNextClick(event: MouseEvent): MouseEvent {
			return event
		}
		@Emit("data-uploaded")
		onShowSuccess(): void {
			this.showSuccess = true
		}
	}
