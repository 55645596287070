import arrow from "assets/svg/icoon_arrow.svg"
import checkmark from "assets/svg/checkmark.svg"
import chevron from "assets/svg/chevron.svg"
import document from "assets/svg/default_document.svg"
import folder from "assets/svg/icoon_dossier.svg"
import pencil from "assets/svg/icoon_aanvragen.svg"
import envelope from "assets/svg/icoon_inbox.svg"
import envelopeFilled from "assets/svg/default_envelope-filled.svg"
import oldAgePension from "assets/svg/ouderdomspensioen.svg"
import partnerPension from "assets/svg/partnerpensioen.svg"
import person from "assets/svg/icoon_profiel.svg"
import repeat from "assets/svg/icoon_repeat.svg"
import send from "assets/svg/icoon_send_static.svg"
import star from "assets/svg/star.svg"
import suitcase from "assets/svg/default_suitcase.svg"
import piggybank from "assets/svg/icoon_pensioengetallen.svg"
import mapsFilled from "assets/svg/icon_maps-filled.svg"
import arrowBlue from "assets/svg/arrow-right-blue.svg"
import intertwinedArrows from "assets/svg/intertwined_arrows.svg"

export const iconMap = {
	arrow,
	arrowBlue,
	checkmark,
	chevron,
	envelope,
	envelopeFilled,
	oldAgePension,
	partnerPension,
	person,
	repeat,
	star,
	suitcase,
	pencil,
	folder,
	piggybank,
	mapsFilled,
	send,
	document,
	intertwinedArrows
}

export type IconType = keyof typeof iconMap
