import { resources } from "api/resources"
import { api } from "api/index"
import { FileType } from "store/types"
import { convertExcelUploadResponse } from "api/conversions/customer-journey/sign-up-employees"

export interface Cell {
	row: string
	column: string
}

export interface ErrorParam {
	cell?: Cell
	name: string
	reason: string
}

export enum ErrorType {
	SEMANTIC = "Semantic error",
	SYNTACTIC = "Syntax error",
	INVALID_INPUT = "Invalid input"
}

export interface ResponseErrors {
	detail: string
	errors?: Array<string>
	warnings?: Array<string>
	params?: Array<ErrorParam>
	status: string
	title: ErrorType
	type?: string
}
export interface ExcelUploadResponse {
	detail: string
	errors?: Array<string>
	warnings?: Array<string>
	status: string
	title: string
	type?: string
}

class SignUpEmployeesClient {
	async uploadParticipantsSheet(file: FileType): Promise<ExcelUploadResponse | null> {
		const response = await api.post(resources.company.uploadParticipantData, file).response

		return this.handleUploadResponse(response)
	}

	async validateParticipantsSheet(file: FileType): Promise<ExcelUploadResponse | null> {
		const response = await api.post(resources.company.validateParticipantData, file).response

		return this.handleUploadResponse(response)
	}

	async uploadYearlySalarySheet(file: FileType, date: Date): Promise<ExcelUploadResponse | null> {
		const response = await api.post(resources.company.uploadYearlySalarySheet, {file, date}).response

		return this.handleUploadResponse(response)
	}

	private async handleUploadResponse(response: Response): Promise<ExcelUploadResponse | null> {
		if (response.ok) {
			return null
		} else if (response.status === 400) {
			return convertExcelUploadResponse(await response.json())
		} else if (response.status === 500) {
			throw await response.json()
		}

		throw new Error("Error occured while uploading")
	}
}

export default new SignUpEmployeesClient()
