
	import Vue from "vue"
	import Component from "vue-class-component"
	import EmployeeTable from "custom/employees/EmployeeTable.vue"

	@Component({
		components: {
			EmployeeTable
		}
	})
	export default class EmployeeOverview extends Vue {
	}
