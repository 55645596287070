import { isKeyValuePairs, isFile } from "../misc/guards"
import { KeyValuePairs, Parameters } from "../types/request"
import adjust, { Adjust } from "../date/adjust"

import { isDate, isString, isUndefined } from "lodash-es"

interface Constructor<T> {
	prototype: T
	new(): T
}

const entries = (source: Parameters): Iterable<[string, unknown]> => isKeyValuePairs(source) ? source : Object.entries(source)
const valuesOf = (source: Parameters): ReadonlyArray<unknown> => isKeyValuePairs(source) ? [...source.values()] : Object.values(source)
const toJSONStringifiable = (parameters: Parameters): any => isKeyValuePairs(parameters) ? Object.fromEntries(parameters) : parameters

/**
 * Transforms dates and ISO date strings into ISO date strings in UTC, leaves other values untouched.
 */
const prepare = (value: unknown): unknown => {
	// JSON.stringify already calls `toISOString` on any dates before we get our hands on them.
	const revived = isString(value) && /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/.test(value) ? new Date(value) : value
	if (isDate(revived)) {
		return adjust(Adjust.LOCAL_TO_UTC, revived).toISOString()
	}
	return value
}

/**
 * Constructs an object of type `T`, which implements `KeyValuePairs`, from the given source object.
 *
 * Its intended use is to create `URLSearchParams`, `FormData` and `Headers` objects.
 */
const construct = <T extends KeyValuePairs>(ResultType: Constructor<T>, source: Parameters): T => {
	if (source instanceof ResultType) {
		return source
	}
	const destination = new ResultType()
	for (const [key, value] of entries(source)) {
		if (!isUndefined(value)) {
			destination.append(key, prepare(value))
		}
	}
	return destination
}

export const urlSearchParams = (parameters: Parameters): URLSearchParams => construct(URLSearchParams, parameters)
export const formData = (parameters: Parameters): FormData => construct(FormData, parameters)
export const headers = (parameters: Parameters): Headers => construct(Headers, parameters)

export const queryString = (parameters: Parameters): string => {
	const encoded = urlSearchParams(parameters).toString()
	return encoded ? `?${ encoded }` : encoded
}

export const containsFile = (parameters: Parameters): boolean => valuesOf(parameters).some(isFile)
export const json = (parameters: Parameters): string => JSON.stringify(toJSONStringifiable(parameters), (_, value) => prepare(value))
