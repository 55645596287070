import Session from "utils/security/Session"
import CredentialsIdentification from "lib/security/identification/CredentialsIdentification"
import { loginUrl, eRecognitionConfig } from "api/settings"
import { openGateway, authorization } from "api/index"
import { storageOptions } from "@lib/storage/session"
import { IdentificationStrategy } from "lib/types/security"
import ERecognitionIdentification from "utils/ERecognitionIdentification"
import localStorageClient from "api/clients/local-storage-client"

export enum AuthenticationMethod {
	CREDENTIALS = "CREDENTIALS",
	ERECOGNITION = "ERECOGNITION",
	CONTROL_ROOM = "CONTROL_ROOM"
}

export const AUTHENTICATION_METHOD_KEY = "AUTHENTICATION_METHOD"

class SessionFactory {
	private localSession?: Session<unknown>

	get session(): Session<unknown> {
		if (!this.localSession) {
			this.localSession = this.createSession()
		}

		return this.localSession
	}

	private createSession(): Session<unknown> {
		const authenticationMethod = localStorageClient.getItem(AUTHENTICATION_METHOD_KEY)

		let identification: IdentificationStrategy<unknown>

		if (authenticationMethod === AuthenticationMethod.ERECOGNITION) {
			identification = new ERecognitionIdentification(eRecognitionConfig.samlUrl, openGateway, eRecognitionConfig.tokenUrl)
		} else {
			identification = new CredentialsIdentification(openGateway, { identification: loginUrl })
		}

		return new Session(storageOptions, identification, authorization)
	}
}

export const sessionFactory = new SessionFactory()
