
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch, Emit } from "vue-property-decorator"

	import { HTMLEvent } from "@portal-components/ui/utils/events"

	@Component
	export default class PCheckbox extends Vue {
		@Prop({ type: Boolean, default: false }) toggle!: boolean
		@Prop({ type: Boolean, default: false }) inline!: boolean
		@Prop({ type: Boolean, default: false }) disabled!: boolean
		@Prop({ type: Boolean, default: false }) value!: boolean

		internalValue = false

		@Watch("value", { immediate: true })
		valueChanged(): void {
			this.internalValue = this.value
		}

		@Emit(HTMLEvent.INPUT)
		onChange(): boolean {
			return this.internalValue
		}
	}
