
	import Component from "vue-class-component"
	import { Prop, Watch, Emit, Ref } from "vue-property-decorator"

	import PInput from "@portal-components/ui/input/PInput.vue"
	import { HTMLEvent } from "@portal-components/ui/utils/events"

	@Component
	export default class PNumberInput extends PInput {
		@Prop({ default: null }) value!: number
		@Prop({ type: Number }) max?: string
		@Prop({ type: Number }) min?: string
		@Prop({ type: Number }) stepSize?: string

		@Ref() internalInput!: HTMLInputElement
		private static readonly allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ",", "."]

		internalValue: any = ""

		@Watch("value", { immediate: true })
		valueChanged(newValue: number | string): void {
			if (!newValue) { return }
			this.internalValue = newValue
		}

		@Emit(HTMLEvent.INPUT)
		onChange(): string {
			return this.internalValue
		}

		focus(): void {
			this.internalInput.focus()
		}

		checkAllowedKeys(event: KeyboardEvent): void {
			if (!PNumberInput.allowedKeys.includes(event.key)) {
				event.preventDefault()
			}
		}

		disableMultipleComma(event: KeyboardEvent): void {
			if (this.internalValue.match(/,{1,}/g) && (event.key === "," || event.key === ".")) {
				event.preventDefault()
			}
		}

		formatField(): void {
			this.internalValue = this.internalValue.replace(".",",")
		}
	}
