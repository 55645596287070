
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import PIcon from "@portal-components/ui/PIcon.vue"

	export const SUB_TAB_COMPONENT_TAG = "p-navigation-sub-tab-item"

	@Component({
		components: {
			PIcon
		},
		name: SUB_TAB_COMPONENT_TAG
	})
	export default class PNavigationSubTabItem extends Vue {
		@Prop({ type: String }) title?: string

		active = false
	}
