
	import Vue from "vue"
	import Component from "vue-class-component"
	import { namespace } from "vuex-class"

	import PIcon from "@portal-components/ui/PIcon.vue"
	import IconLabel from "@components/supplemental/IconLabel.vue"
	import ActionButton from "@components/button/ActionButton.vue"
	import Badge from "@components/supplemental/Badge.vue"
	import Card from "@components/panel/Card.vue"
	import LinkButton from "@components/button/LinkButton.vue"
	import Modal from "@components/modal/Modal.vue"
	import eventBus from "@lib/vue/eventBus"
	import { namespaces } from "store/index"
	import { Agreement } from "store/company/types"
	import { companyModule } from "store/modules"
	import { Ref } from "vue-property-decorator"

	const documents = namespace(namespaces.documents)

	@Component({
		components: {
			ActionButton,
			Badge,
			Card,
			IconLabel,
			LinkButton,
			Modal,
			PIcon
		}
	})
	export default class MenuModal extends Vue {
		@Ref() private readonly modal!: Modal
		@documents.Getter unreadDocuments!: number
		@companyModule.Getter agreement?: Agreement

		readonly baseTranslationKey = "menu"

		open = false

		opened(): void {
			this.open = true
			this.$emit("opened")
		}

		closed(): void {
			this.open = false
			this.$emit("closed")
		}

		mounted(): void {
			eventBus.on("menuModal", () => {
				if (this.open) {
					this.modal.close()
					this.open = false
				} else {
					this.modal.display()
					this.open = true
				}
			})
		}
	}
