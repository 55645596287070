import { AuthorizationStrategy } from "../types/security"
import { RequestMethod, Parameters, Request, RequestOptions } from "../types/request"
import eventBus from "../vue/eventBus"
import { AUTHORIZATION_FAILED, ACCESS_DENIED } from "../vue/events"

import RequestStrategy from "../request/RequestStrategy"
import HttpStatus from "../request/status"

import { MessageEventStream } from "../types/stream"

export default class AuthorizedGateway implements RequestStrategy {

	constructor(private readonly authorization: AuthorizationStrategy) { }

	request(method: RequestMethod, input: string, data?: Parameters, options?: RequestOptions): Request {
		const request = this.authorization.request(method, input, data, options)
		return {
			...request,
			response: request.response.then(response => {
				switch (response.status) {
					case HttpStatus.UNAUTHORIZED:
						eventBus.emit(AUTHORIZATION_FAILED)
						break
					case HttpStatus.FORBIDDEN:
						eventBus.emit(ACCESS_DENIED)
						break
				}
				return response
			})
		}
	}

	openStream(endpoint: string): MessageEventStream {
		return this.authorization.openStream(endpoint)
	}
}
