enum HttpStatus {
	OK = 200,
	CREATED = 201,
	PARTIAL_CONTENT = 206,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404
}

export default HttpStatus
