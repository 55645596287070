
	import Vue from "vue"
	import Component from "vue-class-component"

	import { JwtToken } from "@lib/types/security"

	import ResponseCode from "custom/login/ResponseCode.vue"

	import twoFactorAuthenticationClient from "api/clients/2fa-client"

	@Component({
		components: {
			ResponseCode
		}
	})
	export default class QrCode extends Vue {
		readonly baseTranslationKey = "activateAccount"
		readonly loginTranslationKey = "login"

		qrCode: string | null = null

		async mounted(): Promise<void> {
			this.qrCode = await twoFactorAuthenticationClient.getQrCode()
		}

		onResponseCodeVerified(token: JwtToken): void {
			this.$emit("response-code-verified", token)
		}
	}
