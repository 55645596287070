import {
	ObjectRules,
	FieldValidator,
	FieldRules,
	FieldErrors,
	ScalarErrors,
	ObjectValidator,
	Datatype,
	ObjectErrors,
	Validator,
	ErrorCode
} from "../types/validation"
import { ConversionFunction } from "../types/import"
import { Function1 } from "../types/function"

import { datetime, alphanumeric, numeric, bool, file } from "../import/convert"

import entries from "../misc/entries"
import objectValues from "../misc/values"

import { isEmpty, isNil, isUndefined, isFunction, isArray, isPlainObject, isString } from "lodash-es"

export const ruleTypes: ReadonlyArray<keyof FieldRules<keyof any, any, undefined>> = [
	...objectValues(ErrorCode).filter(code => code !== ErrorCode.REQUIRED && code !== ErrorCode.TYPE),
	"custom", "fields"
]

const typeConverters = new Map<Datatype, ConversionFunction<any>>([
	[Datatype.BOOLEAN, bool],
	[Datatype.DATE, datetime],
	[Datatype.NUMBER, numeric],
	[Datatype.STRING, alphanumeric],
	[Datatype.FILE, file],
	[Datatype.ARRAY, value => isArray(value) ? value : undefined],
	[Datatype.OBJECT, value => isPlainObject(value) ? value : undefined]
])
const converter = (parameter: Datatype): Function1<any, any> => typeConverters.get(parameter)!

const values = <T>(parameter: Set<T>) => (value: T) => parameter.has(value) // TODO: does not work for dates.
// String:
const maxlength = (parameter: number) => (value: string) => value.length <= parameter
const minlength = (parameter: number) => (value: string) => value.length >= parameter
const pattern = (parameter: RegExp) => (value: string) => parameter.test(value)
// Number or Date:
const maximum = <T extends number | Date>(parameter: T) => (value: T) => value.valueOf() <= parameter.valueOf()
const minimum = <T extends number | Date>(parameter: T) => (value: T) => value.valueOf() >= parameter.valueOf()

/**
 * Returns the array of field validator functions based on the rules defined for the field.
 */
const fieldValidators = <F extends keyof T, T, C>(rules: FieldRules<F, T, C>): ReadonlyArray<FieldValidator<F, T, C>> =>
	ruleTypes.filter(it => !isUndefined(rules[it])).map(
		rule => {
			switch (rule) {
				case "fields": {
					const validate = validator<unknown, unknown>(rules.fields!)
					// tslint:disable-next-line: space-before-function-paren
					return function (this: Partial<T>, value: NonNullable<T[F]>, context: C): Promise<ObjectErrors<T[F]> | undefined> {
						return validate.call(this, value, context)
					}
				}
				case "custom":
					return customValidator(rules.custom!)
				default: {
					const fieldValidator: Validator<any, T, C> | undefined =
						rule === ErrorCode.PATTERN ?
							pattern(rules[ErrorCode.PATTERN]!) :
							rule === ErrorCode.MAXLENGTH ?
								maxlength(rules[ErrorCode.MAXLENGTH]!) :
								rule === ErrorCode.MINLENGTH ?
									minlength(rules[ErrorCode.MINLENGTH]!) :
									rule === ErrorCode.MAXIMUM ?
										maximum(rules[ErrorCode.MAXIMUM] as number | Date) :
										rule === ErrorCode.MINIMUM ?
											minimum(rules[ErrorCode.MINIMUM] as number | Date) :
											rule === ErrorCode.VALUES ?
												values(new Set(rules[ErrorCode.VALUES]!)) :
												undefined

					if (process.env.NODE_ENV === "development") {
						// The next check will drop out of the build due to tree shaking but is
						// performed during development.
						if (!fieldValidator) {
							throw new TypeError(`Unsupported rule ${ rule }`)
						}
					}

					return scalarFieldValidator(rule, fieldValidator!)
				}
			}
		}
	)

/**
 * Returns a field validator that performs the given validation rule for the scalar field. It returns an array of 0 or 1 failed rules.
 */
const scalarFieldValidator = <F extends keyof T, T, C>(rule: string, validate: Validator<T[F], T, C>): FieldValidator<F, T, C> =>
	// tslint:disable-next-line: space-before-function-paren
	async function (this: Partial<T>, value: NonNullable<T[F]>, context: C): Promise<ScalarErrors> {
		const result = await validate.call(this, value, context)
		return result ? [] : [rule]
	}

/**
 * Returns a field validator that performs the defined custom rules for the scalar field. It returns an array of 0 or more failed rules.
 */
const customValidator = <F extends keyof T, T, C>(validations: NonNullable<FieldRules<F, T, C>["custom"]>): FieldValidator<F, T, C> => {
	// The custom object contains one or more rules. Create a function that runs all of them and returns the results as
	// a single array of failed rules.
	const validators = Object.entries(validations).map(
		([rule, validate]) => scalarFieldValidator(rule, validate as Validator<T[F], T, C>) // TODO: find out why cast is needed.
	)
	// tslint:disable-next-line: space-before-function-paren
	return async function (this: Partial<T>, value: NonNullable<T[F]>, context: C): Promise<ScalarErrors> {
		const results = await Promise.all(validators.map(validate => validate.call(this, value, context)))
		return results.flat() as ScalarErrors
	}
}

/**
 * Returns a validator that performs the validation of the values in an object against the given set of rules.
 */
const validator = <T, C>(contextRules: ObjectRules<T, C>): ObjectValidator<T, C> => {
	const validatorMap = new Map<keyof T, FieldValidator<keyof T, T, C>>()

	for (const [field, fieldRules] of entries(contextRules)) {
		if (fieldRules) {
			const rules = fieldRules as FieldRules<keyof T, T, C>
			// For all defined rules, create a field validator.
			const validators = fieldValidators(rules)
			const convert = isFunction(rules.type) ? rules.type : converter(rules.type)
			// tslint:disable-next-line: space-before-function-paren
			const fieldValidator = async function (this: Partial<T>, value: any, context: C): Promise<FieldErrors> {
				const required = isFunction(rules.required) ? rules.required.call(this, context) : rules.required || false
				if (isNil(value) || isString(value) && value.length === 0) {
					// Validation is successful if the field is not required.
					return required ? [ErrorCode.REQUIRED] : undefined
				}
				// Convert the value. This will return undefined if the value is not of the proper type.
				const convertedValue = convert(value)
				if (isUndefined(convertedValue)) {
					return [ErrorCode.TYPE]
				}

				// Run the validations. At this point, the value is defined and of the proper type.
				const results = await Promise.all(validators.map(validate => validate.call(this, convertedValue, context)))
				// Results is an array of field errors. If there is one array element, return that, or else flatten the array.
				// Flattening the array is only useful for scalar errors (which is an array of strings). The other error types are
				// maps or objects (of which there is at most one).
				return results.length === 0 ? undefined : results.length === 1 ? results[0] : results.flat()
			}
			validatorMap.set(field, fieldValidator)
		}
	}

	// Create the actual validator function for the object.
	// tslint:disable-next-line: space-before-function-paren
	return async function (this: void, object: any, context: C): Promise<ObjectErrors<T> | undefined> {
		const objectErrors: ObjectErrors<T> = {}
		for (const [field, validate] of validatorMap) {
			// This awaits each validation individually before moving on. If this doesn't perform well enough, collect the promises and use Promise.all.
			// That would be less readable and more prone to errors.
			// eslint-disable-next-line no-await-in-loop
			const errors = await validate.call(object, object[field], context)
			if (!isEmpty(errors)) {
				objectErrors[field] = errors
			}
		}
		return isEmpty(objectErrors) ? undefined : objectErrors
	}
}

export default validator
