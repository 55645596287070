var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"p-button",class:{
		'p-button--primary': _vm.isPrimary,
		'p-button--secondary': _vm.isSecondary,
		'text-link font-icon font-icon--arrow-left': _vm.textLink,
		'p-button--wide': _vm.wide,
		'disabled': _vm.disabled || _vm.loading,
		'pending-indicator--active': _vm.loading
	},attrs:{"type":_vm.type,"disabled":_vm.disabled || _vm.loading},on:{"click":_vm.onClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }