import { ActionContext } from "vuex"
import { namespaces, RootState } from "."
import { Company } from "./company/types"
import { AuthenticationMethod, AUTHENTICATION_METHOD_KEY } from "utils/session-factory"
import { USER_MUTATIONS as UserMutations } from "store/user"
import localStorageClient from "api/clients/local-storage-client"

export default async (store: ActionContext<RootState, RootState>): Promise<void> => {
	const authMethod: AuthenticationMethod = localStorageClient.getItem(AUTHENTICATION_METHOD_KEY) as AuthenticationMethod
	try {
		await store.dispatch(`${ namespaces.company }/getCompany`)
		await store.dispatch(`${ namespaces.premium }/getPremium`)
		await store.dispatch(`${ namespaces.messages }/getMessages`)
	} catch (error) {
		await store.dispatch("authentication/logout") // Logout the user since his/her data is not loaded correctly.
		store.commit(`${ namespaces.user }/${ UserMutations.SET_ERROR }`, true)

		throw error
	}

	// We can't retrieve a controlroom user for e-herkenning login.
	if (authMethod === AuthenticationMethod.CREDENTIALS || authMethod === AuthenticationMethod.CONTROL_ROOM) {
		await store.dispatch(`${ namespaces.user }/getCurrentUser`)
	}

	const company = store.getters[`${ namespaces.company }/company`] as Company
	await store.dispatch(`${ namespaces.documents }/getDocuments`, company.externalIdentifier)
}
