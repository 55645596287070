// Checks if a tree has a specific target
const checkTreeUp = (
	top: Element,
	target: Element | null,
	maxDepth: number = Infinity,
	func: (top: Element, current: Element) => boolean = (a, b) => a === b
): boolean => {
	if (target === null || maxDepth < 0 || target.nodeType !== Node.ELEMENT_NODE) {
		return false
	}

	if (func(top, target)) {
		return true
	}

	if (target.parentNode) {
		return checkTreeUp(top, target.parentNode as Element, maxDepth - 1, func)
	}

	return false
}

export default checkTreeUp
