import { MutationTree } from "vuex"
import { AuthenticationState } from "./types"

export enum MutationType {
	TIMESTAMP = "timestamp",
	LOGIN = "login",
	LOGOUT = "logout"
}

const mutations: MutationTree<AuthenticationState> = {
	[MutationType.TIMESTAMP](state: AuthenticationState): void {
		state.timestamp = Date.now()
	},
	[MutationType.LOGIN](state: AuthenticationState): void {
		state.loggedIn = true
	},
	[MutationType.LOGOUT](state: AuthenticationState): void {
		state.loggedIn = false
	}
}

export default mutations
