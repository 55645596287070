import { ConversionMap, required, optional } from "@lib/types/import"
import { one, mandatory, many } from "@lib/import/extract"
import { statePayload } from "."
import { StatePayload } from "api/models/customer-journey"
import { SignUpEmployeesData } from "api/models/customer-journey/sign-up-employees"
import { ResponseErrors, Cell, ErrorParam, ExcelUploadResponse, ErrorType } from "api/clients/sign-up-employees-client"
import { alphanumeric, enumConstant } from "@lib/import/convert"

const signUpEmployeesData: ConversionMap<SignUpEmployeesData> = {
}

const signUpEmployeesState: ConversionMap<StatePayload<SignUpEmployeesData>> = {
	...statePayload,
	data: ["data", one(signUpEmployeesData), required]
}

const cell: ConversionMap<Cell> = {
	row: ["row", alphanumeric, required],
	column: ["column", alphanumeric, required]
}

const syntacticErrorParams: ConversionMap<ErrorParam> = {
	name: ["name", alphanumeric, required],
	reason: ["reason", alphanumeric, required],
	cell: ["cell", one(cell), optional]
}

const errors: ConversionMap<ResponseErrors> = {
	params: ["params", many(syntacticErrorParams), required],
	title: ["title", enumConstant(ErrorType), required],
	detail: ["detail", alphanumeric, required],
	errors: ["errors", many(alphanumeric), optional],
	warnings: ["warnings", many(alphanumeric), optional],
	status: ["status", alphanumeric, required],
	type: ["title", alphanumeric, optional]
}

const excelUploadResponse: ConversionMap<ExcelUploadResponse> = {
	title: ["title", alphanumeric, required],
	detail: ["detail", alphanumeric, required],
	errors: ["errors", many(alphanumeric), optional],
	warnings: ["warnings", many(alphanumeric), optional],
	status: ["status", alphanumeric, required],
	type: ["title", alphanumeric, optional]
}

export const convertErrors = mandatory(one(errors))
export const convertExcelUploadResponse = mandatory(one(excelUploadResponse))

export default mandatory(one(signUpEmployeesState))
