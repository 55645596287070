export const email = (value: string): boolean => /^.+@(.+\.)+.{2,}$/.test(value)

/*
	Generic IBAN format

	The IBAN consists of up to 34 alphanumeric characters, as follows:

	- country code using ISO 3166-1 alpha-2 – two letters,
	- check digits – two digits, and
	- Basic Bank Account Number (BBAN) – up to 30 alphanumeric characters that are country-specific.

	https://en.wikipedia.org/wiki/International_Bank_Account_Number#Structure
*/
// https://stackoverflow.com/questions/21928083/iban-validation-check
// Reformatted for typescript. Original by: MaVRoSCy
const lengths = {
	AD: 24,
	AE: 23,
	AT: 20,
	AZ: 28,
	BA: 20,
	BE: 16,
	BG: 22,
	BH: 22,
	BR: 29,
	CH: 21,
	CR: 21,
	CY: 28,
	CZ: 24,
	DE: 22,
	DK: 18,
	DO: 28,
	EE: 20,
	ES: 24,
	FI: 18,
	FO: 18,
	FR: 27,
	GB: 22,
	GI: 23,
	GL: 18,
	GR: 27,
	GT: 28,
	HR: 21,
	HU: 28,
	IE: 22,
	IL: 23,
	IS: 26,
	IT: 27,
	JO: 30,
	KW: 30,
	KZ: 20,
	LB: 28,
	LI: 21,
	LT: 20,
	LU: 20,
	LV: 21,
	MC: 27,
	MD: 24,
	ME: 22,
	MK: 19,
	MR: 27,
	MT: 31,
	MU: 30,
	NL: 18,
	NO: 15,
	PK: 24,
	PL: 28,
	PS: 29,
	PT: 25,
	QA: 29,
	RO: 24,
	RS: 22,
	SA: 24,
	SE: 24,
	SI: 19,
	SK: 24,
	SM: 27,
	TN: 24,
	TR: 26
}

export const iban = (value: string): boolean => {
	if (/[^A-Z\d]/.test(value)) {
		return false
	}
	// Match and capture (1) the country code, (2) the check digits, and (3) the rest.
	const code = value.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/)

	// check syntax and length
	if (!code || value.length !== lengths[code[1] as keyof typeof lengths]) {
		return false
	}

	// Rearrange country code and check digits, and convert chars to ints.
	const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => String(letter.charCodeAt(0) - 55))

	let checksum = digits.substring(0, 2)
	for (let offset = 2; offset < digits.length; offset += 7) {
		const fragment = checksum + digits.substring(offset, offset + 7)
		checksum = String(parseInt(fragment, 10) % 97)
	}
	return parseInt(checksum, 10) === 1
}

/*
	Bank Identifier Code

	- bank code: 4 letters
	- country code: 2 letters
	- location code: 2 alphanumeric characters
	- branch code: 3 alphanumeric characters (optional)

	https://en.wikipedia.org/wiki/ISO_9362
*/
export const bic = (value: string): boolean => /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value)

export const phone = (value: string): boolean => /^[0-9()\-+ ]{10,20}$/.test(value)

export const simplePhone = (value: string): boolean => /^[0-9]{10}$/.test(value)
