
	import LoadingComponent from "custom/LoadingComponent"
	import Component from "vue-class-component"
	import { VARIANT_QUERY_STRING, SignUpEmployeesVariant } from "pages/SignUpEmployees.vue"
	import PQuickLink from "@portal-components/ui/PQuickLink.vue"
	import PRadiobuttonGroup from "@portal-components/ui/input/PRadiobuttonGroup.vue"
	import PDateInput from "@portal-components/ui/input/PDateInput.vue"
	import IcsNumberInput from "custom/ui/input/IcsNumberInput.vue"
	import PButton from "@portal-components/ui/input/PButton.vue"
	import PValidationErrors from "@portal-components/ui/input/PValidationErrors.vue"
	import { toOptionModel, OptionModel } from "@portal-components/ui/input/option-model"

	import { showExtraSavings } from "api/settings"
	import EmployeeTable from "custom/employees/EmployeeTable.vue"
	import EmployeeChoices from "custom/employees/EmployeeChoices.vue"
	import { participantModule, companyModule } from "store/modules"
	import { Participant } from "api/models/participants"
	import { StoreAction } from "@lib/types/vuex"
	import { Watch } from "vue-property-decorator"
	import { EndEmployementActionPayload, UpdateSalaryAndOrPartTimePercentageActionPayload } from "store/participant/types"
	import { ObjectErrors } from "@lib/types/validation"
	import validate from "store/participant/rules"
	import { EmployeeChangeModel } from "store/participant/types"
	import { isEmpty } from "lodash-es"
	import Vue from "vue"
	import eventBus from "lib/vue/eventBus"
	import add, {Add} from "lib/date/add"
	import toaster from "@portal-components/ui/utils/toaster"
	import { ErrorType, ResponseErrors } from "api/clients/sign-up-employees-client"
	import { Agreement } from "store/company/types"

	enum EmployeeChangeOptions {
		SALARY_AND_OR_PARTTIMEPERCENTAGE = "salary_and_or_parttimepercentage",
		RETIREMENT = "retirement"
	}

	@Component({
		components: {
			PQuickLink,
			EmployeeTable,
			EmployeeChoices,
			PRadiobuttonGroup,
			PButton,
			PDateInput,
			IcsNumberInput,
			PValidationErrors
		}
	})
	export default class EmployeeChanges extends LoadingComponent implements Vue {
		@companyModule.Getter agreement?: Agreement
		@participantModule.Getter activeParticipant?: Participant
		@participantModule.Action clearActiveParticipant!: StoreAction<void>
		@participantModule.Action endEmployment!: StoreAction<EndEmployementActionPayload>
		@participantModule.Action updateSalaryAndOrPartTimePercentage!: StoreAction<UpdateSalaryAndOrPartTimePercentageActionPayload>
		readonly baseTranslationKey = "employees.changes"
		readonly EmployeeChangeOptions = EmployeeChangeOptions
		readonly employeeChangeOptions: Array<OptionModel> = [
			toOptionModel(EmployeeChangeOptions.SALARY_AND_OR_PARTTIMEPERCENTAGE, `${ this.baseTranslationKey }.form.employeeChangeOptions.${ EmployeeChangeOptions.SALARY_AND_OR_PARTTIMEPERCENTAGE }`),
			toOptionModel(EmployeeChangeOptions.RETIREMENT, `${ this.baseTranslationKey }.form.employeeChangeOptions.${ EmployeeChangeOptions.RETIREMENT }`)
		]
		displaySalary = ""
		displayParttimePercentage = ""
		submitSalary = 0
		submitParttimePercentage = 0
		isChangingEmployee = false
		isHandlingEmployeeChanges = false
		showConfirmation = false
		selectedChangeOption: EmployeeChangeOptions = EmployeeChangeOptions.SALARY_AND_OR_PARTTIMEPERCENTAGE
		errors: ObjectErrors<EmployeeChangeModel> = {}
		responseErrors: Array<string> = []

		employeeChangeModel: EmployeeChangeModel = {
			salary: undefined,
			parttimePercentage: undefined,
			salaryAndOrParttimePercentageChangeDate: undefined,
			retirementDate: undefined
		}

		mounted(): void {
			eventBus.on("retirementDateWarningModalConfirmed", this.retirementDateWarningModalConfirmed)
		}

		retirementDateWarningModalConfirmed(): void {
			this.handleEmployeeChangeMutation()
		}

		getExplanationText(baseTranslationKey: string): string {
			const translationKey = `${ baseTranslationKey }`
			const translatedValue = Vue.filter("locale")(translationKey)
			return Vue.filter("template")(translatedValue)
		}

		get signUpEmployeeUrl(): string {
			return `sign-up-employees.html?${ VARIANT_QUERY_STRING }=${ SignUpEmployeesVariant.SIGN_UP }`
		}

		get yearSalaryChangeUrl(): string {
			return `sign-up-employees.html?${ VARIANT_QUERY_STRING }=${ SignUpEmployeesVariant.YEARLY_SALARY_CHANGE }`
		}

		isValidDate(date: Date): boolean {
			return !isNaN(date.getTime())
		}

		@Watch("activeParticipant")
		activeParticipantChanged(): void {
			if (!this.activeParticipant) {
				return
			}

			this.resetModel()
		}

		@Watch("employeeChangeModel", { immediate: true, deep: true })
		async userDetailsChanged(user: EmployeeChangeModel): Promise<void> {
			this.responseErrors = []
			this.errors = await validate(user, undefined) || {}
		}

		changeEmployeeDetails(): void {
			this.showConfirmation = false
			this.isChangingEmployee = !this.isChangingEmployee
		}

		handleEmployeeChanges(): void {
			this.isHandlingEmployeeChanges = !this.isHandlingEmployeeChanges
		}

		handleDateValidations(): void {
			const currentDate = new Date()
			const threeMonthCheck = add(Add.Months, 3, currentDate)
			const twoMonthCheck = add(Add.Months, -2, currentDate)
			const nextYearDate = new Date(currentDate.getFullYear() + 1, 0, 1)

			const validationDate = this.selectedChangeOption === EmployeeChangeOptions.RETIREMENT ?
				this.employeeChangeModel.retirementDate! :
				this.employeeChangeModel.salaryAndOrParttimePercentageChangeDate!

			if(validationDate > threeMonthCheck && validationDate < nextYearDate) {
				const message = "form.retirementDate.threeMonthWarning"
				eventBus.emit("showWarningModal", message)
			}else if(validationDate < twoMonthCheck) {
				const message = "form.retirementDate.twoMonthWarning"
				eventBus.emit("showWarningModal", message)
			} else {
				this.handleEmployeeChangeMutation()
			}
		}

		async submitChangeEmployeeDetails(): Promise<void> {
			this.handleDateValidations()
			this.responseErrors = []
		}

		async handleEmployeeChangeMutation() : Promise<void> {
			switch (this.selectedChangeOption) {
				case EmployeeChangeOptions.SALARY_AND_OR_PARTTIMEPERCENTAGE:
					await this.handleParticipantMutation(this.updateSalaryAndOrPartTimePercentage({
						participantId: this.activeParticipant!.externalIdentifier,
						newSalary: this.submitSalary,
						newPartTimePercentage: this.submitParttimePercentage,
						startDateNewSalaryAndOrPartTimePercentage: this.employeeChangeModel.salaryAndOrParttimePercentageChangeDate!
					}))
					break
				case EmployeeChangeOptions.RETIREMENT:
					await this.handleParticipantMutation(this.endEmployment({
						participantId: this.activeParticipant!.externalIdentifier,
						employmentEndDate: this.employeeChangeModel.retirementDate!
					}))
					break
			}
		}

		async finish(): Promise<void> {
			await this.clearActiveParticipant()
			this.resetModel()
			this.isChangingEmployee = false
			this.showConfirmation = false
		}

		private handleParticipantMutation(mutationPromise: Promise<void>): Promise<void> {
			return this.manageLoadingState(mutationPromise)
				.then(() => { this.showConfirmation = true })
				.catch(response => {
					if (response.status !== "400") {
						toaster.error({ title: "general.error" })
					}

					this.handleErrorResponse(response)
				})
		}

		private handleErrorResponse(response: ResponseErrors): void {
			this.responseErrors = (response.errors && response.title === ErrorType.INVALID_INPUT) ? response.errors : []
		}

		private resetModel(): void {
			this.submitParttimePercentage = this.activeParticipant?.partTimePercentage!
			this.submitSalary = this.activeParticipant?.salary!
			this.displaySalary = this.activeParticipant?.salary.toFixed(2).replace(".",",")!
			this.displayParttimePercentage = this.activeParticipant?.partTimePercentage.toFixed(2).replace(".",",")!
			this.employeeChangeModel = {
				salary: this.activeParticipant?.salary,
				parttimePercentage: this.activeParticipant?.partTimePercentage,
				salaryAndOrParttimePercentageChangeDate: undefined,
				retirementDate: undefined
			}
		}

		get validSalaryAndOrParttimePercentageForm(): boolean {
			return !isEmpty(this.errors) || !this.employeeChangeModel.salaryAndOrParttimePercentageChangeDate ||
				this.loading || !this.isValidDate(this.employeeChangeModel.salaryAndOrParttimePercentageChangeDate)
		}

		get validEndEmployementForm(): boolean {
			return !this.employeeChangeModel.retirementDate || this.loading || !this.isValidDate(this.employeeChangeModel.retirementDate)
		}

		get showExtraSavings(): boolean {
			return showExtraSavings && !!this.agreement?.insurance?.extraSavings
		}

		checkSalary(salary: string): void {
			this.submitSalary = parseFloat(salary.replace(".","").replace(",","."))
			this.employeeChangeModel.salary = this.submitSalary
		}

		checkParttimePercentage(parttimePercentage: string): void {
			this.submitParttimePercentage = parseFloat(parttimePercentage.replace(".","").replace(",","."))
			this.employeeChangeModel.parttimePercentage = this.submitParttimePercentage
		}

	}
