import { resources } from "api/resources"
import { api } from "api/index"
import convertPremium from "api/conversions/premium"
import { PremiumState } from "store/premium/types"

class PremiumClient {
	async getPremium(): Promise<PremiumState> {
		return convertPremium(await api.getJson(resources.payPremium.getPremium))
	}
}

export default new PremiumClient()
