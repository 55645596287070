
	import Vue from "vue"
	import { Watch, Provide } from "vue-property-decorator"

	import { CustomerJourneyNamespace } from "@portal-components/customer-journey/configuration"
	import type { CustomerJourneyControls } from "@portal-components/customer-journey/configuration"

	import { ConversionMap, required } from "@lib/types/import"
	import { one, mandatory } from "@lib/import/extract"

	import { SmoothScrollOptions, scrollToElement } from "@components/@directives/smoothscroll"

	import { customerJourney as customerJourneyClient } from "api"
	import { CustomerJourneySlide, CustomerJourneyStatus, StatePayload, StepState } from "api/models/customer-journey"
	import { statePayload } from "api/conversions/customer-journey"

	import { merge } from "lodash-es"

	export default abstract class CustomerJourney<T> extends Vue {
		@Provide(CustomerJourneyNamespace.CUSTOMER_JOURNEY)
		customerJourneyControls: CustomerJourneyControls<T> = {
			progression: 0,
			isPending: true,
			isStarted: false,
			isCompleted: false,
			status: CustomerJourneyStatus.PENDING,
			steps: [],
			shouldDisplayStep: (stepId: string) => this.shouldDisplayStep(stepId),
			resolveStep: (stepId: string, event: MouseEvent) => this.resolveStep(stepId, event),
			resolveIntro: (event: MouseEvent) => this.resolveIntro(event),
			getStepData: <K extends keyof T>(stepId: K) => this.getStepData(stepId),
			resetState: () => this.deleteCustomerJourneyState()
		}

		readonly customerJourneySlideEnum = CustomerJourneySlide

		scrollOffset = -105

		private get scrollOptions(): SmoothScrollOptions {
			return {
				offset: this.scrollOffset,
				duration: 1000,
				delay: 100
			}
		}

		abstract get stateConverter(): ConversionMap<T>

		abstract get customerJourneyId(): string

		get customerJourneyPending(): boolean {
			return this.state.status === CustomerJourneyStatus.PENDING
		}

		get customerJourneyStarted(): boolean {
			return this.state.status === CustomerJourneyStatus.STARTED
		}

		get customerJourneyCompleted(): boolean {
			return this.state.status === CustomerJourneyStatus.COMPLETED
		}

		get progression(): number {
			const progressPerStep = 100 / this.customerJourneySteps.length
			return progressPerStep * this.completedSteps.length
		}

		get activeStepId(): string {
			const activeStepId = this.customerJourneySteps.find(stepId => {
				const stepState: StepState = this.getStepStateById(stepId)
				return stepState.startDateTime !== undefined && stepState.endDateTime === undefined
			})

			return activeStepId || CustomerJourneySlide.INTRO
		}

		get completedSteps(): Array<string> {
			return this.customerJourneySteps.filter(stepId => this.getStepStateById(stepId).endDateTime !== undefined)
		}

		get customerJourneySteps(): Array<string> {
			return Object.keys(this.state.steps)
		}

		abstract beforeMount(): void

		@Watch("progression", { immediate: true })
		@Watch("state", { immediate: true })
		updateData(): void {
			this.customerJourneyControls.progression = this.progression
			this.customerJourneyControls.isPending = this.customerJourneyPending
			this.customerJourneyControls.isStarted = this.customerJourneyStarted
			this.customerJourneyControls.isCompleted = this.customerJourneyCompleted
			this.customerJourneyControls.status = this.state.status
			this.customerJourneyControls.steps = Object.keys(this.state.steps) as Array<keyof T>
		}

		async customerJourneyBeforeMount(): Promise<void> {
			const customerJourneyState = await this.getCustomerJourneyState()
			if (!customerJourneyState) {
				await this.createCustomerJourneyState()
				return
			}

			this.hydrate(customerJourneyState)
		}

		hydrate(newCustomerJourneyState: StatePayload<T>): void {
			merge(this.state, newCustomerJourneyState)
		}

		@Watch("state", { deep: true })
		async stateChanged(): Promise<void> {
			await this.updateCustomerJourneyState()
		}

		getCustomerJourneyState(): Promise<StatePayload<T> | undefined> {
			return customerJourneyClient.getStateById(this.customerJourneyId, mandatory(one<StatePayload<any>>({
				...statePayload,
				data: ["data", one(this.stateConverter), required]
			})))
		}

		async createCustomerJourneyState(): Promise<void> {
			customerJourneyClient.createState(this.customerJourneyId, this.state)
		}

		async updateCustomerJourneyState(): Promise<void> {
			customerJourneyClient.updateState(this.customerJourneyId, this.state)
		}

		async deleteCustomerJourneyState(): Promise<void> {
			customerJourneyClient.deleteState(this.customerJourneyId)
		}

		abstract state: StatePayload<T>

		abstract getNextStepId(stepId: string): string

		resolveIntro(event: MouseEvent): void {
			if (this.customerJourneyStarted) {
				this.toActiveStep(event)
				return
			}

			this.startCustomerJourney()
			this.toNextStep(CustomerJourneySlide.INTRO, event)
		}

		resolveStep(stepId: string, event: MouseEvent): void {
			this.finishStep(stepId)
			this.toNextStep(stepId, event)
		}

		skipNextStep(stepId: string, event: MouseEvent): void {
			this.finishStep(stepId)
			const nextStepId: string = this.getNextStepId(stepId)
			this.resolveStep(nextStepId, event)
		}

		startCustomerJourney(): void {
			this.state.status = CustomerJourneyStatus.STARTED
			this.state.startDateTime = new Date()

			const firstStepId = this.getNextStepId(CustomerJourneySlide.INTRO)
			const firstStepState: StepState = this.getStepStateById(firstStepId)
			firstStepState.startDateTime = new Date()
		}

		finishCustomerJourney(): void {
			this.state.status = CustomerJourneyStatus.COMPLETED
			this.state.endDateTime = new Date()
		}

		finishStep(stepId: string): void {
			const stepState = this.getStepStateById(stepId)
			stepState.endDateTime = new Date()

			if (this.progression === 100) {
				this.finishCustomerJourney()
				return
			}

			const nextStepId: string = this.getNextStepId(stepId)
			const nextStepState = this.getStepStateById(nextStepId)
			nextStepState.startDateTime = new Date()
		}

		toActiveStep(event: MouseEvent): void {
			this.scrollTo(this.activeStepId, event)
		}

		toNextStep(stepId: string, event: MouseEvent): void {
			const nextStepId: string = this.getNextStepId(stepId)
			this.scrollTo(nextStepId, event)
		}

		scrollTo(stepId: string, event: MouseEvent): void {
			const vueElement: Vue = this.$refs[stepId] as Vue
			const element: HTMLElement = vueElement.$el as HTMLElement
			scrollToElement(element, this.scrollOptions, event)
		}

		navigateToDashboard(): void {
			location.href = "index.html"
		}

		shouldDisplayStep(stepId: string): boolean {
			const stepState: StepState = this.state.steps[stepId]
			return stepState && stepState.startDateTime !== undefined
		}

		getStepStateById(stepId: string): StepState {
			return this.state.steps[stepId]
		}

		getStepData<K extends keyof T>(stepId: K): T[K] {
			return this.state.data[stepId]
		}
	}
