
	import Vue from "vue"
	import LoadingComponent from "custom/LoadingComponent"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"

	import { Datatype, ObjectErrors, ObjectValidator } from "@lib/types/validation"
	import validator from "@lib/validation/validator"
	import PDateInput from "@portal-components/ui/input/PDateInput.vue"
	import PNumberInput from "@portal-components/ui/input/PNumberInput.vue"
	import PValidationErrors from "@portal-components/ui/input/PValidationErrors.vue"

	import { resources } from "api/resources"
	import { SignUpEmployeesVariant } from "pages/SignUpEmployees.vue"
	import IcsDownloadLink from "custom/ui/IcsDownloadLink.vue"
	import { provideEmployeeDataConfig } from "api/settings"

	interface YearlySalaryChangeDownload {
		year?: Date
	}

	@Component({
		components: {
			PNumberInput,
			PValidationErrors,
			IcsDownloadLink,
			PDateInput
		}
	})
	export default class DownloadTemplate extends LoadingComponent {
		@Prop({ type: String, required: true }) variant!: SignUpEmployeesVariant

		readonly baseTranslationKey = "signUpEmployees.provideEmployeeData.downloadTemplate"
		readonly SignUpEmployeesVariant = SignUpEmployeesVariant
		
		private readonly validate: ObjectValidator<YearlySalaryChangeDownload, unknown> = validator({
			year: {
				required: true,
				type: Datatype.DATE,
				custom: {
					higherOrEqualsMigrationYear: (value: Date): boolean => {
						const migrateDate = new Date(provideEmployeeDataConfig.migrationYear+"-01-01");
						return value >= migrateDate
					},
					lowerOrEqualsCurrentYear: (value: Date): boolean => {
						const currentYear = new Date()
						return value <= currentYear
					}
				}
			}
		})

		model: YearlySalaryChangeDownload = {}
		errors?: ObjectErrors<YearlySalaryChangeDownload> = {}
		emptyDate: Boolean = false

		get isYearlySalaryVariant(): boolean {
			return this.variant === SignUpEmployeesVariant.YEARLY_SALARY_CHANGE
		}
		formatDate(date: Date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) 
				month = '0' + month;
			if (day.length < 2) 
				day = '0' + day;

			return [year, month, day].join('-');
		}

		get downloadUrl(): string {
			if (this.variant === SignUpEmployeesVariant.YEARLY_SALARY_CHANGE) {
				const formatDate = this.formatDate(this.model.year!)
				return resources.company.downloadYearlySalariesTemplate(formatDate)
			}

			return resources.company.downloadParticipantTemplate
		}

		async beforeMount(): Promise<void> {
			if (this.variant !== SignUpEmployeesVariant.YEARLY_SALARY_CHANGE) {
				return
			}
			if(!this.model.year){
				const date = new Date()
				this.model = {year: new Date(date.getFullYear(), 0, 1)}
				this.errors = await this.validate(this.model, undefined)
			}
			await Vue.nextTick()
		}

		isDirty(value: any): boolean {
			return value === undefined
		}

		@Watch("model", { deep: true, immediate: true })
		async yearlySalaryModelChanged(): Promise<void> {
			if (this.model.year instanceof Date) {
				this.emptyDate = true
			}
			this.errors = await this.validate(this.model, undefined)
			this.$emit("dateSelected", this.model.year!)
		}
	}
