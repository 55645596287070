
	import Component from "vue-class-component"
	import Page from "custom/Page.vue"
	import PageSlide from "@components/container/PageSlide.vue"
	import { Provide } from "vue-property-decorator"

	import PIntro from "@portal-components/customer-journey/PIntro.vue"
	import POutro from "@portal-components/customer-journey/POutro.vue"
	import PProgressCircle from "@portal-components/ui/PProgressCircle.vue"
	import PCustomerJourney from "@portal-components/customer-journey/PCustomerJourney.vue"

	import SignUpEmployeesWizard from "custom/customer-journey/sign-up-employees/SignUpEmployeesWizard.vue"
	import { SignUpEmployeesData, SignUpEmployeesStep } from "api/models/customer-journey/sign-up-employees"
	import type { OutroConfig } from "@portal-components/customer-journey/configuration"
	import { CustomerJourneyStatus, StatePayload, CustomerJourneySlide } from "api/models/customer-journey"
	import signUpEmployeesConverter from "api/conversions/customer-journey/sign-up-employees"
	import { CustomerJourneyNamespace } from "@portal-components/customer-journey/configuration"
	import Secured from "utils/security/Secured"
	import { session } from "api/index"

	export enum SignUpEmployeesVariant {
		START = "start",
		SIGN_UP = "signUp",
		YEARLY_SALARY_CHANGE = "yearlySalaryChange"
	}

	export const VARIANT_QUERY_STRING = "variant"

	@Secured(session)
	@Component({
		components: {
			Page,
			PageSlide,
			PProgressCircle,
			PIntro,
			SignUpEmployeesWizard,
			POutro
		}
	})
	export default class SignUpEmployees extends PCustomerJourney<SignUpEmployeesData> {
		private readonly version = "1.0"
		readonly baseTranslationKey = "signUpEmployees"
		readonly stepEnum = SignUpEmployeesStep

		@Provide(CustomerJourneyNamespace.OUTRO)
		outroConfig: OutroConfig = {
			outroItems: [
				{
					title: `${ this.baseTranslationKey }.outro.card1.title`,
					description: `${ this.baseTranslationKey }.outro.card1.info`,
					url: "static/checklist_voor_uw_medewerkers.pdf",
					newTab: true
				},
				{
					title: `${ this.baseTranslationKey }.outro.card2.title`,
					description: `${ this.baseTranslationKey }.outro.card2.info`,
					url: "pay-premium.html"
				},
				{
					title: `${ this.baseTranslationKey }.outro.card3.title`,
					description: `${ this.baseTranslationKey }.outro.card3.info`,
					url: "employees.html"
				}
			]
		}

		state: StatePayload<SignUpEmployeesData> = {
			version: this.version,
			startDateTime: new Date(),
			endDateTime: undefined,
			status: CustomerJourneyStatus.PENDING,
			data: {},
			steps: {
				[SignUpEmployeesStep.PROVIDE_EMPLOYEE_DATA]: { startDateTime: undefined, endDateTime: undefined, weight: 0 }
			}
		}

		variant: SignUpEmployeesVariant = SignUpEmployeesVariant.START

		mounted(): void {
			const urlParams = new URLSearchParams(window.location.search)
			const variant = urlParams.get(VARIANT_QUERY_STRING) || SignUpEmployeesVariant.START

			if (!this.isValidVariant(variant)) {
				return
			}

			this.variant = variant as SignUpEmployeesVariant
		}

		private isValidVariant(variant: string): boolean {
			return Object.values(SignUpEmployeesVariant).includes(variant as SignUpEmployeesVariant)
		}

		get customerJourneyId(): string {
			return "CJ_SIGN_UP_EMPLOYEES"
		}

		get customerJourneyState(): StatePayload<SignUpEmployeesData> {
			return this.state
		}

		get stateConverter(): (value: any) => StatePayload<SignUpEmployeesData> {
			return signUpEmployeesConverter
		}

		async beforeMount(): Promise<void> {
			await this.customerJourneyBeforeMount()
		}

		getNextStepId(stepId: string): string {
			let nextStepId: string = CustomerJourneySlide.INTRO
			switch (stepId) {
				case CustomerJourneySlide.INTRO:
					nextStepId = SignUpEmployeesStep.PROVIDE_EMPLOYEE_DATA
					break
				case SignUpEmployeesStep.PROVIDE_EMPLOYEE_DATA:
					nextStepId = CustomerJourneySlide.OUTRO
					break
			}

			return nextStepId
		}
	}
