
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	import { iconMap, IconType } from "@portal-components/ui/utils/icons"

	@Component
	export default class PIcon extends Vue {
		@Prop({ type: String, default: "false" }) icon!: IconType

		iconSvg: any = null

		mounted(): void {
			this.iconSvg = iconMap[this.icon]
		}
	}
