import { ComponentState, Identifiable, ObjectId, Cardinality, DataSource, Condition, PartialState, State } from "../types/model"
import StoreModule from "../model/store/StoreModule"
import { Suspended, expect } from "../model/suspended"

import { cloneDeep, isUndefined, merge } from "lodash-es"

/**
 * A model brings a module in the frontend store (Vuex) and a `DataSource` together. It calls the `DataSource` to
 * `retrieve` or `save` state and updates the state in the frontend store, and vice versa.
 *
 * Models are attached to components, in order to display or update data using those components.
 *
 * The model contains one or more objects of type `T`, of which one can have the focus for mutations.
 * The cardinality `C` determines whether one or many objects are managed.
 *
 * The type parameter `R` defines the type of the root state of the frontend store.
 */
export default abstract class Model<T extends Identifiable, C extends Cardinality, R> {
	protected readonly componentStates = new Set<ComponentState<T>>()
	hydrating = false

	constructor(
		protected readonly cardinality: C,
		protected readonly storeModule: StoreModule<T, C, R>,
		protected readonly dataSource?: Suspended<DataSource<T, C>>
	) {
		storeModule.subscribe((field, value, id) => {

			if (this.state.id === id || this.cardinality === Cardinality.ONE) {
				this.state[field] = value

				for (const componentState of this.componentStates) {
					componentState.state[field] = value
				}
			}
		})
	}
	protected abstract state: PartialState<T>

	/**
	 * Returns an state object intended to serve as initial state.
	 */
	protected abstract defaultState(): PartialState<T>

	/**
	 * Saves the state in the data source and returns whether the operation succeeded.
	 */
	abstract save(componentState: ComponentState<T>): Promise<boolean>

	/**
	 * Deletes the state from the data source.
	 */
	abstract delete(componentState: ComponentState<T>, id: ObjectId): Promise<void>

	/**
	 * Connects the data in the store to the component state.
	 */
	async attach(componentState: ComponentState<T>): Promise<void> {
		this.componentStates.add(componentState)
		await this.hydrate()

		// For singleton documents (root or subdocuments), set the focus immediately. For arrays, focus on the previously
		// focussed item if known by passing `null`. TODO: improper use of null here. Null is used for unknown but persisted id's.
		if (this.cardinality === Cardinality.ONE && this.focus() || this.cardinality === Cardinality.MANY && this.focus(null)) {
			componentState.condition = Condition.CLEAN
		}
	}

	/**
	 * Sets the model state to the element with the given id, and returns whether the element is found.
	 */
	focus(id?: ObjectId): boolean {
		const storeState = this.storeModule.retrieve(id)
		this.storeModule.focus(storeState?.id)
		this.state = {
			// Create an empty state object to make sure that all fields are initialized.
			...this.defaultState(),
			// The state needs to be cloned, otherwise the component can directly mutate state-data.
			...cloneDeep(storeState)
		}
		this.propagateState()

		return !!storeState
	}

	/**
	 * Resets the state to the default.
	 */
	unfocus(): void {
		this.hydrating = true
		this.state = this.defaultState()
		this.propagateState()
		this.storeModule.focus(undefined)
		this.hydrating = false
	}

	private propagateState(): void {
		for (const componentState of this.componentStates) {
			Object.assign(componentState.state, this.state)
			componentState.condition = isUndefined(this.state.id) ? Condition.PRISTINE : Condition.CLEAN
		}
	}

	/**
	 * Hydrates the store module if not yet hydrated. Retrieves data from the data source if needed.
	 */
	async hydrate(): Promise<void> {
		if (!this.storeModule.hydrated && !this.hydrating) {
			this.hydrating = true
			const dataSource = await expect(this.dataSource)
			this.storeModule.loading = true

			try {
				const data = await dataSource?.retrieve()
				if (data) {
					const state: State<T, C> = this.cardinality === Cardinality.ONE ?
						merge(this.defaultState(), data as T) as State<T, C> :
						{ items: data as ReadonlyArray<T> } as State<T, C>

					this.storeModule.hydrate(state)
				}
			} finally {
				this.storeModule.loading = false
			}

			this.hydrating = false
		}
	}

	/**
	 * Returns the current state for readonly access.
	 */
	get(): PartialState<T> {
		return this.state
	}
}
