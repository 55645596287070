
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop, Provide, Watch} from "vue-property-decorator"

	import keys from "lib/misc/keys"
	import compare from "lib/function/compare"

	import {Filter, Range, Sort, View} from "./types"

	@Component
	export default class DataView<T> extends Vue {
		@Prop({required: true}) data!: Array<T>
		@Prop({required: false, type: Object, default: () => ({})}) sort!: Sort<T>
		@Prop({required: false, type: Object, default: () => ({})}) filter!: Filter<T>
		@Prop({required: false, type: Object, default: () => ({offset: 0, limit: Infinity})}) range!: Range

		sortDirective: Sort<T> = {}
		filterDirective: Filter<T> = {}
		rangeDirective: Range = this.range ? {...this.range} : {offset: 0, limit: Infinity}

		@Provide() view: View<T> = {
			data: {
				all: [],
				filtered: [],
				range: []
			},
			sort: (field, order) => {
				// @ts-ignore: Argument of type 'Sort<T>' is not assignable to parameter of type 'SortOrder[]'.
				// The typing of Vue.set apparently makes TS infer the directive as an array.
				Vue.set(this.sortDirective, field, order)
			},
			filter: (field, predicate) => {
				if (predicate) {
					// @ts-ignore: Argument of type 'Filter<T>' is not assignable to parameter of type '(Predicate<T[F]> | null)[]'.
					Vue.set(this.filterDirective, field, predicate)
				} else {
					// @ts-ignore: Argument of type 'Filter<T>' is not assignable to parameter of type '{}[]'.
					Vue.delete(this.filterDirective, field)
				}
			},
			range: (offset, limit) => {
				this.rangeDirective.offset = offset
				this.rangeDirective.limit = limit
			}
		}

		@Watch("data", {deep: true})
		applyDirectives(): void {
			this.view.data.all = this.data

			const filterFields = keys(this.filterDirective)
			const filtered = this.data.filter(
				record => filterFields.every(
					field => !this.filterDirective[field] || this.filterDirective[field]!(record[field])
				)
			)

			this.view.data.filtered = [...filtered] // Need to make a copy, because sort is in-place.

			const sortFields = keys(this.sortDirective)
			filtered.sort(
				(a, b) => {
					for (const field of sortFields) {
						const order = this.sortDirective[field]!
						const asort = a[field]
						const bsort = b[field]
						const afiled = typeof asort === "string" ? asort.toUpperCase() : a[field]
						const bfiled = typeof bsort === "string" ? bsort.toUpperCase() : b[field]
						const comparison = compare(afiled, bfiled)
						if (order === 0 || comparison === 0) {
							// For this field, the order does not matter or the two values are equal.
							continue
						}
						return order * comparison
					}
					return 0
				}
			)

			this.view.data.range = filtered.slice(this.rangeDirective.offset, this.rangeDirective.offset + this.rangeDirective.limit)
		}

		beforeMount(): void {
			this.applyDirectives()
			// Stacking @Watch decorators doesn't seem to work, so we would need separate watchers. The $watch api is shorter.
			this.$watch("sortDirective", () => this.applyDirectives(), {deep: true})
			this.$watch("filterDirective", () => this.applyDirectives(), {deep: true})
			this.$watch("rangeDirective", () => this.applyDirectives(), {deep: true})
		}

		@Watch("sort")
		sortChanged(): void {
			this.sortDirective = this.sort
		}

		@Watch("filter")
		filterChanged(): void {
			this.filterDirective = this.filter
		}

		@Watch("range")
		rangeChanged(): void {
			this.rangeDirective = {...this.range}
		}

	}
