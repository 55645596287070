
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	import { HTMLEvent } from "@portal-components/ui/utils/events"

	@Component
	export default class PDateInput extends Vue {
		@Prop({ type: Date }) value?: Date
		@Prop({ type: Boolean, default: false }) noLabel?: string
		@Prop({ type: Boolean, default: false }) required?: string

		day = ""
		month = ""
		year = ""

		readonly dayLength = 2
		readonly monthLength = 2
		readonly yearLength = 4

		updateValue(): void {
			this.$emit(HTMLEvent.INPUT, new Date(`${ this.year }-${ this.month }-${ this.day }`))
		}

		padDay(): void {
			this.day = this.day.padStart(this.dayLength, "0")
		}

		padMonth(): void {
			this.month = this.month.padStart(this.monthLength, "0")
		}

		padYear(): void {
			this.year = this.year.padStart(this.yearLength, "0")
		}
	}
