
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Inject } from "vue-property-decorator"

	import PButton from "@portal-components/ui/input/PButton.vue"
	import PPageSlide from "@portal-components/customer-journey/PPageSlide.vue"
	import PProgressCircle from "@portal-components/ui/PProgressCircle.vue"
	import { CustomerJourneyNamespace } from "@portal-components/customer-journey/configuration"
	import type { CustomerJourneyControls, OutroConfig } from "@portal-components/customer-journey/configuration"
	import POutroItems from "@portal-components/customer-journey/POutroItems.vue"

	@Component({
		components: {
			PButton,
			POutroItems,
			PPageSlide,
			PProgressCircle
		}
	})
	export default class POutro extends Vue {
		@Inject(CustomerJourneyNamespace.CUSTOMER_JOURNEY) customerJourneyControls!: CustomerJourneyControls
		@Inject(CustomerJourneyNamespace.OUTRO) outroConfig!: OutroConfig

		navigateToDashboard(): void {
			location.href = "/"
		}
	}
