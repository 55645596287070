
	import Vue from "vue"
	import Component from "vue-class-component"

	import PButton from "@portal-components/ui/input/PButton.vue"
	import { SHOW_TOAST, Toast, eventBus, ToastType, CONFIRM_TOAST, ConfirmationToast } from "@portal-components/ui/utils/toaster"

	@Component({
		components: {
			PButton
		}
	})
	export default class PToaster extends Vue {
		readonly toasts: Array<Toast> = []
		readonly baseTranslationKey = "toast"

		confirmToast: ConfirmationToast | null = null
		delay: number | undefined = undefined

		mounted(): void {
			eventBus.$on(SHOW_TOAST, async (toast: Toast) => {
				if (toast.type === ToastType.CONFIRM) {
					const confirmed = await this.confirm(toast as ConfirmationToast)
					eventBus.$emit(CONFIRM_TOAST, confirmed)
				} else {
					await this.show(toast)
				}
			})
		}

		destroyed(): void {
			eventBus.$off(SHOW_TOAST)
		}

		handleResponse(confirmed: boolean): void {
			clearTimeout(this.delay)
			this.delay = undefined
			this.confirmToast = null
			eventBus.$emit(CONFIRM_TOAST, confirmed)
		}

		reject(): void {
			clearTimeout(this.delay)
			this.delay = undefined
			this.confirmToast = null
			eventBus.$emit(CONFIRM_TOAST, false)
		}

		private show(toast: Toast): Promise<void> {
			return new Promise<void>(resolve => {
				this.toasts.push(toast)

				setTimeout(() => {
					this.toasts.splice(this.toasts.indexOf(toast), 1)
					resolve()
				}, toast.durationMs)
			})
		}

		private confirm(toast: ConfirmationToast): Promise<boolean> {
			return new Promise<boolean>(resolve => {
				this.confirmToast = toast
				this.delay = window.setTimeout(() => {
					this.confirmToast = null
					resolve(true)
				}, toast.durationMs)
			})
		}
	}
