import { Store } from "vuex"

import { Identifiable, ObjectId, Cardinality, State, PartialState, CollectionState } from "../../types/model"
import { Function3 } from "../../types/function"

import entries from "../../misc/entries"

import { MutationType } from "../../model/store/mutations"
import { GetterType } from "../../model/store/getters"

import { isUndefined } from "lodash-es"

/**
 * Abstracts a namespaced Vuex module.
 */
export default class StoreModule<T extends Identifiable, C extends Cardinality, R> {
	private readonly store: Store<R>
	private readonly namespace: string
	private readonly cardinality: C
	private state?: State<T, C>

	constructor(store: Store<R>, namespace: string, cardinality: C) {
		this.store = store
		this.namespace = namespace && `${ namespace }/` // Use a slash only for submodules (where namespace is not empty).
		this.cardinality = cardinality
		this.populate()
	}

	/**
	 * Returns whether the data in the store module has been explicitly hydrated from a data source.
	 * This is determined by testing whether the `id` of the object (or objects) is `undefined` (not hydrated),
	 * or `null`/ a string (hydrated).
	 */
	get hydrated(): boolean {
		if (isUndefined(this.state)) {
			this.populate()
		}

		if (isUndefined(this.state)) {
			return false
		} else if (this.cardinality === Cardinality.MANY) {
			// In arrays, object id's cannot be null.
			return (this.state as CollectionState<T>).items.some(item => !!item.id)
		} else {
			// For root documents or subdocuments, the id must be something other than undefined.
			return !isUndefined(this.state.id)
		}
	}

	private populate(): void {
		this.state = this.store.getters[this.namespace + GetterType.STATE]
	}

	set loading(loading: boolean) {
		this.store.commit(this.namespace + MutationType.LOADING, loading)
	}

	/**
	 * Searches the store module for the object with the given id and returns it if found.
	 */
	retrieve(id: ObjectId): T | undefined {
		if (isUndefined(this.state)) {
			return undefined
		} else if (this.cardinality === Cardinality.MANY) {
			// If the id is `null`, retrieve the item that is currently in focus.
			// We're using `null` for that because `undefined` has meaning for a collection. See ObjectId doc. This will change when that is improved.
			const state = this.state as CollectionState<T>
			const searchId = id === null ? state.id : id
			return state.items.find(item => item.id === searchId)
		} else {
			// For singleton state, we don't care whether the id matches or not. We don't know whether or not there is an id on the server.
			return this.state as T
		}
	}

	/**
	 * Updates the value for the field in the backing store.
	 */
	update<F extends keyof T>(field: F, value: T[F], id: ObjectId): void {
		this.store.commit(this.namespace + MutationType.UPDATE, { id, field, value })
	}

	/**
	 * Inserts or overwrites the object in the store. The object is matched by id.
	 */
	save(data: T): void {
		this.store.commit(this.namespace + MutationType.SAVE, data)
	}

	delete(id: ObjectId): void {
		this.store.commit(this.namespace + MutationType.DELETE, id)
	}

	/**
	 * Hydrates the backing store module.
	 */
	hydrate(data: State<T, C>): void {
		this.store.commit(this.namespace + MutationType.HYDRATE, data)
		this.populate()
	}

	dehydrate(): void {
		this.store.commit(this.namespace + MutationType.DEHYDRATE)
		this.populate()
	}

	focus(id: ObjectId): void {
		if (this.cardinality === Cardinality.MANY) {
			this.store.commit(this.namespace + MutationType.FOCUS, id)
		}
	}

	/**
	 * Subscribes to updates on the backing store module.
	 * This is used by models to propagate updates to attached components.
	 */
	subscribe(handler: Function3<keyof PartialState<T>, PartialState<T>[keyof T], ObjectId, void>): void {
		this.store.subscribe(mutation => {
			const payload = mutation.payload
			switch (mutation.type) {
				case this.namespace + MutationType.UPDATE: {
					handler(payload.field, payload.value, payload.id)
					break
				}
				case this.namespace + MutationType.SAVE: {
					for (const [field, value] of entries(payload as PartialState<T>)) {
						handler(field, value, payload.id)
					}
					break
				}
				default: break
			}
		})
	}
}
