import {Module} from "vuex"
import {RootState} from "../index"
import {Message, Messages} from "./types"
import { convert } from "./conversion"
import { api } from "api/index"
import { resources } from "api/resources"

const messagesState: Messages = {
	messages: [],
	loaded: false
}

const messagesResources = resources.messages

const messagesModule: Module<Messages, RootState> = {
	actions: {
		async markMessageAsRead(state, message: Message): Promise<Response> {
			state.commit("readMessage", message)
			return api.patch(messagesResources.markAsRead, { messageId: message.id }).response
		},
		async getMessages(state): Promise<Array<Message>> {
			const response = await api.get(messagesResources.getMessages).response
			const messages = convert(await response.json()) ?? []
			state.commit("hydrate", messages)
			return messages
		}
	},
	getters: {
		unreadMessages: state => state.messages.filter(message => !message.read).length,
		sortedMessages: state => state.messages.slice().sort((a, b) => b.date.getTime() - a.date.getTime())
	},
	mutations: {
		hydrate(state, payload: Array<Message>): void {
			state.messages = payload
		},
		readMessage(state, message: Message): void {
			const msg = state.messages.find(m => m.id === message.id)
			if (msg) {msg.read = true}
		}
	},
	namespaced: true,
	state: messagesState
}

export default messagesModule
