
	import Component from "vue-class-component"
	import { Prop, Watch, Emit } from "vue-property-decorator"

	import PInput from "@portal-components/ui/input/PInput.vue"
	import { HTMLEvent } from "@portal-components/ui/utils/events"

	@Component
	export default class PTextInput extends PInput {
		@Prop({ type: String, default: "text" }) type!: string
		@Prop({ type: String, default: "" }) value!: string
		@Prop({ type: Boolean, default: false }) autofocus!: boolean

		internalValue = ""

		@Watch("value", { immediate: true })
		valueChanged(newValue: string): void {
			this.internalValue = newValue
		}

		@Emit(HTMLEvent.INPUT)
		onChange(): string {
			return this.internalValue
		}
	}
