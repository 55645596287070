import { ConversionMap, required, optional } from "@lib/types/import"
import { Document, Download } from "./types"
import { alphanumeric, date, datetime, numeric } from "@lib/import/convert"
import { many, def } from "@lib/import/extract"

const download: ConversionMap<Download> = {
	at: ["at", datetime, required],
	by: ["by", alphanumeric, required]
}

const document: ConversionMap<Document> = {
	id: ["id", alphanumeric, required],
	identifier: ["identifier", alphanumeric, required],
	bucket: ["bucket", alphanumeric, required],
	type: ["type", alphanumeric, optional],
	published: ["published", date, optional],
	fileName: ["fileName", alphanumeric, required],
	mimeType: ["mimeType", alphanumeric, optional],
	size: ["size", numeric, optional],
	created: ["created", datetime, required],
	modified: ["modified", datetime, required],
	downloads: ["downloads", def([], many(download)), required]
}

export default many(document)
