
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class Card extends Vue {
		hasTitle = false
		hasIcon = false
		hasBadge = false

		mounted(): void {
			this.hasTitle = !!this.$slots.title
			this.hasIcon = !!this.$slots.icon
			this.hasBadge = !!this.$slots.badge
		}
	}
