
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	@Component
	export default class PCard extends Vue {
		@Prop({ type: Boolean, default: false }) primary!: boolean

		hasTitle = false
		hasIcon = false
		hasBadge = false

		mounted(): void {
			this.hasTitle = !!this.$slots.title
			this.hasIcon = !!this.$slots.icon
			this.hasBadge = !!this.$slots.badge
		}
	}
