import { RequestMethod, Parameters, Request, RequestOptions } from "../types/request"
import { MessageEventStream } from "../types/stream"

import RequestStrategy from "../request/RequestStrategy"
import request from "../request/request"
import openStream from "../request/openStream"

/**
 * A `RequestStrategy` that performs a standard request. Cookies, if present, are sent with the request.
 */
export default class FetchGateway implements RequestStrategy {

	request(method: RequestMethod, resource: string, data?: Parameters, _options?: RequestOptions): Request {
		return request(method, resource, data, { credentials: "include", headers: { "Content-Type": "application/json" } })
	}

	openStream(endpoint: string): MessageEventStream {
		return openStream(endpoint)
	}
}
