import { resources } from "api/resources"
import { api } from "api/index"
import { Participant, EndEmployement, UpdateParticipant, PeriodicExtraSavingsRequest, OneOffExtraSavingsRequest } from "api/models/participants"

import convertParticipants, { convertOneOffSavingsRequests, convertPeriodicSavingsRequests } from "api/conversions/participants/participants"

class ParticipantClient {
	async getParticipantsByAgreementId(agreementId: string): Promise<Array<Participant>> {
		return convertParticipants(await api.getJson(resources.company.agreements.participants(agreementId)))
	}

	async endEmployment(participantId: string, model: EndEmployement): Promise<void> {
		const response = await api.post(resources.participant.endEmployment(participantId), model).response

		if (!response.ok) {
			throw await response.json()
		}
	}

	async updateParticipant(agreementId: string, participantId: string, model: UpdateParticipant): Promise<void> {
		const response = await api.put(resources.participant.updateParticipant(agreementId, participantId), model).response

		if (!response.ok) {
			throw await response.json()
		}
	}

	async getOneOffExtraSavings(): Promise<Array<OneOffExtraSavingsRequest>> {
		return convertOneOffSavingsRequests(await api.getJson(resources.participant.oneOffExtraSavings))
	}

	async getPeriodicExtraSavings(): Promise<Array<PeriodicExtraSavingsRequest>> {
		return convertPeriodicSavingsRequests(await api.getJson(resources.participant.periodicExtraSavings))
	}

	async confirmPeriodicExtraSavings(model: PeriodicExtraSavingsRequest): Promise<void> {
		const reponse = await api.post(resources.participant.periodicExtraSavings, model).response

		if (!reponse.ok) {
			throw await reponse.json()
		}
	}
}

export default new ParticipantClient()
