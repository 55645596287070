import { EnumOption } from "store/types"

export interface CompanyState {
	company?: Company
	loading: boolean
}

export interface Agreement {
	externalIdentifier: string
	name: string
	startDate: Date
	endDate?: Date
	originalStartDate: Date
	enrollmentAge: number
	pensionAge: number
	packageCharacteristic: string
	packageVariant: string
	insurance: Insurance
	franchise: EnumOption<Franchise, number>
	maximumSalary: EnumOption<MaximumSalary, number>
	scale: Scale
	survivorsPension: SurvivorsPension
	employeeContribution: EmployeeContribution
	investmentFreedom: InvestmentFreedom
	intermediary?: Intermediary
	dataDeliveryChannel?: DataDeliveryChannel
	premiumFrequency?: PremiumFrequency
	newPoliciesAllowed?: boolean
	productType?: ProductFrameworkType
}

export interface Insurance {
	anwGap: AnwGap
	premiumWaiver: boolean
	extraSavings: boolean
}

export interface Scale {
	type: ScaleType
	percentage?: number
	utilizationRate?: number
	fixedBasePremium?: number
}

export interface SurvivorsPension {
	type: SurvivorsPensionType
	partnerAccrual?: number
	orphanAccrual?: number
	indexation?: number
	yearsOfServiceDetermination?: YearsOfServiceDetermination
}

export interface EmployeeContribution {
	type: EmployeeContributionType
	percentage?: number
}

export enum Franchise {
	UNMARRIED_AVERAGE_PAY = "UNMARRIED_AVERAGE_PAY",
	MARRIED_AVERAGE_PAY = "MARRIED_AVERAGE_PAY",
	UNMARRIED_FINAL_PAY = "UNMARRIED_FINAL_PAY",
	MARRIED_FINAL_PAY = "MARRIED_FINAL_PAY",
	UNMARRIED_WITTEVEEN = "UNMARRIED_WITTEVEEN",
	MARRIED_WITTEVEEN = "MARRIED_WITTEVEEN",
	CUSTOM = "CUSTOM"
}

export enum MaximumSalary {
	FISCAL_MAXIMUM = "FISCAL_MAXIMUM",
	CUSTOM = "CUSTOM"
}

export enum ScaleType {
	AGE_DEPENDENT = "AGE_DEPENDENT",
	FIXED_PREMIUM = "FIXED_PREMIUM",
	TRANSITIONAL_ARRANGEMENT = "TRANSITIONAL_ARRANGEMENT"
}

export enum SurvivorsPensionType {
	RISK_INSURANCE = "RISK_INSURANCE",
	RESTITUTION = "RESTITUTION"
}

export enum EmployeeContributionType {
	PREMIUM_BASE = "PREMIUM_BASE",
	PREMIUM = "PREMIUM"
}

export enum InvestmentFreedom {
	NONE = "NONE",
	LIMITED = "LIMITED",
	COMPLETE = "COMPLETE"
}

export enum DataDeliveryChannel {
	UPA = "UPA",
	PORTAL = "PORTAL",
	BENEFITS_PLAZA = "BENEFITS_PLAZA"
}

export enum PremiumFrequency {
	MONTHLY = "MONTHLY",
	QUARTERLY = "QUARTERLY",
	YEARLY = "YEARLY",
	BI_ANNUALLY = "BI_ANNUALLY"
}

export enum ProductFrameworkType {
	WTP = "WTP",
	WNP = "WNP"
}

export interface Company {
	externalIdentifier?: string
	name?: string
	address?: Partial<Addresses>
	contact?: Contact
	chamberOfCommerceNumber?: string
	payrollTaxNumber?: string
	agreements: Array<Agreement>
}

export interface Addresses {
	business?: Address
	postal?: Address
}

export interface Address {
	street?: string
	number?: string
	postalCode?: string
	city?: string
}

export interface Contact {
	name?: string
	email?: string
	phoneNumber?: string
}

export interface Permission {
	viewParticipants?: boolean
	administrationForEmployer?: boolean
}

export interface Intermediary {
	externalIdentifier: string
	name?: string
	contact?: Contact
	address?: Partial<Addresses>
	license?: string
	permission?: Permission
}

export interface AuthorizeModel {
	companyId?: string
	intermediaryId: string
	agreementId: string
	viewParticipants?: boolean
	administrationForEmployer?: boolean
}

export interface AnwGap {
	active: boolean
	defaultInsured?: boolean
	coverageOptions?: Array<CoverageOption>
	coverageDefault?: CoverageDefault
	indexation?: number
	hasEmployeeContribution?: boolean
}

export interface CoverageDefault {
	percentage: number
	amount?: number
}

export interface CoverageOption {
	active?: boolean
	percentage?: number
	amount?: number
}

export enum YearsOfServiceDetermination {
	IN_SERVICE_DATE = "IN_SERVICE_DATE",
	START_DATE_PENSION_SCHEME = "START_DATE_PENSION_SCHEME"
}
