export function filterContainsValue(filterValue: string): boolean {
	if (!filterValue || filterValue.trim() === "") {
		return false
	}
	return true
}

export function columnContainsValue(colValue: string): boolean {
	if (colValue === undefined || colValue === null) {
			return false
	}
	return true
}

/**
 * Extracts the column value from the given row data
 * @param rowData - The data object from which the value should be extracted
 * @param key - The key that should be used for the extracting (example: "company.name")
 */
export function extractValue<T extends string | number | Date>(data: object, key: string): T | undefined {
	return key.split(".").reduce<T | object>((value, dataKey) => {
		if (!!value && typeof value === "object") {
			return value[dataKey as keyof object]
		}
		return value
	}, data) as T
}
