
	import Vue from "vue"
	import Component from "vue-class-component"
	import DataView from "@components/data/view/DataView.vue"
	import Grid from "custom/table/Grid.vue"
	import { Participant } from "api/models/participants"
	import FilterField from "custom/table/FilterField.vue"
	import { filterFunctions } from "custom/table/utils/filter-functions"
	import { participantModule, companyModule } from "store/modules"
	import PButton from "@portal-components/ui/input/PButton.vue"
	import { employeesEventBus, EmployeesTabs, changeTabEvent } from "pages/Employees.vue"
	import { StoreAction } from "@lib/types/vuex"
	import { GridColumn } from "custom/table/model/grid-column"
	import { Agreement } from "store/company/types"

	@Component({
		components: {
			Grid,
			DataView,
			FilterField,
			PButton
		}
	})

	export default class EmployeeTable extends Vue {
		@participantModule.Action setActiveParticipant!: StoreAction<Participant>
		@participantModule.Getter participants!: Array<Participant>
		@companyModule.Getter agreement?: Agreement

		readonly baseTranslationKey = "employees.overview"
		readonly filters = filterFunctions
		readonly columns: ReadonlyArray<GridColumn> = [
			{ title: `${ this.baseTranslationKey }.column.policyNumber`, field: "policyNumber", class: "col-policy-number" },
			{ title: `${ this.baseTranslationKey }.column.fullName`, field: "fullName", class: "col-fullName" },
			{ title: `${ this.baseTranslationKey }.column.dateOfBirth`, field: "dateOfBirth", class: "col-date-of-birth" },
			{ title: `${ this.baseTranslationKey }.column.civilStatus`, field: "civilStatus", class: "col-civil-status" },
			{ title: `${ this.baseTranslationKey }.column.startDateEmployment`, field: "startDate", class: "col-start-date" },
			{ title: `${ this.baseTranslationKey }.column.salary`, field: "salary", class: "col-salary" },
			{ title: `${ this.baseTranslationKey }.column.fte`, field: "fte", class: "col-fte" }
		]

		toSignUpEmployees(): void {
			location.href = "sign-up-employees.html"
		}

		async toChangeEmployeeDetails(participant: Participant): Promise<void> {
			await this.setActiveParticipant(participant)
			employeesEventBus.$emit(changeTabEvent, EmployeesTabs.CHANGES)
		}
	}
