
	import Component from "vue-class-component"
	import { Prop, Watch, Emit, Ref } from "vue-property-decorator"

	import PInput from "@portal-components/ui/input/PInput.vue"
	import { HTMLEvent } from "@portal-components/ui/utils/events"

	@Component
	export default class PNumberInput extends PInput {
		@Prop({ type: Number, default: null }) value!: number | null
		@Prop({ type: String }) min?: string
		@Prop({ type: String }) max?: string
		@Prop({ type: String }) stepSize?: string

		@Ref() internalInput!: HTMLInputElement 

		internalValue = ""

		@Watch("value", { immediate: true })
		valueChanged(newValue: number | null): void {
			this.internalValue = newValue ? newValue.toString() : ""
		}

		@Emit(HTMLEvent.INPUT)
		onChange(): number {
			return parseFloat(this.internalValue)
		}

		focus(): void {
			this.internalInput.focus()
		}
	}
