import { OneOffExtraSavingsRequest, Participant, PeriodicExtraSavingsRequest, SavingsRequestType, SavingsRequestStatus } from "api/models/participants"
import { ConversionMap, required, ConversionFunction, optional } from "@lib/types/import"
import { alphanumeric, date, datetime, enumConstant, numeric } from "@lib/import/convert"
import { mandatory, many } from "@lib/import/extract"

import { CivilStatusType } from "@lib/types/personal/policyholder"

export const civilStatusType: ConversionFunction<CivilStatusType> = (value: CivilStatusType) =>
	["SINGLE", "COHABITATION", "LEGAL_COHABITATION", "CIVIL_PARTNERSHIP", "MARRIED"].includes(value) ? value : undefined

const participant: ConversionMap<Participant> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	policyNumber: ["policyNumber", alphanumeric, optional],
	fullName: ["fullName", alphanumeric, required],
	dateOfBirth: ["dateOfBirth", date, required],
	civilStatus: ["civilStatus", civilStatusType, required],
	startDate: ["employmentStartDate", date, required],
	salary: ["salary", numeric, required],
	partTimePercentage: ["partTimePercentage", numeric, required]
}

const oneOffExtraSavingsRequest: ConversionMap<OneOffExtraSavingsRequest> = {
	participantId: ["participantId", alphanumeric, required],
	eventDate: ["eventDate", date, required],
	applicationDateTime: ["applicationDateTime", datetime, required],
	policyId: ["policyId", alphanumeric, required],
	name: ["name", alphanumeric, required],
	amount: ["amount", numeric, required]
}

const periodicExtraSavingsRequest: ConversionMap<PeriodicExtraSavingsRequest> = {
	...oneOffExtraSavingsRequest,
	status: ["status", enumConstant(SavingsRequestStatus), required],
	type: ["type", enumConstant(SavingsRequestType), required]
}

export default mandatory(many(participant, undefined, (exception, { externalIdentifier }) => {
	// tslint:disable-next-line:no-console
	console.error(`Conversion failed for participant ${ externalIdentifier }`, exception instanceof Error ? exception.message : exception)
}))
export const convertOneOffSavingsRequests = mandatory(many(oneOffExtraSavingsRequest))
export const convertPeriodicSavingsRequests = mandatory(many(periodicExtraSavingsRequest))
