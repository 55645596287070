
import { GetterTree } from "vuex"
import { RootState } from "store/index"
import { AuthenticationState } from "./types"
import localStorageClient from "api/clients/local-storage-client"

const getters: GetterTree<AuthenticationState, RootState> = {
	loggedIn(state: AuthenticationState): boolean {
		const faAuthentication = localStorageClient.getItem("2faAuthentication")
		if(faAuthentication === "1") {
			return false
		} else {
			return state.loggedIn
		}
	}
}

export default getters
