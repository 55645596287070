import { MutationTree } from "vuex"
import { Documents, Document } from "./types"

export enum MutationType {
	HYDRATE = "hydrate",
	DOWNLOADS = "downloads"
}

const mutations: MutationTree<Documents> = {
	[MutationType.HYDRATE](state: Documents, payload: Array<Document>): void {
		state.documents = payload
	},
	[MutationType.DOWNLOADS](state: Documents, documentId: string): void {
		const newDownload = {
			at: new Date(),
			by: "portal"
		}
		state.documents.find((document: Document) => document.id === documentId)?.downloads.push(newDownload)
	}
}

export default mutations
