
	import Vue, {VNode} from "vue"
	import Component from "vue-class-component"
	import {Prop, Watch} from "vue-property-decorator"

	import Container from "components/generic/Container.vue"

	import {single} from "components/@directives/selectable"
	import TabButton from "components/button/TabButton.vue"

	@Component({
		components: {
			Container
		},
		directives: {
			single
		}
	})
	export default class TabContainer<T = any> extends Vue {
		@Prop({required: false, default: undefined}) value?: T

		children: Array<VNode> = []

		@Watch("value")
		valueChanged(): void {
			const selectedChild = this.children.find(child => child.componentInstance!.$props.value === this.value)
			if (selectedChild) {
				(selectedChild.elm! as HTMLElement).click()
			}
		}

		selectTab(tabButton: TabButton): void {
			this.$emit("input", tabButton.$props.value)
		}

		mounted(): void {
			this.valueChanged()
		}
	}
