import pad from "../../number/pad"
import { Function1 } from "../../types/function"
import { Locale } from "../../types/i18n"
import entries from "../../misc/entries"

type Formatters = {
	[F in keyof Locale["date"]]?: ReadonlyArray<Function1<Date, string>>
}

/**
 * Creates a formatter function that formats the date according to the mask.
 */
export default (locale: Locale): Function1<keyof Locale["date"], Function1<Date, string>> => {
	const formatters: Formatters = {}
	for (const [format, mask] of entries(locale.date)) {
		formatters[format] = mask.map((part: string) => {
			switch (part) {
				case "d":
				case "dd":
					return (date: Date) => pad(part.length, date.getDate())
				case "m":
				case "mm":
					return (date: Date) => pad(part.length, date.getMonth() + 1)
				case "mmm":
					return (date: Date) => locale.months.short[date.getMonth()]
				case "mmmm":
					return (date: Date) => locale.months.long[date.getMonth()]
				case "yy":
					return (date: Date) => pad(2, date.getFullYear() % 100)
				case "yyyy":
					return (date: Date) => date.getFullYear().toString()
				default:
					return (_: Date) => part
			}
		})
	}

	return (format: keyof Locale["date"]): Function1<Date, string> =>
		(date: Date) => formatters[format]!.reduce((result, part) => result + part(date), "")
}
