import Vue from "vue"
import { MutationTree } from "vuex"

import { FieldMutation, Identifiable, ObjectId, CollectionState } from "../../types/model"

import { cloneDeep, isUndefined } from "lodash-es"

export const enum MutationType {
	HYDRATE = "hydrate",
	DEHYDRATE = "dehydrate",
	FOCUS = "focus",
	UPDATE = "update",
	SAVE = "save",
	DELETE = "delete",
	LOADING = "loading"
}

const setState = <T extends Identifiable>(state: T, data: T): void => {
	for (const [field, value] of Object.entries(data)) {
		Vue.set(state, field, value)
	}
}

const clearState = <T extends Identifiable>(state: T): void => {
	for (const field of Object.keys(state)) {
		Vue.set(state, field, undefined)
	}
}

export const singletonMutations = <T extends Identifiable>(): MutationTree<T> => ({
	[MutationType.HYDRATE](state, data: T) {
		setState(state, data)
	},
	[MutationType.DEHYDRATE](state) {
		clearState(state)
	},
	[MutationType.UPDATE](state, mutation: FieldMutation<T>) {
		Vue.set(state, mutation.field as string, mutation.value)
	},
	[MutationType.SAVE](state, data: T) {
		setState(state, data)
	}
})

export const collectionMutations = <T extends Identifiable>(): MutationTree<CollectionState<T>> => ({
	[MutationType.HYDRATE](state, data: CollectionState<T>) {
		// Keep the focus if there is no id coming in with the data.
		setState(state, { ...data, id: !isUndefined(data.id) ? data.id : state.id })
		// Unfocus if there is no item with the id.
		if (!isUndefined(state.id) && !state.items.some(item => item.id === state.id)) {
			state.id = undefined
		}
	},
	[MutationType.DEHYDRATE](state) {
		state.items.length = 0
		state.id = undefined
	},
	[MutationType.FOCUS](state, id: ObjectId) {
		state.id = id
	},
	[MutationType.UPDATE](state, mutation: FieldMutation<T>) {
		const element = state.items.find(item => item.id === mutation.id)
		if (element) {
			element[mutation.field] = cloneDeep(mutation.value)
		}
	},
	[MutationType.SAVE](state, data: T) {
		const index = state.items.findIndex(item => item.id === data.id)
		const clone = cloneDeep(data)
		if (index > -1) {
			state.items.splice(index, 1, clone)
		} else {
			state.items.push(clone)
		}
	},
	[MutationType.DELETE](state, id: ObjectId) {
		const index = state.items.findIndex(item => item.id === id)
		if (index > -1) {
			state.items.splice(index, 1)
		}
	},
	[MutationType.LOADING](state, loading: boolean) {
		state.loading = loading
	}
})
