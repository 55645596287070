import { IdentificationStrategy, UsernamePassword, isJwtToken } from "../../types/security"
import RequestStrategy from "../../request/RequestStrategy"
import RequestError from "../../request/RequestError"
import HttpStatus from "../../request/status"
import { ConversionFunction } from "../../types/import"
import { RequestMethod } from "../../types/request"

import { identity, isUndefined } from "lodash-es"

const jwtIdentifier: ConversionFunction<string> = data => isJwtToken(data) ? data.username : undefined

export interface CredentialEndpoints {
	identification: string
	twoFactorAuthentication?: string
}

/**
 * Identification strategy that sends a POST request containing credentials for identification.
 */
export default class CredentialsIdentification<C extends object = UsernamePassword, T = any> implements IdentificationStrategy<C, T> {
	private _isIdentified: boolean = false
	private _identifier: string | undefined = undefined

	constructor(
		private readonly gateway: RequestStrategy,
		private readonly endpoints: CredentialEndpoints,
		private readonly extractToken: ConversionFunction<T> = identity,
		private readonly extractIdentifier: ConversionFunction<string> = jwtIdentifier
	) { }

	get isIdentified(): boolean {
		return this._isIdentified
	}

	get identifier(): string | undefined {
		return this._identifier
	}

	set identifier(identifier: string | undefined) {
		this._identifier = identifier
		this._isIdentified = true
	}

	async identify(credentials: C): Promise<T> {
		if (this.isIdentified) {
			return Promise.reject(new TypeError("Already identified"))
		}

		const response = await this.gateway.request(RequestMethod.POST, this.endpoints.identification, credentials).response

		switch (response.status) {
			case HttpStatus.OK:
				const data = await response.json()
				const token = this.extractToken(data)

				if (isUndefined(token)) {
					return Promise.reject(new TypeError("Invalid token"))
				}

				this.identifier = this.extractIdentifier(data)
				return token
			case HttpStatus.UNAUTHORIZED:
				return Promise.reject(new RequestError(response))
			default:
				return Promise.reject(new RangeError(`Unexpected status code ${ response.status }`))
		}
	}

	async unidentify(): Promise<boolean> {
		if (this.isIdentified) {
			this._isIdentified = false
			return true
		}
		return false
	}

	async is2FARequired(): Promise<boolean> {
		if (!this.endpoints.twoFactorAuthentication) {
			return false
		}

		const response = await this.gateway.request(RequestMethod.GET, this.endpoints.twoFactorAuthentication).response

		// returns a 401 when the auth service does not have the specified endpoint. set 2faRequired to false if so.
		if (response.status === 401) {
			return false
		}

		return await response.json()
	}

	setIdentifierFromToken(token: T): void {
		this.identifier = this.extractIdentifier(token)
	}
}
