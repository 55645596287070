import { Locale } from "../../types/i18n"

import { isUndefined } from "lodash-es"

export default (locale: Locale) => (n: number, decimals?: number): string => {
	const nString = isUndefined(decimals) ? n.toString() : n.toFixed(decimals)
	const [whole, fraction] = nString.split(".")
	let formatted = whole
	const regex = /(\d+)(\d{3})/
	const replace = `$1${ locale.separator.thousands }$2`

	while (regex.test(formatted)) {
		formatted = formatted.replace(regex, replace)
	}

	if (fraction) {
		formatted += `${ locale.separator.decimals }${ fraction }`
	}

	return formatted
}
