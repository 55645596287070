export const enum Adjust {
	UTC_TO_LOCAL, LOCAL_TO_UTC
}

/**
 * Adjusts the date to the local timezone or to UTC.
 */
export default (adjust: Adjust, date: Date): Date => new Date(
	date.getFullYear(),
	date.getMonth(),
	date.getDate(),
	date.getHours(),
	date.getMinutes() + date.getTimezoneOffset() * (adjust === Adjust.UTC_TO_LOCAL ? 1 : -1),
	date.getSeconds()
)
