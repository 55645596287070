
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import Container from "custom/Container"

	@Component({
		components: {
			Container
		}
	})
	export default class Wizard extends Vue {
		@Prop({ type: Number, default: 0 }) value!: number

		slides = 0

		mounted(): void {
			const slideWrapper = this.$refs.slides as Element
			this.slides = slideWrapper.children.length
		}
	}
