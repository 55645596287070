import { VueConstructor } from "vue"

import { Options, Locale } from "../types/i18n"
import dayFormatter from "../i18n/format/day"
import monthFormatter from "../i18n/format/month"
import dateFormatter from "../i18n/format/date"
import numberFormatter from "../i18n/format/number"
import moneyFormatter from "../i18n/format/money"
import { gender, maritalStatus, status } from "../i18n/format/personal"
import template from "../misc/template"
import round from "../number/round"
import toAge from "../number/toAge"
import truncate from "../number/truncate"

import { get } from "lodash-es"

const link = (_locale: Locale) => (url: string, text?: string): string => `<a href="http://${ url }" target="_blank">${ text || url }</a>`

// tslint:disable-next-line: variable-name
export default (Vue: VueConstructor, options?: Options): void => {
	if (!options) {
		throw new ReferenceError("Options are required")
	}

	// Create filters for display of dates and numbers.
	const locale = options.locale

	const formatLink = link(locale)
	const formatNumber = numberFormatter(locale)
	const formatDate = dateFormatter(locale)
	const formatDay = dayFormatter(locale)
	const formatMonth = monthFormatter(locale)
	const formatMoney = moneyFormatter(locale)
	const formatGender = gender(locale)
	const formatMaritalStatus = maritalStatus(locale)
	const formatStatus = status(locale)

	Vue.filter("locale", (code: string) => get(options.texts, code) || `{{ ${ code }: undefined }}`)
	Vue.filter("template", template)
	Vue.filter("link", formatLink)

	Vue.filter("truncate", truncate)
	Vue.filter("round", (n: number, decimals: number = 0) => round(decimals, n))
	Vue.filter("number", formatNumber)
	Vue.filter("money", formatMoney)
	Vue.filter("longDate", formatDate("long"))
	Vue.filter("shortDate", formatDate("short"))
	Vue.filter("day", formatDay("long"))
	Vue.filter("month", formatMonth("long"))
	Vue.filter("gender", formatGender)
	Vue.filter("maritalStatus", formatMaritalStatus)
	Vue.filter("status", formatStatus)

	// Create filters for number conjugations.
	for (const [code, [singular, plural]] of Object.entries(options.plural)) {
		// Filter with preceding number:
		Vue.filter(code, (n: number) => `${ n } ${ n === 1 ? singular : plural }`)
		// Filter without the preceding number, just the resulting unit:
		Vue.filter(`${ code }Unit`, (n: number) => n === 1 ? singular : plural)
	}
	Vue.filter("age", (n: number) => {
		const { years, months } = toAge(n)
		// The `years` and `months` filters are created based on the `plural` config.
		return `${ Vue.filter("years")(years) }${ months > 0 ? `${ locale.separator.and }${ Vue.filter("months")(months) }` : "" }`
	})

	// Add the separators to the texts so that they can be accessed using `{{ "separator.decimals" | locale }}`.
	options.texts.separator = locale.separator
}
