import Vue from "vue"
import { Store } from "vuex"

import { Identifiable, Cardinality, ImmutableModuleConfig, MutableModuleConfig, PreserveState } from "../../types/model"
import { StorageOptions } from "../../types/storage"

import Model from "../../model/Model"
import MutableModel from "../../model/MutableModel"
import ImmutableModel from "../../model/ImmutableModel"

import StoreModule from "../../model/store/StoreModule"
import { singletonMutations, collectionMutations } from "../../model/store/mutations"
import { singletonGetters, collectionGetters } from "../../model/store/getters"

import { get, set } from "lodash-es"

const DEFAULT_KEY = "store"

export default class ModelFactory<R> {
	private readonly store: Store<R>
	private readonly storedState: R | undefined
	private readonly options?: Required<StorageOptions>
	private preserveState: boolean = true
	private readonly whitelist: Array<ReadonlyArray<string>> = [] // The paths to manage in storage.

	constructor(store: Store<R>, options?: StorageOptions) {
		this.store = store
		if (!process.env.SERVER && options) {
			this.options = {
				...options,
				key: options.key || DEFAULT_KEY
			}

			this.storedState = options.storage.retrieve(this.options.key)
			store.subscribe(() => {
				if (this.options && this.preserveState) {
					const persistState = this.whitelist.reduce(
						(state, path) => set(state, path, get(store.state, path)),
						{}
					)
					this.options.storage.store(this.options.key, persistState)
				}
			})
		}
	}

	expire(): void {
		this.preserveState = false
		if (this.options) {
			this.options.storage.discard(this.options.key)
		}
	}

	/**
	 * Creates a store module for a single object, which is mutable. The structure of the module state is
	 * derived from the given validation rules.
	 */
	mutableSingleton<T extends Identifiable, K>(config: MutableModuleConfig<T, Cardinality.ONE, R, K>): MutableModel<T, Cardinality.ONE, R, K> {
		const storeModule = this.createModule<T, Cardinality.ONE>(Cardinality.ONE, config.namespace, config.preserveState)

		this.store.registerModule(config.namespace.split("/"), {
			mutations: singletonMutations<T>(),
			getters: { ...config.getters, ...singletonGetters<T, R>() },
			actions: config.actions,
			namespaced: true
		})

		return new MutableModel(Cardinality.ONE, storeModule, config.rules, config.context, config.dataSource)
	}

	/**
	 * Creates a store module for a single object, which is immutable.
	 */
	immutableSingleton<T extends Identifiable>(config: ImmutableModuleConfig<T, Cardinality.ONE, R>): Model<T, Cardinality.ONE, R> {
		const storeModule = this.createModule<T, Cardinality.ONE>(Cardinality.ONE, config.namespace, config.preserveState)

		this.store.registerModule(config.namespace.split("/"), {
			state: config.initialState as T, // PartialState must be cast. TODO: see if we can use PartialState<T> everywhere instead of T.
			mutations: singletonMutations<T>(),
			getters: { ...config.getters, ...singletonGetters<T, R>() },
			actions: config.actions,
			namespaced: true
		})

		return new ImmutableModel(Cardinality.ONE, storeModule, config.dataSource)
	}

	/**
	 * Creates a store module for an array of (mutable) objects. The structure of the objects in the module state is
	 * derived from the given validation rules.
	 */
	mutableCollection<T extends Identifiable, K>(config: MutableModuleConfig<T, Cardinality.MANY, R, K>): MutableModel<T, Cardinality.MANY, R, K> {
		const storeModule = this.createModule<T, Cardinality.MANY>(Cardinality.MANY, config.namespace, config.preserveState)

		this.store.registerModule(config.namespace.split("/"), {
			state: {
				items: [],
				id: undefined,
				loading: false
			},
			mutations: collectionMutations<T>(),
			getters: { ...config.getters, ...collectionGetters<T, R>() },
			actions: config.actions,
			namespaced: true
		})

		return new MutableModel(Cardinality.MANY, storeModule, config.rules, config.context, config.dataSource)
	}

	/**
	 * Creates a store module for an array of (immutable) objects. The structure of the objects in the module state is
	 * derived from the given validation rules.
	 */
	immutableCollection<T extends Identifiable>(config: ImmutableModuleConfig<T, Cardinality.MANY, R>): Model<T, Cardinality.MANY, R> {
		const storeModule = this.createModule<T, Cardinality.MANY>(Cardinality.MANY, config.namespace, config.preserveState)

		this.store.registerModule(config.namespace.split("/"), {
			state: {
				items: config.initialState,
				id: undefined,
				loading: false
			},
			mutations: collectionMutations<T>(),
			getters: { ...config.getters, ...collectionGetters<T, R>() },
			actions: config.actions,
			namespaced: true
		})

		return new ImmutableModel(Cardinality.MANY, storeModule, config.dataSource)
	}

	/**
	 * Creates a store module for the namespace, and optionally hydrates it at the appropriate time with the existing state in the store.
	 */
	private createModule<T extends Identifiable, C extends Cardinality>(
		cardinality: C,
		namespace: string,
		preserveState: PreserveState = PreserveState.COMPLETE
	): StoreModule<T, C, R> {
		const storeModule = new StoreModule<T, C, R>(this.store, namespace, cardinality)

		const path = namespace.split("/")
		if (this.preserveState) {
			this.whitelist.push(path)
		}

		// Immediate hydration, before the rendering of the page, could lead to the rendering of elements
		// that are not rendered server side, resulting in runtime errors.
		Vue.nextTick(() => {
			if (preserveState !== PreserveState.NONE) {
				const state = get(this.storedState, path)
				if (state) {
					if (cardinality === Cardinality.MANY && preserveState === PreserveState.FOCUS) {
						const id = state.id
						storeModule.dehydrate()
						storeModule.focus(id)
					} else {
						storeModule.hydrate(state)
					}
				}
			} else {
				storeModule.dehydrate()
			}
		})

		return storeModule
	}
}
