import { resources } from "api/resources"
import { authenticationApi, openApi as api } from "api/index"
import { AccountActivation } from "api/models/account"

class UserClient {
	private readonly resources = resources.user

	async isDuplicateUsername(username: string): Promise<boolean> {
		const response =  await authenticationApi.post(this.resources.usernameUnique, { username }).response
		return response.json()
	}

	async sendVerificationMail(email: string): Promise<void> {
		await api.post(this.resources.verificationMail, { email })
	}

	async checkTokenValidity(token: string): Promise<boolean> {
		const response = await api.post(this.resources.checkTokenValidity, { token }).response
		return await response.json()
	}

	async activateUser(user: AccountActivation): Promise<Response> {
		return await api.post(this.resources.activate, user).response
	}

	async forgotPassword(email: string): Promise<void> {
		await api.post(this.resources.forgotPassword, { email })
	}

	async resetPassword(token: string, password: string): Promise<void> {
		await api.post(this.resources.resetPassword, { token, password })
	}

	async forgotUsername(email: string): Promise<Response> {
		return await api.post(this.resources.forgotUsername, { email }).response
	}

	async resetUsername(token: string, username: string): Promise<Response> {
		return await api.post(this.resources.resetUsername, { token, username }).response
	}

	async changePassword(username: string): Promise<Response> {
		return await api.post(this.resources.changePassword, { username }).response
	}
}

export default new UserClient()
