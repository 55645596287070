import { KeyValuePairs } from "../types/request"

import { isBoolean, isNumber, isDate, isString, isFunction } from "lodash-es"

export const isPromise = <T = unknown>(value: any): value is Promise<T> => value && value.toString() === "[object Promise]"
export const isFile = (value: any): value is File => value && value.toString() === "[object File]"
export const isBlob = (value: any): value is Blob => value && value.toString() === "[object Blob]"
// URLSearchParams returns the url encoded string of its contents, not `[object URLSearchParams]`.
export const isURLSearchParams = (value: any): value is URLSearchParams => value instanceof URLSearchParams
export const isFormData = (value: any): value is FormData => value && value.toString() === "[object FormData]"
export const isHeaders = (value: any): value is Headers => value && value.toString() === "[object Headers]"
export const isKeyValuePairs = (value: any): value is KeyValuePairs =>
	value && isFunction(value.entries) && isFunction(value.keys) && isFunction(value.values) && isFunction(value.append)

export const isScalar = (value: any): value is string | number | Date | boolean =>
	isString(value) || isNumber(value) || isDate(value) || isBoolean(value)
