import { Module, ActionContext } from "vuex"
import { RootState } from "store/index"
import { PremiumState, Transaction } from "./types"
import premiumClient from "api/clients/premium-client"

const premiumState: PremiumState = {
	balance: undefined,
	transactions: []
}

type Context = ActionContext<PremiumState, RootState>

enum Mutations {
	HYDRATE = "HYDRATE"
}

const premiumModule: Module<PremiumState, RootState> = {
	actions: {
		async getPremium(context: Context): Promise<void> {
			const premium = await premiumClient.getPremium()
			context.commit(Mutations.HYDRATE, premium)
		}
	},
	getters: {
		balance(state: PremiumState): number | undefined {
			return state.balance
		},
		transactions(state: PremiumState): Array<Transaction> {
			return state.transactions || []
		}
	},
	mutations: {
		[Mutations.HYDRATE](state: PremiumState, data: PremiumState): void {
			Object.assign(state, data)
		}
	},
	namespaced: true,
	state: premiumState
}

export default premiumModule
