import Vue from "vue"

import { isUndefined, isString } from "lodash-es"

export type Callback<T = any> = (...args: Array<T>) => void

// Relevant methods of the Vue interface.
export interface Emitter {
	$on(event: string | Array<string>, callback: Callback): this
	$once(event: string | Array<string>, callback: Callback): this
	$off(event?: string | Array<string>, callback?: Callback): this
	$emit(event: string, ...args: Array<any>): this
}

const empty = new Set<Callback>()

/**
 * Provides a means to communicate between components using events and callbacks.
 *
 * Events are registered and unregistered in namespaces. This facilitates easy unregistering of events
 * by the components themselves, without interfering with other components.
 *
 * A namespace can be any non-primitive object (so no strings). Usually, it should be an object that is
 * globally unique and is not easily available outside of the component that registers the event.
 */
export class EventBus {
	// Store events and callbacks in a map per namespace. Weakly reference the namespace for garbage collection.
	private readonly namespaces = new WeakMap<object, Map<string, Set<Callback>>>()

	constructor(private readonly emitter: Emitter = new Vue()) { }

	/**
	 * Registers the callback for the event or events within the namespace.
	 */
	on<T>(event: string | Array<string>, callback: Callback<T>, namespace: object = {}): void {
		this.emitter.$on(event, callback)
		this.register(namespace, event, callback)
	}

	/**
	 * Registers the callback for the event or events within the namespace, and automatically
	 * unregisters the callback if the event occurs once.
	 */
	once<T>(event: string | Array<string>, callback: Callback<T>, namespace: object = {}): void {
		this.emitter.$once(event, callback)
		this.register(namespace, event, callback)
		// Register another callback that unregisters the original callback. This will ensure that the callback
		// is unregistered in all cases: 1) the event occurs or 2) when `off` is called using the original
		// callback.
		// This new callback might remain in memory indefinitely, but it will hold no references that prevent
		// components from being garbage collected. It will be unregistered if `off` is called for the namespace.
		const unregister = (): void => {
			this.unregister(namespace, event, callback)
		}

		this.emitter.$once(event, unregister)
		this.register(namespace, event, unregister)
	}

	/**
	 * Unregisters events from the namespace.
	 *
	 * - If no event and callback are passed in, all callbacks in the namespace are unregistered.
	 * - If an event is given, but no callback, all callbacks for the given event in the namespace are unregistered.
	 * - If a callback is given, it is unregistered from the event in the namespace.
	 */
	off(namespace: object, event?: string | Array<string>, callback?: Callback): void {
		this.unregister(namespace, event, callback)
	}

	/**
	 * Invokes the callbacks registered for the event.
	 */
	emit<T>(event: string, ...args: Array<T>): void {
		this.emitter.$emit(event, ...args)
	}

	/**
	 * Returns a `Promise` that is fullfilled when the event occurs within the given timeout.
	 * If the timeout elapses, the promise is rejected.
	 */
	when<T>(event: string | Array<string>, timeout: number = Infinity): Promise<T> {
		return new Promise(
			(resolve, reject) => {
				const namespace = {}
				const timer: any = timeout < Infinity ?
					setTimeout(() => {
						this.off(namespace)
						reject(new Error("Timeout"))
					}, timeout) :
					undefined
				this.once<T>(event, payload => {
					clearTimeout(timer)
					resolve(payload)
				}, namespace)
			}
		)
	}

	private register(namespace: object, event: string | Array<string>, callback: Callback): void {
		const events = isString(event) ? [event] : event
		for (const e of events) {
			this.add(namespace, e, callback)
		}
	}

	private unregister(namespace: object, event?: string | Array<string>, callback?: Callback): void {
		const events = isUndefined(event) ?
			this.events(namespace) :
			isString(event) ? [event] : event

		for (const e of events) {
			const callbacks = isUndefined(callback) ? this.get(namespace, e) : [callback]

			for (const c of callbacks) {
				this.emitter.$off(e, c)
				this.delete(namespace, e, c)
			}
		}
	}

	// Private accessors to the namespaces data structure:
	private get(namespace: object, event: string): Iterable<Callback> {
		const map = this.namespaces.get(namespace)
		if (!map) {
			return empty
		}

		return [...(map.get(event) || empty)]
	}

	private add(namespace: object, event: string, callback: Callback): void {
		const map = this.namespaces.get(namespace) || new Map<string, Set<Callback>>()
		const set = map.get(event) || new Set<Callback>()
		set.add(callback)
		map.set(event, set)
		this.namespaces.set(namespace, map)
	}

	private delete(namespace: object, event: string, callback: Callback): void {
		const map = this.namespaces.get(namespace)
		if (map) {
			const set = map.get(event) || empty
			set.delete(callback)
		}
	}

	private events(namespace: object): Iterable<string> {
		const map = this.namespaces.get(namespace)
		return map ? map.keys() : []
	}

}

export default new EventBus()
