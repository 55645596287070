
import { GetterTree } from "vuex"
import { RootState } from "store/index"
import { Company, Agreement, Intermediary, Contact } from "./types"

const getterTree: GetterTree<Company, RootState> = {
	company(state: Company): Company {
		return state
	},
	// Note: A company can have multiple agreements. For now we assume that a company only has one,
	// so take the first one from the array and present it.
	agreement(state: Company): Agreement | undefined {
		return state.agreements[0]
	},
	intermediary(_state: Company, getters: any): Intermediary | undefined {
		const agreement: Agreement | undefined = getters.agreement

		return agreement?.intermediary
	},
	canViewParticipants(_state: Company, getters: any): boolean {
		const intermediary: Intermediary | undefined = getters.intermediary

		return intermediary?.permission?.viewParticipants || false
	},
	canAdministrationForEmployer(_state: Company, getters: any): boolean {
		const intermediary: Intermediary | undefined = getters.intermediary

		return intermediary?.permission?.administrationForEmployer || false
	},
	contact(state: Company): Contact | undefined {
		return state.contact
	}
}

export default getterTree
