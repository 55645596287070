import { Function0 } from "../types/function"

/**
 * Parent interface for `URLSearchParams`, `FormData` and `Headers`.
 */
export interface KeyValuePairs<T = unknown> {
	[Symbol.iterator](): IterableIterator<[string, T]>
	/**
	 * Returns an array of key, value pairs for every entry in the list.
	 */
	entries(): IterableIterator<[string, T]>
	/**
	 * Returns a list of keys in the list.
	 */
	keys(): IterableIterator<string>
	/**
	 * Returns a list of values in the list.
	 */
	values(): IterableIterator<T>

	append(name: string, value: T): void
}

export interface PlainObject<T = unknown> {
	[key: string]: T
}

export type Parameters<T = unknown> = KeyValuePairs | object | Array<T>

export type Submit = (data?: KeyValuePairs) => Promise<Response>

export type ResponseHandler<T> = (response: Response) => Promise<T>

export type ErrorHandler<T> = (error: Error) => Promise<T>

export enum RequestMethod {
	GET = "GET",
	POST = "POST",
	PUT = "PUT",
	PATCH = "PATCH",
	DELETE = "DELETE",
	HEAD = "HEAD"
}

export enum RequestHeader {
	CONTENT_TYPE = "Content-Type",
	AUTHORIZATION = "Authorization"
}

export enum ContentType {
	APPLICATION_JSON = "application/json",
	APPLICATION_FORM_URLENCODED = "application/x-www-form-urlencoded"
}

export interface Request {
	abort: Function0<void>
	response: Promise<Response>
}

/**
 * The options that `request` accepts, can only come from a subset of the options allowed in `RequestInit`.
 */
export type RequestOptions = Omit<RequestInit, "method" | "body" | "header"> & { headers?: Headers | PlainObject<string> }
