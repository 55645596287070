import { Config } from "api/settings"

declare var dataLayer: any

// Load google tag manager if the id is provided.
export function loadGoogleTagManager(config: Config): void {
	const id = config.googleTagManager?.id
	if (!id) {
		return
	}

	const tagManagerScript = document.createElement("script")
	tagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${ id }`
	tagManagerScript.async = true
	document.body.appendChild(tagManagerScript)
	tagManagerScript.onload = () => {
		dataLayer = (window as any).dataLayer || []

		function gtag(..._args: any): void { dataLayer.push(arguments) }

		gtag("js", new Date())
		gtag("config", id)
	}
}
