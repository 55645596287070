
	import { Component, Prop, Vue } from "vue-property-decorator"
	import { CreateElement, VNode } from "vue"

	@Component
	export default class Container<T = any> extends Vue {
		@Prop({ type: String, required: false, default: "div" }) tag!: string
		@Prop({ type: String, required: false, default: "" }) childTag!: string
		@Prop({ type: String, required: false, default: "" }) childClass!: string
		@Prop({ type: String, required: false, default: "" }) forceChildType!: string
		@Prop({ type: Array, required: false, default: () => [] }) items!: ReadonlyArray<T>

		render(createElement: CreateElement): VNode {
			const children: Array<VNode> = this.items.length
				? this.items.flatMap(item => this.$scopedSlots.default!(item)!)
				: (this.$slots.default || []).filter(vNode => vNode.tag)

			if (process.env.NODE_ENV !== "production" && !process.env.SERVER && this.forceChildType) {
				for (const child of children) {
					if (typeof child === "object" && child.tag && !child.tag.endsWith(this.forceChildType)) {
						throw Error(`Child [${child.tag}] is not allowed. expected type: [${this.forceChildType}]`)
					}
				}
			}

			this.$emit("children", children)

			return createElement(
				this.tag,
				children.map(child => this.childTag
					? createElement(this.childTag, {class: this.childClass}, [child])
					: child
				)
			)
		}

	}
