
	import Vue from "vue"
	import { Prop } from "vue-property-decorator"

	export default class PInput extends Vue {
		@Prop({ type: String, default: " " }) placeholder!: string
		@Prop({ type: Boolean, default: false }) isLoading!: boolean
		@Prop({ type: Boolean, default: false }) required!: boolean
		@Prop({ type: Boolean, default: false }) readonly!: boolean
		@Prop({ type: Boolean, default: false }) inline!: boolean
		@Prop({ type: Boolean, default: false }) floatLabel!: boolean
		@Prop({ type: Boolean, default: false }) noLabel!: boolean
		@Prop({ type: Boolean, default: false }) disabled!: boolean
		@Prop({ type: Boolean, default: false }) append!: boolean
		@Prop({ type: Boolean, default: false }) prepend!: boolean
		@Prop({ type: Boolean, default: false }) noIndicatorBorder!: boolean
		@Prop({ type: Boolean, default: false }) hideLabelOnInput!: boolean
		@Prop({ type: Boolean, default: false }) isCorrect!: boolean
		@Prop({ type: Boolean, default: false }) invalid!: boolean
	}
