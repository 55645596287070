
	import Vue from "vue"
	import Component from "vue-class-component"

	import PageHeader from "custom/PageHeader.vue"
	import PageFooter from "custom/PageFooter.vue"

	import PToaster from "@portal-components/ui/PToaster.vue"
	import { Prop } from "vue-property-decorator"

	@Component({
		components: {
			PageHeader,
			PageFooter,
			PToaster
		}
	})
	export default class Page extends Vue {
		@Prop({required: false, default: false}) isLoginPage!: boolean
		year = new Date().getFullYear()
	}
