
	import Vue from "vue"
	import Component from "vue-class-component"
	import Page from "custom/Page.vue"
	import ContentHeader from "custom/ContentHeader.vue"
	import PNavigationTabs from "@portal-components/ui/navigation/PNavigationTabs.vue"
	import PNavigationTabItem from "@portal-components/ui/navigation/PNavigationTabItem.vue"
	import PageSlide from "@components/container/PageSlide.vue"

	import Secured from "utils/security/Secured"
	import { session } from "api/index"

	import EmployeeOverview from "custom/employees/EmployeeOverview.vue"
	import EmployeeChanges from "custom/employees/EmployeeChanges.vue"
	import CustomModal from "custom/modal/CustomModal.vue"
	import { Agreement, DataDeliveryChannel } from "store/company/types"
	import { companyModule, participantModule } from "store/modules"
	import { Ref, Watch } from "vue-property-decorator"
	import { StoreAction } from "@lib/types/vuex"
	import eventBus from "lib/vue/eventBus"

	export const employeesEventBus = new Vue()
	export const changeTabEvent = "changeTab"

	export enum EmployeesTabs {
		OVERVIEW = "employee-overview",
		CHANGES = "employee-changes"
	}

	@Secured(session)
	@Component({
		components: {
			Page,
			ContentHeader,
			PageSlide,
			EmployeeOverview,
			EmployeeChanges,
			PNavigationTabs,
			PNavigationTabItem,
			CustomModal
		}
	})
	export default class Employees extends Vue {
		@companyModule.Getter agreement!: Agreement
		@participantModule.Action getParticipantsByAgreementId!: StoreAction<string>

		@Ref() readonly employeeTabs!: PNavigationTabs

		readonly baseTranslationKey = "employees"
		readonly DataDeliveryChannel = DataDeliveryChannel
		readonly EmployeesTabs = EmployeesTabs
		readonly baseEmployeeTranslationKey = "employees.changes"

		showModalDialog = false
		showDialogContent = ''

		get dataDeliveryChannel(): DataDeliveryChannel | undefined {
			return this.agreement?.dataDeliveryChannel
		}

    	mounted(): void {
			employeesEventBus.$on(changeTabEvent, (tab: EmployeesTabs) => {
				this.employeeTabs.setActiveTab(tab)
				window.scroll({ top: 0 })
			})
			eventBus.on("showWarningModal",(data: string) => {
				this.showWarningModal(data)
			})
		}

		@Watch("agreement", { immediate: true })
		async agreementChanged(agreement: Agreement): Promise<void> {
			if (agreement === undefined) {
				return
			}

			await this.getParticipantsByAgreementId(agreement.externalIdentifier)
			const urlParams = new URLSearchParams(window.location.search)
			this.setActiveTab(urlParams.get("tab"))
		}

		showWarningModal(data : string) {
			this.showDialogContent = data;
			this.showModalDialog = true
		}

		private setActiveTab(tabKey: string | null): void {
			if (tabKey === null) {
				return
			}

			this.employeeTabs?.setActiveTab(tabKey)
		}
	}
