import Vue, { VNode } from "vue"

export default Vue.extend({
	functional: true,
	render(createElement, context): VNode | Array<VNode> {
		const props = context.props
		const children: Array<VNode> = !!props.items.length
			? props.items.flatMap(item => context.scopedSlots.default!(item)!)
			: (context.slots().default).filter((vNode: VNode) => vNode.tag)
		// Component tags are mangled in production, so we cannot do checks in production
		if (process.env.NODE_ENV !== "production" && !process.env.SERVER) {
			for (const child of children) {
				if (!!props.requiredTag && child.tag && !child.tag.endsWith(props.requiredTag)) {
					throw Error(
						`Child <${child.tag}> is not allowed. expected type: <${context.props.requiredTag}>`
					)
				}
			}
		}
		const objectsToRender = children.map(child => !!props.childTag
			? createElement(props.childTag, { class: props.childClass }, [child])
			: child
		)
		if (props.baseTag) {
			return createElement(props.baseTag, objectsToRender)
		}
		return objectsToRender
	},
	props: {
		/**
		 * The name of the wrapper element
		 * @example ```html
		 * <div>
		 * 	<child-tag></child-tag>
		 * 	<child-tag></child-tag>
		 * </div>
		 * ```
		 *
		 * When empty no wrapper element will be created
		 */
		baseTag: { type: String, default: "" },
		/**
		 * The tag that should be used as the element tag for the
		 * child components in the view
		 */
		childTag: { type: String, default: "" },
		/**
		 * An space separated string with classes that should be added
		 * to the child element
		 * @example `'btn btn--active'`
		 */
		childClass: { type: String, default: "" },
		/**
		 * The element tag that should be enforced
		 * If a child that does not match this tag is placed within this container
		 * The container will throw an error
		 */
		requiredTag: { type: String, default: "" },
		/**
		 * Scope values for usage in scoped slots
		 * @example ```html
		 * <template slot-scope="item">
		 * 	<element v-bind="item"></element>
		 * </template>
		 * ```
		 */
		items: { type: Array, default: () => [] }
	}
})
