import actions from "./actions"
import getters from "./getters"
import { UserState } from "./types"
import { Module } from "vuex"
import { RootState } from "store/index"

export enum USER_MUTATIONS {
	HYDRATE = "HYDRATE",
	SET_LOADING = "SET_LOADING",
	SET_ERROR = "SET_ERROR",
	SET_ACTIVATION_STATUS = "SET_ACTIVATION_STATUS"
}

const userModule: Module<UserState, RootState> = {
	state : {
		user: {
			id: undefined,
			email: undefined,
			fullName: undefined
		},
		loading: false,
		hasError: false,
		isActivating: false
	},
	actions,
	getters,
	mutations: {
		[USER_MUTATIONS.HYDRATE](state, data: UserState): void {
			Object.assign(state.user, data)
		},
		[USER_MUTATIONS.SET_LOADING](state, loading: boolean): void {
			state.loading = loading
		},
		[USER_MUTATIONS.SET_ERROR](state, hasError: boolean): void {
			state.hasError = hasError
		},
		[USER_MUTATIONS.SET_ACTIVATION_STATUS](state, activating: boolean): void {
			state.isActivating = activating
		}
	},
	namespaced: true
}

export default userModule
