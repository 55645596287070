import type { OptionModel } from "@portal-components/ui/input/option-model"
import type { POutroItem } from "@portal-components/customer-journey/POutroItems.vue"

import { CustomerJourneyStatus } from "api/models/customer-journey"

export enum CustomerJourneyNamespace {
	CUSTOMER_JOURNEY = "CUSTOMER_JOURNEY",
	CONTACT_DETAILS = "CONTACT_DETAILS",
	PENSION_PROGNOSIS = "PENSION_PROGNOSIS",
	PENSION123 = "PENSION123",
	SIGN_UP_PARTNER = "SIGN_UP_PARTNER",
	OUTRO = "OUTRO"
}

export interface CustomerJourneyControls<T = any> {
	progression: number
	isPending: boolean
	isStarted: boolean
	isCompleted: boolean
	status: CustomerJourneyStatus
	steps: Array<keyof T>
	shouldDisplayStep: (stepId: string) => boolean
	resolveStep: (stepId: string, event: MouseEvent) => void
	resolveIntro: (event: MouseEvent) => void
	getStepData: <K extends keyof T>(stepId: K) => T[K]
	resetState: () => Promise<void>
}

export interface ContactDetailsConfig {
	email: "REQUIRED" | "OPTIONAL" | false
	phone: "REQUIRED" | "OPTIONAL" | false
	communication: "FORCED" | "OPTIONAL" | false
	emailValue: string
	phoneValue: string
	communicationValue: boolean
}

export interface Pension123Config {
	questions: {
		[key: string]: Pension123QuestionConfig
	}
	data: any
}

export interface Pension123QuestionConfig {
	sections: {
		[key: string]: Pension123SectionConfig
	}
}

export interface Pension123SectionConfig {
	icon: string
	withCross?: boolean
	hidden?: boolean
}

export interface PensionPrognosisItem {
	title: string
	value: number
	unit: string
}

export interface PensionPrognosisConfig {
	items: Array<PensionPrognosisItem>
}

export interface SignUpPartnerConfig {
	genderOptions: Array<OptionModel>
}

export interface OutroConfig {
	outroItems: Array<POutroItem>
}
