
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class PAccordionItem extends Vue {
		isAccordionOpen = false

		toggleAccordion(): void {
			this.isAccordionOpen = !this.isAccordionOpen
		}
	}
