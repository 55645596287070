import Vue from "vue"

import { ObjectRules, ObjectValidator, FieldRules, Datatype } from "../types/validation"
import entries from "../misc/entries"
import { ComponentState, Mode, Cardinality, Identifiable, DataSource, ObjectId, Condition, PartialState } from "../types/model"

import validator from "../validation/validator"

import StoreModule from "../model/store/StoreModule"
import Model from "../model/Model"
import ModelProxy from "../model/ModelProxy"
import { Suspended, expect } from "../model/suspended"

import { isPlainObject, isUndefined, merge } from "lodash-es"

/**
 * A model with update and delete capabilities. It is responsible for validation of updates before updating the frontend store,
 * or saving state in the data source.
 *
 * The type parameters T, C and R are described in the `Model` class. Type parameter `K` defines the
 * context object that is used with validation.
 */
export default class MutableModel<T extends Identifiable, C extends Cardinality, R, K = undefined> extends Model<T, C, R> {
	protected state: PartialState<T>
	private readonly validateObject: ObjectValidator<T, K>
	private storing = false

	constructor(
		cardinality: C,
		storeModule: StoreModule<T, C, R>,
		private readonly rules: ObjectRules<T, K>,
		private readonly context: Suspended<K>,
		dataSource?: Suspended<DataSource<T, C>>
	) {
		super(cardinality, storeModule, dataSource)
		this.state = this.defaultState()
		this.validateObject = validator(rules)
	}

	protected defaultState(): PartialState<T> {
		return this.createPartialState(this.rules)
	}

	/**
	 * Creates a `PartialState` object by traversing the object rules recursively. This leads to a data structure that is
	 * the same as the corresponding type (`N`), with the caveat that all scalar fields are allowed to be undefined.
	 */
	private createPartialState<N>(objectRules: ObjectRules<N, K>): PartialState<N> {
		// TODO: use better types. Maybe use Object.fromEntries.
		const state: any = {}
		for (const [field, fieldRules] of entries(objectRules)) {
			if (fieldRules) {
				const rules = fieldRules as FieldRules<keyof N, N, K>
				switch (rules.type) {
					case Datatype.OBJECT:
						state[field] = this.createPartialState(rules.fields as ObjectRules<unknown, K>)
						break
					case Datatype.ARRAY:
						state[field] = []
						break
					case Datatype.BOOLEAN:
						// Give booleans a default value of false, so that checkboxes and the like don't give 'required' errors.
						state[field] = false
						break
					default:
						state[field] = undefined
						break
				}
			} else {
				// Hidden fields:
				state[field] = undefined
			}
		}
		return state
	}

	async attach(model: ModelProxy<T, C, R>): Promise<void> {
		await super.attach(model)

		model.subscribe(async () => {
			if (model.mode !== Mode.READONLY && !this.storing && !this.hydrating) {
				await this.update(model)
			}
		})
	}

	async validate(componentState: ComponentState<T>): Promise<void> {
		const context = await expect(this.context)
		componentState.errors = await this.validateObject(componentState.state, context)
	}

	private isValid(componentState: ComponentState<T>): boolean {
		return !componentState.errors
	}

	private async update(componentState: ComponentState<T>): Promise<void> {
		componentState.condition = Condition.TOUCHED
		await this.validate(componentState)

		if (componentState.mode === Mode.IMMEDIATE) {
			componentState.condition = await this.saveComponentState(componentState) ? Condition.CLEAN : Condition.DIRTY
		}
	}

	async save(componentState: ComponentState<T>): Promise<boolean> {
		if (componentState.mode !== Mode.READONLY) {
			await this.validate(componentState)
			componentState.condition = await this.saveComponentState(componentState) ? Condition.CLEAN : Condition.DIRTY
		}

		return componentState.condition === Condition.CLEAN
	}

	private async saveComponentState(componentState: ComponentState<T>): Promise<boolean> {
		if (!this.isValid(componentState)) {
			return false
		}

		// Store the component state right away, so that the store is already updated in case the data source is interrupted.
		await this.storeState(componentState.state as T)

		// The data source returns the state from the server. Besides the id, other fields may also have been set, so store the returned state.
		const dataSource = await expect(this.dataSource)

		if (dataSource) {
			const knownState = this.knownState(componentState)
			const state = await dataSource.save(knownState)

			if (!isUndefined(state)) {
				await this.storeState(state)
			}
		}

		return true
	}

	private async storeState(state: T): Promise<void> {
		this.storing = true
		const currentId = this.state.id as ObjectId
		// The id must be updated separately (when changed), because the store uses it to match.
		// Also make sure that the data structure is complete. All nested objects must exist recursively (see PartialState).
		this.storeModule.save({
			...merge(this.defaultState(), state),
			id: currentId // Leave id untouched.
		})
		this.storeModule.update("id", state.id, currentId)
		this.storeModule.focus(state.id)
		// Updating the store is (apparently) asynchronous. Under the assumption that Vue
		// postpones the update until the next tick, wait one tick.
		await Vue.nextTick()
		this.storing = false
	}

	/**
	 * Returns the component state with only known and non-empty fields and without hidden fields.
	 * If the store module has submodules, then the state of those submodules would exist in the component state (in case of singletons).
	 */
	private knownState(componentState: ComponentState<T>): T {
		const state: Partial<T> = {}
		for (const [field, rules] of entries(this.rules)) {
			// Leave out hidden fields. These fields are not validated and therefore not under control of the frontend.
			// TODO: Hidden fields could be located in nested objects, so this should be done recursively.
			if (rules || field === "id") {
				const value = componentState.state[field] as T[keyof T]
				// Leave out objects that contain only undefined properties. These objects are supposed to be undefined but
				// have these properties assigned because of `PartialState`. TODO: this should be done recursively.
				if (!isPlainObject(value) || !Object.values(value).every(isUndefined)) {
					state[field] = value
				}
			}
		}

		return state as T
	}

	async delete(componentState: ComponentState<T>, id: ObjectId): Promise<void> {
		if (componentState.mode !== Mode.READONLY) {
			const dataSource = await expect(this.dataSource)

			if (await dataSource?.delete(id)) {
				this.storeModule.delete(id)
			}
		}
	}

}
