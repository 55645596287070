import { ObjectRules, Datatype } from "@lib/types/validation"
import validator from "@lib/validation/validator"
import { EmployeeChangeModel } from "./types"

export const rules: ObjectRules<EmployeeChangeModel> = {
	parttimePercentage: {
		required: false,
		type: Datatype.NUMBER,
		minimum: 1,
		maximum: 100
	},
	retirementDate: {
		required: false,
		type: Datatype.DATE
	},
	salary: {
		required: false,
		type: Datatype.NUMBER,
		minimum: 1
	},
	salaryAndOrParttimePercentageChangeDate: {
		required: false,
		type: Datatype.DATE
	}
}

export default validator(rules)
