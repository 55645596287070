import { Module } from "vuex"
import { Documents } from "./types"
import { RootState } from ".."

import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const documentsState: Documents = {
	documents: [],
	loaded: false
}

const documentsModule: Module<Documents, RootState> = {
	actions,
	getters,
	mutations,
	namespaced: true,
	state: documentsState
}

export default documentsModule
