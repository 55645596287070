import {ConversionMap} from "@lib/types/import"
import {Message} from "./types"
import {alphanumeric, datetime, bool} from "@lib/import/convert"
import {many} from "@lib/import/extract"

const required = true

const message: ConversionMap<Message> = {
	id: ["messageId", alphanumeric, required],
	title: ["title", alphanumeric, required],
	body: ["body", alphanumeric, required],
	date: ["timestamp", datetime, required],
	read: ["read", bool, required]
}

export const convert = many(message)
