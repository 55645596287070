
  import Vue from "vue"
	import LoadingComponent from "custom/LoadingComponent"
	import Component from "vue-class-component"
	import CustomerJourneyStep from "custom/customer-journey/CustomerJourneyStep.vue"

	import Wizard from "custom/wizard/Wizard.vue"
	import WizardSlide from "custom/wizard/WizardSlide.vue"
	import PButton from "@portal-components/ui/input/PButton.vue"
	import PFileUpload from "@portal-components/ui/input/PFileUpload.vue"

	import SignUpInstructions from "custom/customer-journey/sign-up-employees/SignUpInstructions.vue"
	import DownloadTemplate from "custom/customer-journey/sign-up-employees/DownloadTemplate.vue"

	import Loader from "custom/Loader.vue"
	import { ErrorType, ExcelUploadResponse } from "api/clients/sign-up-employees-client"
	import { Emit, Prop, Watch } from "vue-property-decorator"
	import { serializeBase64File } from "store/conversion"
	import { FileType } from "store/types"
	import { SignUpEmployeesVariant } from "pages/SignUpEmployees.vue"
	import signUpEmployeesClient from "api/clients/sign-up-employees-client"

	@Component({
		components: {
			CustomerJourneyStep,
			Wizard,
			WizardSlide,
			SignUpInstructions,
			PButton,
			PFileUpload,
			DownloadTemplate,
			Loader
		}
	})
	export default class UploadSheet extends LoadingComponent {
		@Prop({ type: String, required: true }) variant!: SignUpEmployeesVariant

		readonly baseTranslationKey = "signUpEmployees.provideEmployeeData.uploadParticipantData"

		employeeData: { file?: File } = {
			file: undefined
		}

		errors: Array<string> = []
		warnings: Array<string> = []
		showEmptySheetError = false
		fileUploaded = false

		get warningsAndErrors(): Array<string> {
			return this.errors.concat(this.warnings).sort((a, b) => {
				return this.extractRowNumber(a) - this.extractRowNumber(b)
			})
		}

		@Watch("employeeData.file")
		@Emit("fileSelected")
		async employeeDataFileChanged(): Promise<boolean> {
			let fileSelected = false
			if (this.variant === SignUpEmployeesVariant.YEARLY_SALARY_CHANGE) {
				const returnMessage = await this.onChange()
				if (returnMessage === true) {
					fileSelected = !!this.employeeData.file
				}
			} else {
				fileSelected = !!this.employeeData.file
			}
			if (fileSelected) {
				this.errors = []
				this.fileUploaded = false
				this.showEmptySheetError = false
			}

			return fileSelected
		}
		
		async onChange(): Promise<boolean> {
			let showEmptyMessage = false
			const file = this.employeeData.file
			if (!file) {
				return false
			}
			const fileToUpload: FileType = {
				content: await serializeBase64File(file) || "",
				name: file.name
			}
			
			const response = await this.manageLoadingState(signUpEmployeesClient.validateParticipantsSheet(fileToUpload))
			if (response !== null) {
				this.handleErrorResponse(response)

				showEmptyMessage = this.errors.length === 0
			} else {
				this.errors = []
				this.warnings = []
				showEmptyMessage = true
			}
			return showEmptyMessage
		}
		
		async upload(datechange: Date): Promise<void> {
			const file = this.employeeData.file
			if (!file) {
				return
			}

			const fileToUpload: FileType = {
				content: await serializeBase64File(file) || "",
				name: file.name
			}

			try {
				const response = this.variant === SignUpEmployeesVariant.YEARLY_SALARY_CHANGE
					? await this.manageLoadingState(signUpEmployeesClient.uploadYearlySalarySheet(fileToUpload, datechange))
					: await this.manageLoadingState(signUpEmployeesClient.uploadParticipantsSheet(fileToUpload))

				if (response !== null) {
					this.handleErrorResponse(response)
				}

				if (this.errors.length === 0) {
					this.$emit("uploadFinished")
					this.fileUploaded = true
				}
			} catch (error) {
				if (error.message.includes("Empty sheet")) {
					this.showEmptySheetError = true
				}
			} finally {
				this.employeeData.file = undefined
			}
		}

		formatError(error: string): string {
		const locale = Vue.filter("locale")
		const translatedEmpty = locale(`${ this.baseTranslationKey }.uploadError.error.empty`)
		const translatedColumn = locale(`${ this.baseTranslationKey }.uploadError.error.zipCode`)
		const translatedPtp = locale(`${ this.baseTranslationKey }.uploadError.error.andHousenumber`)
		const general = locale(`${ this.baseTranslationKey }.uploadError.error.generalMessage`)
		const translatedRow = locale(`${ this.baseTranslationKey }.uploadError.error.row`)
		const translatedPs = locale(`${ this.baseTranslationKey }.uploadError.error.newPensionableSalary`)
		const generic = locale(`${ this.baseTranslationKey }.uploadError.error.generic`)
		const policy = locale(`${ this.baseTranslationKey }.uploadError.error.policy`)

		const emptyRegex = /The sheet is empty/
		const rowRegex = /Row/
		const columnRegex = /column/
		const ptpRegex = /newPartTimePercentage/
		const psRegex = /newPensionableSalary/
		const errorsGeneric = /contains an error/
		const policyRegex = /Can't find matching policy for participationNumber/
		const generalRegex = "General exception: System exception : Errors parsing employment file, com.schantz.foundation.exception.FailFast.isFalse(FailFast.java:20);com.schantz.nllife.logic.standardintegrations.employment.batch.EmployeeFileParserLogic.parseEmploymentFile(EmployeeFileParserLogic.java:51)"

		return error
			.replace(emptyRegex, translatedEmpty)
			.replace(rowRegex, translatedRow)
			.replace(columnRegex, translatedColumn)
			.replace(ptpRegex, translatedPtp)
			.replace(psRegex, translatedPs)
			.replace(errorsGeneric, generic)
			.replace(policyRegex, policy)
			.replace(generalRegex, general)
		}

		private handleErrorResponse(response: ExcelUploadResponse): void {
			switch (response.title) {
				case ErrorType.SEMANTIC: {
					this.errors = response.errors || []
					this.warnings = response.warnings || []
					break
				}
				case ErrorType.SYNTACTIC: {
					this.errors = response.errors || []
					this.warnings = response.warnings || []
					break
				}
			}
		}

		private extractRowNumber(error: string) {
			try {
				const [row, ] = error.split(",")
				const [, rowNumber] = row.split(" ")

				return parseInt(rowNumber)
			} catch (eror) {
				return Infinity
			}
		}
	}
