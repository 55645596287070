import {DirectiveOptions, DirectiveFunction} from "vue"

// This directive was taken from the plugin vue-smooth-scroll (https://github.com/ocordeiro/vue-smooth-scroll/blob/master/index.js)
// It has been modified for our purposes

export interface SmoothScrollOptions {
	target?: string
	offset?: number
	duration?: number
	delay?: number
}

const easeInOutCubic = (t: number): number => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1

const frameRequest = (fn: FrameRequestCallback): void => {
	setTimeout(fn, 15)
}

const hrefHash = (el: HTMLElement): string => {
	const href = el.getAttribute("href") || ""
	if (href.startsWith("#")) {
		return href.substring(1)
	}
	return ""
}

// Keep references to click handlers per element that has the smooth-scroll directive.
const handlers: {[name: string]: (event: MouseEvent) => void} = {}
let key = 0 // The key to identify an element. This will be stored in the element's dataset.

const stop = (el: HTMLElement): void => {
	const handler = handlers[el.dataset.smoothScroll!]
	if (handler) {
		el.removeEventListener("click", handler)
	}
}

const listen: DirectiveFunction = (el, binding) => {
	const bindingValue: SmoothScrollOptions = binding.value
	const hash = bindingValue.target || hrefHash(el)

	const prevHash = binding.oldValue ? binding.oldValue.target : ""

	if (hash !== prevHash) {
		stop(el)
		if (hash) {
			const handler = (event: MouseEvent): void => {
				// timeout for when the html element is to be shown after clicking the element
				const scrollTo = document.getElementById(hash)
				scrollToElement(scrollTo, bindingValue, event)
			}
			el.addEventListener("click", handler)
			handlers[el.dataset.smoothScroll!] = handler
		}
	}
}

export function scrollToElement(scrollTo: HTMLElement | null, bindingValue: SmoothScrollOptions, event: MouseEvent) {
	setTimeout(() => {
		if (scrollTo) {
			event.preventDefault()

			// Using the history api to solve issue: back doesn't work
			// most browsers don't update :target when the history api is used:
			// THIS IS A BUG FROM THE BROWSERS.
			// if (window.history.pushState && location.hash !== `#${hash}`) {
			// 	window.history.pushState("", "", `#${hash}`)
			// }

			// Get duration from element, default to 500ms
			const duration = bindingValue && bindingValue.duration
				? bindingValue.duration
				: 500

			// Get offset from element, default to 0
			const offset = bindingValue && bindingValue.offset
				? bindingValue.offset
				: 0

			const clock = Date.now()

			// Get the top position of an element in the document
			// return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
			const end = (scrollTo.nodeName === "HTML" ? -window.pageYOffset : scrollTo.getBoundingClientRect().top + window.pageYOffset) + offset

			// we use requestAnimationFrame to be called by the browser before every repaint
			const requestAnimationFrame = window.requestAnimationFrame || frameRequest
			const step = (): void => {
				// the time elapsed from the beginning of the scroll
				const elapsed = Date.now() - clock
				// calculate the scroll position we should be in
				let position = end
				if (elapsed < duration) {
					position = window.pageYOffset + (end - window.pageYOffset) * easeInOutCubic(elapsed / duration)
					requestAnimationFrame(step)
				} else {
					location.replace(`#${scrollTo.id}`)
					// this will cause the :target to be activated.
				}
				window.scroll(0, position)
			}
			step()
		}
	}, bindingValue.delay || 1)
}

const smoothScroll: DirectiveOptions = {
	bind(el, _binding) {
		el.dataset.smoothScroll = key.toString()
		key += 1
	},
	inserted: listen,
	update: listen,
	unbind(el, _binding) {
		stop(el)
	}
}

export default smoothScroll
