export const resources = {
	company: {
		company: "/company",
		contact: "/company/contact",
		customerJourney: "/company/customer-journeys/states",
		downloadParticipantTemplate: "/company/customer-journeys/upload-participants/download-template",
		downloadYearlySalariesTemplate: (date: string) => `/company/customer-journeys/upload-yearly-salaries/generate-sheet/${ date }`,
		uploadParticipantData: "/company/customer-journeys/upload-participants/upload-template",
		validateParticipantData: "/company/customer-journeys/upload-yearly-salaries/validate-sheet",
		uploadYearlySalarySheet: "/company/customer-journeys/upload-yearly-salaries/upload-sheet",
		agreements: {
			agreements: "/company/agreements",
			participants: (id: string) => `/company/agreements/${ id }/participants`,
			authorizeAdvisor: (agreementId: string, intermediaryId: string) => `/company/agreements/${ agreementId }/intermediary/${ intermediaryId }/permission`
		}
	},
	documents: {
		getDocuments: (bucket: string) => `/dossier/plexus/${ bucket }`,
		getDocument: (id: string) => `/dossier/plexus/stream/${ id }`
	},
	user: {
		verificationMail: "/token",
		checkTokenValidity: "/token/validity",
		getCurrentUser: "/identity",
		updateUser: "/portal-users",
		activate: "/activate",
		forgotPassword: "/password/forgot",
		resetPassword: "/password/reset",
		forgotUsername: "/username/forgot",
		usernameUnique: "/exists/username",
		resetUsername: "/username/reset",
		getQrCode: "/2fa/qr-code",
		is2FaActivated: "/2fa/is-activated",
		is2faEnabled: "/2fa/enabled",
		changePassword: "/password/forgot/username",
		authenticateTotp: (code: string) => `/2fa/authenticate-totp/${ code }`,
		authenticateTotpFirstTime: (code: string) => `/2fa/authenticate-totp-first-time/${ code }`
	},
	participant: {
		endEmployment: (id: string) => `/company/customer-journeys/end-employment/${ id }`,
		updateParticipant: (agreementId: string, participantId: string) => `/company/agreements/${ agreementId }/participants/${ participantId }`,
		periodicExtraSavings: "/company/customer-journeys/extra-savings/periodic",
		oneOffExtraSavings: "/company/customer-journeys/extra-savings/one-off"
	},
	payPremium: {
		getPremium: "/company/premium"
	},
	messages: {
		getMessages: "/message",
		markAsRead: "/message/markRead"
	}
}
