import { ConversionFunction } from "../types/import"
import { isBlob, isScalar, isFile } from "../misc/guards"
import { Enum } from "../types/tuple"
import pad from "../number/pad"

import { isEmpty, toNumber, isArray, isUndefined, toString, isFinite, isString, isDate } from "lodash-es"

/**
 * Converts the value to a `Date` including time in the local time zone, if it's not a `Date` already. Supported values:
 * - The number of seconds since epoch.
 * - Any string recognized by `Date.parse`.
 * - An array of the form `[years, months, days, hours, minutes, seconds?]`.
 */
export const datetime: ConversionFunction<Date> = value => {
	if (isDate(value)) {
		return !isNaN(value.getTime()) ? value : undefined
	}

	if (isFinite(value)) {
		// This returns a date in the local timezone while we don't know the timezone of the origin.
		return new Date(value * 1000)
	}

	if (isString(value)) {
		const result = new Date(value)
		return !isNaN(result.getTime()) ? result : undefined
	}

	if (isArray(value)) {
		const [year, month, day, h, m, s] = value.map(numeric)

		if (year && month && day && ![h, m].some(isUndefined)) {
			return new Date(year, month - 1, day, h, m, s || 0)
		}
	}

	return undefined
}

/**
 * Converts the value to a `Date` without time in the local time zone, if it's not a `Date` already.
 *
 * Supported values:
 * - A string of the form `yyyy-mm-dd`.
 * - An array of the form `[years, months, days]`.
 */
export const date: ConversionFunction<Date> = value => {
	if (isDate(value)) {
		return value
	}

	const [year, month, day] = isString(value) ?
		value.split("-").map(numeric) :
		isArray(value) ?
			value.map(numeric) :
			[undefined, undefined, undefined]

	if (year && month && day) {
		return new Date(year, month - 1, day, 0, 0, 0, 0)
	}

	return undefined
}

/**
 * Converts a backend array date (`[year, month, day]`) to a string representation of the date with
 * the format `yyyy-mm-dd`.
 *
 * @param value The value to be converted to a string
 */
export const alphanumericDate: ConversionFunction<string> = (value: Array<number>) => {
	const [year, month, day]: Array<number | undefined> = isArray(value) ? value.map(numeric) : [undefined, undefined, undefined]

	if (year && month && day) {
		return `${ pad(4, year) }-${ pad(2, month) }-${ pad(2, day) }`
	}

	return undefined
}

export const alphanumeric: ConversionFunction<string> = value => isScalar(value) ? toString(value) : undefined

export const numeric: ConversionFunction<number> = value => {
	const result = isScalar(value) && !isEmpty(toString(value)) ? toNumber(value) : NaN
	return !isNaN(result) ? result : undefined
}

export const bool: ConversionFunction<boolean> = value => {
	switch (toString(value)) {
		case "true": return true
		case "false": return false
		default: return undefined
	}
}

export const file: ConversionFunction<File> = value => isFile(value) ? value : undefined
export const blob: ConversionFunction<Blob> = value => isBlob(value) ? value : undefined

export const pairOf = <T, U>(f: ConversionFunction<T>, g: ConversionFunction<U>): ConversionFunction<[T, U]> =>
	value => isArray(value) && value.length === 2 && !isUndefined(f(value[0])) && !isUndefined(g(value[1])) ? value as [T, U] : undefined

export const enumConstant = <T extends Enum>(enumObject: T): ConversionFunction<T[keyof T]> => value =>
	value in enumObject ? enumObject[value as keyof typeof enumObject] : undefined
