import { IdentificationStrategy, SamlArt, JwtToken, isJwtToken } from "lib/types/security"
import RequestStrategy from "lib/request/RequestStrategy"
import { RequestMethod, RequestHeader, ContentType } from "@lib/types/request"
import HttpStatus from "@lib/request/status"
import { ConversionFunction } from "@lib/types/import"
import { identity } from "lodash-es"
import RequestError from "lib/request/RequestError"
import request from "@lib/request/request"
import CredentialsIdentification from "@lib/security/identification/CredentialsIdentification"

const jwtIdentifier: ConversionFunction<string> = data => isJwtToken(data) ? data.username : undefined

// TODO: Move this to lib when it works
export default class ERecognitionIdentification implements IdentificationStrategy<null> {
	private readonly identification: CredentialsIdentification<SamlArt, JwtToken>

	constructor(
		private readonly samlEndpoint: string,
		private readonly gateway: RequestStrategy,
		private readonly tokenEndpoint: string,
		private readonly extractToken: ConversionFunction<any> = identity,
		private readonly extractIdentifier: ConversionFunction<string> = jwtIdentifier) {

		this.identification = new CredentialsIdentification(this.gateway, { identification: samlEndpoint })
	}

	async is2FARequired(): Promise<boolean> {
		return false
	}

	setIdentifierFromToken(token: JwtToken): void {
		this.identifier = this.extractIdentifier(token)
	}

	async identify(): Promise<any> {
		if (this.isIdentified) {
			return Promise.reject(new TypeError("Already identified"))
		}

		const samlArt = new URL(location.href).searchParams.get("SAMLart") // The parameter name is case sensitive.
		if (!samlArt) {
			throw new ReferenceError("Parameter samlArt does not exist")
		}

		const response = await this.gateway.request(RequestMethod.POST, this.samlEndpoint, { samlArt }).response

		switch (response.status) {
			case HttpStatus.OK:
				const data = await response.json()
				const token = this.extractToken(data)

				if (token) {
					const companyToken = await this.getTokenForCompany(token)
					this.identifier = this.extractIdentifier(companyToken)
					return this.extractToken(companyToken)
				}
				return Promise.reject(new TypeError("Invalid token"))
			case HttpStatus.UNAUTHORIZED:
				return Promise.reject(new RequestError(response))
			default:
				return Promise.reject(new RangeError(`Unexpected status code ${ response.status }`))
		}
	}

	get isIdentified(): boolean {
		return this.identification.isIdentified
	}
	unidentify(): Promise<boolean> {
		return this.identification.unidentify()
	}
	get identifier(): string | undefined {
		return this.identification.identifier
	}
	set identifier(identifier: string | undefined) {
		this.identification.identifier = identifier
	}

	//  Perform a GET request to the company service to retrieve a correct jwt token with company claims, etc.
	private async getTokenForCompany(token: JwtToken): Promise<JwtToken> {
		const headers = {
			[RequestHeader.CONTENT_TYPE]: ContentType.APPLICATION_FORM_URLENCODED,
			[RequestHeader.AUTHORIZATION]: `${token.token_type} ${token.access_token}`
		}

		const response = await request(RequestMethod.GET, this.tokenEndpoint, undefined, { credentials: "include", headers }).response
		return await response.json() as JwtToken
	}
}
