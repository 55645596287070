import { GetterTree, ActionTree } from "vuex"

import { ObjectRules, ObjectErrors, Scalar } from "../types/validation"
import { Suspended } from "../model/suspended"

import { isFunction } from "lodash-es"

/**
 * The mode of operation of the model:
 * - IMMEDIATE: updates to a field are immediately saved.
 * - COMPLETE: updates to fields are saved only when `model.save` is called.
 * - READONLY: updates are never saved.
 */
export const enum Mode { IMMEDIATE, COMPLETE, READONLY }

/**
 * The model cardinality type. A model contains ONE or MANY objects.
 */
export const enum Cardinality { ONE, MANY }

/**
 * The condition of the data in the model:
 * - PRISTINE: the data is new
 * - TOUCHED: the data has been edited but not saved
 * - DIRTY: the data is rejected on save
 * - CLEAN: the data has been saved
 */
export const enum Condition { PRISTINE, TOUCHED, DIRTY, CLEAN }

/**
 * Determines the type of state depending on the cardinality.
 */
export type State<T, C extends Cardinality> = C extends Cardinality.ONE ? PartialState<T> : CollectionState<T>

/**
 * The id of an object can be a string, `null` or `undefined`. These have the following meanings:
 * - string: the object exists in the data source. This is the case for root documents, or documents in arrays.
 * - null: the object exists in the data source without an id. This is the case for subdocuments.
 * - undefined: the object does not exist in the data source.
 */
export type ObjectId = string | null | undefined
// TODO: null and undefined are given semantics here that is not clear without documentation. Maybe use an enum?

export const enum SingletonId {
	PERSISTED, NOT_PERSISTED
}

/**
 * Base interface for all models: an object with an `id` property.
 */
export interface Identifiable {
	id: ObjectId
}

/**
 * Allows `undefined` for all `Scalar` fields in `T` recursively. Nested objects and arrays still must be defined.
 */
export type PartialState<T> = {
	[F in keyof Required<T>]: Required<T>[F] extends Scalar ? T[F] | undefined :
	Required<T>[F] extends Array<unknown> | undefined ? Required<T>[F] :
	PartialState<Required<T>[F]>
}

/**
 * Defines that state of a collection store module, containing the `items` and the `id` that is currently focussed.
 */
export interface CollectionState<T> {
	items: Array<T>
	id: ObjectId
	loading: boolean
}

/**
 * Exposes data related to the model to be used by a component.
 */
export interface ComponentState<T> {
	state: PartialState<T>
	errors?: ObjectErrors<T>
	condition: Condition
	readonly mode: Mode
}

/**
 * Describes the operations allowed on a source of data such as a remote service.
 */
export interface DataSource<T, C extends Cardinality> {
	retrieve(): Promise<ServerState<T, C> | undefined>
	save(data: T): Promise<T | undefined>
	delete(id?: ObjectId): Promise<boolean>
}

export type ServerState<T, C extends Cardinality> = C extends Cardinality.ONE ? T : ReadonlyArray<T>

export const isDataSource = <T, C extends Cardinality>(value: any): value is DataSource<T, C> =>
	!!value && isFunction(value.retrieve) && isFunction(value.save) && isFunction(value.delete)

/**
 * Describes a mutation to a field.
 */
export interface FieldMutation<T, F extends keyof T = keyof T> extends Identifiable {
	readonly field: F
	readonly value: T[F]
}

/**
 * The behaviour around store module state preservation between pages and reloads.
 * - NONE: no state preservation
 * - COMPLETE: preservation of all data in the module
 * - FOCUS: preservation of only the id of the focussed item (applicable to collection state only)
 */
export const enum PreserveState { NONE, COMPLETE, FOCUS }

/**
 * The base configuration object for a store module.
 */
interface ModuleConfig<T, C extends Cardinality, R> {
	/**
	 * The module namespace in the store. Nested namespaces use `/` as a separator.
	 */
	readonly namespace: string
	/**
	 * The data source to read data from.
	 */
	readonly dataSource?: DataSource<T, C>
	/**
	 * The state preservation mode.
	 */
	readonly preserveState?: PreserveState
	/**
	 * Standard Vuex getters to attach to the store module.
	 */
	readonly getters?: C extends Cardinality.ONE ? GetterTree<T, R> : GetterTree<CollectionState<T>, R>
	/**
	 * Standard Vuex actions to attach to the store module.
	 */
	readonly actions?: C extends Cardinality.ONE ? ActionTree<T, R> : ActionTree<CollectionState<T>, R>
}

/**
 * Configuration for an immutable store module.
 */
export interface ImmutableModuleConfig<T, C extends Cardinality, R> extends ModuleConfig<T, C, R> {
	/**
	 * The initial state of the store module.
	 */
	readonly initialState: C extends Cardinality.ONE ? PartialState<T> : Array<T>
}

/**
 * Configuration for a mutable store module.
 */
export interface MutableModuleConfig<T, C extends Cardinality, R, K = undefined> extends ModuleConfig<T, C, R> {
	/**
	 * The validation rules for a single object in the store.
	 */
	readonly rules: ObjectRules<T, K>
	/**
	 * The context used for validation.
	 */
	readonly context: Suspended<K>
}
