import { ConversionMap, optional, required } from "@lib/types/import"
import { one, mandatory, obj } from "@lib/import/extract"

import { alphanumeric, numeric, datetime, enumConstant } from "@lib/import/convert"

import { StatePayload, StepState, CustomerJourneyStatus } from "api/models/customer-journey"

export const stepState: ConversionMap<StepState> = {
	startDateTime: ["startDateTime", datetime, optional],
	endDateTime: ["endDateTime", datetime, optional],
	weight: ["weight", numeric, required]
}

export const statePayload: ConversionMap<Omit<StatePayload<unknown>, "data">> = {
	version: ["version", alphanumeric, required],
	startDateTime: ["startDateTime", datetime, optional],
	endDateTime: ["endDateTime", datetime, optional],
	status: ["status", enumConstant(CustomerJourneyStatus), required],
	steps: ["steps", obj(one(stepState)), required]
}

export default mandatory(one(statePayload))
