import { Function0 } from "../types/function"

import { isFunction } from "lodash-es"

/**
 * The `Suspended` (for lack of a better name) enables multiple ways to make a value of type `T` available.
 * A bare `T` can be used if the value is known at compile time. If the value is only known at runtime,
 * a `Promise`, a `Function0` or a combination of these can be used for asynchronous or dynamic values.
 */
export type Suspended<T> = T | Promise<T> | Function0<T> | Function0<Promise<T>>

/**
 * Turns the `Suspended` into a `Promise` that resolves to the suspended value.
 */
export const expect = async <T>(value: Suspended<T>): Promise<T> => isFunction(value) ? value() : value
