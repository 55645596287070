
import { GetterTree } from "vuex"
import { RootState } from "store/index"
import { Documents, Document } from "./types"

const getters: GetterTree<Documents, RootState> = {
	documents(state: Documents): Array<Document> {
		return state.documents
	},
	unreadDocuments(state: Documents): number {
		return state.documents.filter(document => document.downloads.length === 0).length
	}
}

export default getters
