import { Module, ActionContext } from "vuex"
import { RootState, namespaces } from "store/index"
import { ParticipantState, EndEmployementActionPayload, UpdateSalaryAndOrPartTimePercentageActionPayload } from "./types"
import { OneOffExtraSavingsRequest, Participant, PeriodicExtraSavingsRequest } from "api/models/participants"
import participantClient from "api/clients/participant-client"
import { Agreement } from "store/company/types"
import { sortBy } from "lodash-es"

const participantState: ParticipantState = {
	activeParticipant: undefined,
	participants: [],
	oneOffExtraSavings: [],
	periodicExtraSavings: []
}

type Context = ActionContext<ParticipantState, RootState>

enum MutationType {
	SET_ONEOFF_EXTRA_SAVINGS = "SET_ONEOFF_EXTRA_SAVINGS",
	SET_PERIODIC_EXTRA_SAVINGS = "SET_PERIODIC_EXTRA_SAVINGS",
	SET_PARTICIPANTS = "SET_PARTICIPANTS",
	SET_ACTIVE_PARTICIPANT = "SET_ACTIVE_PARTICIPANT",
	CLEAR_ACTIVE_PARTICIPANT = "CLEAR_ACTIVE_PARTICIPANT"
}

const participantModule: Module<ParticipantState, RootState> = {
	actions: {
		async getOneOffExtraSavings(context: Context): Promise<void> {
			const oneOffExtraSavings = await participantClient.getOneOffExtraSavings()
			context.commit(MutationType.SET_ONEOFF_EXTRA_SAVINGS, oneOffExtraSavings)
		},
		async getPeriodicExtraSavings(context: Context): Promise<void> {
			const periodicExtraSavings = await participantClient.getPeriodicExtraSavings()
			context.commit(MutationType.SET_PERIODIC_EXTRA_SAVINGS, periodicExtraSavings)
		},
		async confirmPeriodicExtraSavings(_context: Context, payload: PeriodicExtraSavingsRequest): Promise<void> {
			await participantClient.confirmPeriodicExtraSavings(payload)
		},
		async getParticipantsByAgreementId(context: Context, agreementId: string): Promise<void> {
			const participants = await participantClient.getParticipantsByAgreementId(agreementId)
			context.commit(MutationType.SET_PARTICIPANTS, participants)
		},
		async endEmployment(_context: Context, payload: EndEmployementActionPayload): Promise<void> {
			// Don't delete the participant directly from state.participants since the participant is not deleted immediately from the database.
			await participantClient.endEmployment(payload.participantId, { employmentEndDate: payload.employmentEndDate })
		},
		async updateSalaryAndOrPartTimePercentage(context: Context, payload: UpdateSalaryAndOrPartTimePercentageActionPayload): Promise<void> {
			const agreement = context.rootGetters[`${ namespaces.company }/agreement`] as Agreement
			const participant = context.getters.activeParticipant as Participant

			if (!agreement || !participant) {
				return
			}

			await participantClient.updateParticipant(agreement.externalIdentifier, payload.participantId, {
				...participant,
				newSalary: payload.newSalary,
				newPartTimePercentage: payload.newPartTimePercentage,
				startDateNewChanges: payload.startDateNewSalaryAndOrPartTimePercentage
			})
		},

		setActiveParticipant(context: Context, payload: Participant): void {
			context.commit(MutationType.SET_ACTIVE_PARTICIPANT, payload)
		},
		clearActiveParticipant(context: Context): void {
			context.commit(MutationType.CLEAR_ACTIVE_PARTICIPANT)
		}
	},
	getters: {
		participants(state: ParticipantState): Array<Participant> {
			return state.participants
		},
		activeParticipant(state: ParticipantState): Participant | undefined {
			return state.activeParticipant
		},
		oneOffExtraSavings(state: ParticipantState): Array<OneOffExtraSavingsRequest> {
			return sortBy(state.oneOffExtraSavings, oneOffExtraSaving => -oneOffExtraSaving.applicationDateTime.valueOf())
		},
		periodicExtraSavings(state: ParticipantState): Array<PeriodicExtraSavingsRequest> {
			return sortBy(state.periodicExtraSavings, periodicExtraSaving => -periodicExtraSaving.applicationDateTime.valueOf())
		}
	},
	mutations: {
		[MutationType.SET_PARTICIPANTS](state: ParticipantState, payload: Array<Participant>): void {
			state.participants = payload
		},
		[MutationType.SET_ONEOFF_EXTRA_SAVINGS](state: ParticipantState, payload: Array<OneOffExtraSavingsRequest>): void {
			state.oneOffExtraSavings = payload
		},
		[MutationType.SET_PERIODIC_EXTRA_SAVINGS](state: ParticipantState, payload: Array<PeriodicExtraSavingsRequest>): void {
			state.periodicExtraSavings = payload
		},
		[MutationType.SET_ACTIVE_PARTICIPANT](state: ParticipantState, payload: Participant): void {
			state.activeParticipant = payload
		},
		[MutationType.CLEAR_ACTIVE_PARTICIPANT](state: ParticipantState): void {
			state.activeParticipant = undefined
		}
	},
	namespaced: true,
	state: participantState
}

export default participantModule
