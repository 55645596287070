
	import Vue from "vue"
	import Component from "vue-class-component"

	import PTextInput from "@portal-components/ui/input/PTextInput.vue"
	import PButton from "@portal-components/ui/input/PButton.vue"

	import userClient from "api/clients/user-client"

	@Component({
		components: {
			PTextInput,
			PButton
		}
	})
	export default class EmailVerification extends Vue {
		readonly baseTranslationKey = "login.emailVerification"

		verificationCode: string | null = null
		invalidVerificationCode = false

		async submitVerification(): Promise<void> {
			if (!this.verificationCode) {
				return
			}

			const tokenValid = await userClient.checkTokenValidity(this.verificationCode)

			if (tokenValid) {
				this.$emit("emailVerified", this.verificationCode)
			} else {
				this.invalidVerificationCode = true
			}
		}
	}
