
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch, Emit } from "vue-property-decorator"

	import { OptionModel } from "@portal-components/ui/input/option-model"
	import { HTMLEvent } from "@portal-components/ui/utils/events"

	import { isEqual } from "lodash-es"

	@Component
	export default class PRadiobuttonGroup<T extends OptionModel> extends Vue {
		@Prop({ type: Boolean, default: false }) inline!: boolean
		@Prop({ type: Boolean, default: false }) disabled!: boolean
		@Prop({ type: Array, default: () => [] }) options!: Array<T>
		@Prop({ type: [String, Number, Boolean, Object] }) value?: string | number | boolean | object
		@Prop({ type: Boolean, default: false }) noLabel!: boolean

		internalValue: number = -1

		@Watch("options", { immediate: true })
		@Watch("value", { immediate: true })
		valueChanged(): void {
			// this.value can be false, which is why we check like this.
			if (this.value === null || this.value === undefined) {
				return
			}

			this.internalValue = this.options.findIndex(option => isEqual(option.value, this.value))
		}

		@Emit(HTMLEvent.INPUT)
		@Emit(HTMLEvent.CHANGE)
		onChange(): T {
			const selected = this.options[this.internalValue]?.value
			return selected
		}
	}
