import { GetterTree } from "vuex"

import { CollectionState } from "../../types/model"

import { identity } from "lodash-es"

export const enum GetterType {
	STATE = "state",
	ITEMS = "items",
	ID = "id"
}

export const singletonGetters = <S, R>(): GetterTree<S, R> => ({
	[GetterType.STATE]: identity
})

export const collectionGetters = <S, R>(): GetterTree<CollectionState<S>, R> => ({
	[GetterType.STATE]: identity,
	[GetterType.ITEMS]: state => state.items,
	[GetterType.ID]: state => state.id
})
