import { Parameters, RequestMethod, Request } from "../types/request"

import RequestStrategy from "../request/RequestStrategy"

export default class RestApi {

	constructor(
		private readonly endpoint: string,
		private readonly requestStrategy: RequestStrategy
	) {}

	get(resource: string, data?: Parameters): Request {
		return this.requestStrategy.request(RequestMethod.GET, this.endpoint + resource, data)
	}

	post(resource: string, data?: Parameters): Request {
		return this.requestStrategy.request(RequestMethod.POST, this.endpoint + resource, data)
	}

	put(resource: string, data?: Parameters): Request {
		return this.requestStrategy.request(RequestMethod.PUT, this.endpoint + resource, data)
	}

	patch(resource: string, data?: Parameters): Request {
		return this.requestStrategy.request(RequestMethod.PATCH, this.endpoint + resource, data)
	}

	delete(resource: string, data?: Parameters): Request {
		return this.requestStrategy.request(RequestMethod.DELETE, this.endpoint + resource, data)
	}

	async getJson<T = unknown>(resource: string, data?: Parameters): Promise<T> {
		return (await this.get(resource, data).response).json()
	}

	async getBlob(resource: string, data?: Parameters): Promise<Blob> {
		return (await this.get(resource, data).response).blob()
	}

	async getText(resource: string, data?: Parameters): Promise<string> {
		return (await this.get(resource, data).response).text()
	}

}
