
	import Vue from "vue"
	import Component from "vue-class-component"
	import Badge from "@components/supplemental/Badge.vue"
	import Hamburger from "custom/Hamburger.vue"

	import PIcon from "@portal-components/ui/PIcon.vue"

	import IconLabel from "@components/supplemental/IconLabel.vue"
	import LinkButton from "@components/button/LinkButton.vue"
	import MenuModal from "custom/modal/MenuModal.vue"
	import { StoreAction } from "@lib/types/vuex"
	import { userModule, authenticationModule, companyModule } from "store/modules"
	import { User } from "store/user/types"
	import localStorageClient from "api/clients/local-storage-client"
	import { Company } from "store/company/types"
	import { Prop } from "vue-property-decorator"

	@Component({
		components: {
			Badge,
			Hamburger,
			LinkButton,
			MenuModal,
			IconLabel,
			PIcon
		}
	})
	export default class PageHeader extends Vue {
		@Prop({required: false, default: false}) isLoginPage!: boolean

		@authenticationModule.Getter loggedIn!: boolean
		@authenticationModule.Action logout!: StoreAction<void>
		@userModule.Getter user!: User
		@companyModule.Getter company!: Company

		readonly baseTranslationKey = "pageHeader"
		checkImpersonate = localStorageClient.getItem("CHECK_IMPERSONATE")
		menuModalOpened = false
	}
