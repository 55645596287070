
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Ref, Watch } from "vue-property-decorator"

	@Component
	export default class PProgressCircle extends Vue {
		@Prop({ type: Number, default: 0 }) value!: number
		@Prop({ required: false, type: Boolean, default: true }) pulse!: boolean

		@Ref()
		canvas!: HTMLDivElement

		@Ref()
		circle!: SVGCircleElement

		circumference = 0
		offset = 0

		mounted(): void {
			this.circumference = this.circle.r.baseVal.value * 2 * Math.PI
			this.offset = this.circumference - this.value / 100 * this.circumference
		}

		@Watch("value")
		updateOffset(): void {
			this.offset = this.circumference - this.value / 100 * this.circumference

			if (this.pulse) {
				this.pulsate()
			}
		}

		pulsate(): void {
			this.canvas.style.animation = "none"
			// eslint-disable-next-line no-unused-expressions
			this.canvas.offsetHeight /* triggers reflow = restarts keyframe animation */
			this.canvas.style.animation = ""
		}
	}
