import Vue from "vue"

export default abstract class LoadingComponent extends Vue {
	loading = false

	manageLoadingState<T>(promise: Promise<T>): Promise<T> {
		this.loading = true

		return promise.then(result => result)
			.finally(() => this.loading = false)
	}
}
