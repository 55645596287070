
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Emit } from "vue-property-decorator"

	import PageSlide from "@components/container/PageSlide.vue"
	import NextStepButton from "custom/customer-journey/NextStepButton.vue"

	@Component({
		components: {
			PageSlide,
			NextStepButton
		}
	})
	export default class CustomerJourneyStep extends Vue {
		@Prop({ type: Boolean, default: true }) nextStepAllowed!: boolean
		@Prop({ type: Boolean, default: false }) showSuccessPage!: boolean
		@Prop({ type: Boolean, default: false }) large!: boolean
		@Prop({ type: String, default: "primary" }) slideType!: "primary" | "secondary"

		get canShowBody(): boolean {
			return !this.showSuccessPage || !this.nextStepAllowed
		}

		@Emit("click")
		onNextClick(event: Event): Event {
			return event
		}
	}
