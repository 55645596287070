import { loadGoogleTagManager } from "tracking/google-tag-manager"

export interface Config {
	loginUrl: string
	refreshTokenEndpoint: string
	api: {
		endpoint: string
		authenticationEndpoint: string
	}
	provideEmployeeData: {
		migrationYear: number
	}
	eRecognition: {
		loginUrl: string
		samlUrl: string
		tokenUrl: string
	}
	googleTagManager?: {
		id: string
	},
	showExtraSavings?: boolean
}

declare const config: Config

if (process.env.SERVER) {
	global.config = {
		loginUrl: "",
		localjsons: false,
		api: {
			endpoint: "",
			authenticationEndpoint: ""
		},
		provideEmployeeData: {
			migrationYear: 0
		}
	}
}

export const loginUrl = config.loginUrl
export const refreshTokenEndpoint = config.refreshTokenEndpoint
export const api = config.api
export const eRecognitionConfig = config.eRecognition
export const provideEmployeeDataConfig = config.provideEmployeeData
export const showExtraSavings = config.showExtraSavings ?? true

loadGoogleTagManager(config)
