import { namespace } from "vuex-class"
import { namespaces } from "store/index"

export const messagesModule = namespace(namespaces.messages)
export const companyModule = namespace(namespaces.company)
export const documentsModule = namespace(namespaces.documents)
export const authenticationModule = namespace(namespaces.authentication)
export const contactDetailsModule = namespace(namespaces.contactDetails)
export const participantModule = namespace(namespaces.participant)
export const userModule = namespace(namespaces.user)
export const premiumModule = namespace(namespaces.premium)
