import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import { AuthenticationState } from "./types"
import { Module } from "vuex"
import { RootState } from "store/index"

const authenticationModule: Module<AuthenticationState, RootState> = {
	state : {
		loggedIn: false,
		locale: "",
		timestamp: 0
	},
	actions,
	getters,
	mutations,
	namespaced: true
}

export default authenticationModule
