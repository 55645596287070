import { ConversionMap, optional, required } from "@lib/types/import"
import { FileType } from "./types"
import { alphanumeric } from "@lib/import/convert"
import { isUndefined } from "lodash-es"
import { one, many } from "@lib/import/extract"

function convertToByteArray(input: string): Array<Uint8Array> {
	const sliceSize = 512
	const bytes = []

	for (let offset = 0; offset < input.length; offset += sliceSize) {
		const slice = input.slice(offset, offset + sliceSize)
		const byteNumbers = new Array(slice.length)

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}

		const byteArray = new Uint8Array(byteNumbers)

		bytes.push(byteArray)
	}

	return bytes
}

export function deserializeBase64File(encodedString: string): File | undefined {
	if (isUndefined(encodedString)) {
		return undefined
	}

	const blobBinary = atob(encodedString)
	const blob = new Blob(convertToByteArray(blobBinary))
	return new File([blob], "") // TODO: get filename from name field?
}

export function serializeBase64File(file: File): Promise<string> {
	const reader = new FileReader()
	return new Promise(resolve => {

		reader.onload = event => {
			const arrayBuffer = event.target!.result as ArrayBuffer
			const array = new Uint8Array(arrayBuffer)
			const binaryString = String.fromCharCode.apply(null, array)
			return resolve(btoa(binaryString))
		}
		reader.readAsArrayBuffer(file)
	})
}

const fileType: ConversionMap<FileType> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, optional],
	name: ["name", alphanumeric, required],
	content: ["content", alphanumeric, required],
	type: ["type", alphanumeric, optional]
}

export const manyFileTypes = many(fileType)

export const oneFileType = one(fileType)
