
	import Vue from "vue"
	import Component from "vue-class-component"
	import Card from "@components/panel/Card.vue"
	import CardSection from "@components/supplemental/CardSection.vue"
	import { premiumModule, companyModule } from "store/modules"
	import { Company } from "store/company/types"

	import { isUndefined } from "lodash-es"

	@Component({
		components: {
			Card,
			CardSection
		}
	})
	export default class CurrentBalance extends Vue {
		@premiumModule.Getter balance!: number
		@companyModule.Getter company!: Company

		readonly isUndefined = isUndefined
		readonly baseTranslationKey = "payPremium"
		readonly bankData = {
			number: "NL64 RABO 0328 9589 80",
			accountHolder: "t.n.v. Scildon N.V. Inz. Collectief Pensioen"
		}

		get isNegativeBalance(): boolean {
			return this.balance < 0
		}
	}
