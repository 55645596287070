
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	import toaster from "@portal-components/ui/utils/toaster"
	import PButton from "@portal-components/ui/input/PButton.vue"

	import { api } from "api/index"
	import { deserializeBase64File, oneFileType } from "store/conversion"
	import { FileType } from "store/types"

	@Component({
		components: {
			PButton
		}
	})
	export default class IcsDownloadLink extends Vue {
		@Prop({ type: String }) url!: string
		@Prop({ type: Object }) document!: FileType
		@Prop({ type: Boolean, default: false }) button!: boolean
		@Prop({ type: Boolean, default: false }) disabled!: boolean

		loading = false

		async downloadDocument(): Promise<void> {
			if (!!this.document) {
				this.downloadFile(this.document)
				return
			}

			if (!this.url) {
				return
			}

			try {
				this.loading = true

				const fileType: FileType | undefined = oneFileType(await api.getJson(this.url))
				if (!fileType) {
					return
				}

				this.downloadFile(fileType)
			} catch (error) {
				toaster.error({ title: "general.error" })
			} finally {
				this.loading = false
			}
		}

		private downloadFile(fileType: FileType): void {
			if (!fileType?.content) {
				return
			}

			const file: File | undefined = deserializeBase64File(fileType.content)
			if (!file) {
				return
			}

			this.downloadBlob(file, fileType.name)
		}

		private downloadBlob(file: File, fileName: string): void {
			const url = URL.createObjectURL(new File([file], fileName))

			const downloadLink: HTMLAnchorElement = document.createElement("a")
			downloadLink.href = url
			downloadLink.download = fileName
			downloadLink.hidden = true

			document.body.appendChild(downloadLink)
			downloadLink.click()

			document.body.removeChild(downloadLink)
			URL.revokeObjectURL(url)
		}
	}
