export interface OptionModel {
	value: any
	label: string
}

export interface MultiOptionModel {
	optionModels: Array<OptionModel>
}

export function toOptionModel(value: any, label: string = ""): OptionModel {
	return { value, label }
}
