import Vue from "vue"

import { get } from "lodash-es"

interface Context {
	[key: string]: any
}

interface FilterMatch {
	mustache: string
	content: string
}

/**
 * Renders the mustache and applies Vue filters.
 *
 * @argument {string} mustache The contents of the mustache to be rendered.
 * @argument {object} context The context to get values from.
 * @returns {string}
 */
const render = (mustache: string, context: Context): string => {
	const pipeline = mustache.split("|").map(s => s.trim())
	if (pipeline.length === 0) {
		throw new RangeError("Empty mustache")
	}

	let value = get(context, pipeline[0])
	if (!value && value !== 0) { // 0 is still a valid value
		value = pipeline[0] // could be a string defined in translation file
	}

	// Process the filters.
	return pipeline.slice(1).reduce((result, filter) => {
		const filterParts = filter.split("(")

		if (filterParts.length > 1) {
			const filterArguments = filterParts[1].slice(0, -1).split(",")
			return Vue.filter(filterParts[0])(result, ...filterArguments)
		}

		return Vue.filter(filter)(result)
	}, value)
}

const reduceMatch = (content: string, filterMatch: FilterMatch, context: Context): string => {
	const filterResult = render(filterMatch.content, context)
	return content.replace(filterMatch.mustache, filterResult)
}

export default (template: string, context: Context): string => {
	// 'Grammar' for mustaches: {{ variable [ | variable [ (numeric argument) ] ]* }}
	const matcher = /{{([ ]?(?:[A-Z0-9._-]|\/|:)+[ ]?(\|[ ]?[A-Z0-9.]+(?:[(][A-Z0-9, ]+[)])?[ ]?)*)}}/gi
	const regexMatchArrayArray: Array<RegExpMatchArray> = [...template.matchAll(matcher)]
	const matches: Array<FilterMatch> = regexMatchArrayArray.map(regexMatchArray => ({ mustache: regexMatchArray[0], content: regexMatchArray[1] }))
	// Every result is an array where element 0 is the complete match, and 1 is the contents within {{ and }}.
	return matches.reduce((cumulative, filterMatch) => reduceMatch(cumulative, filterMatch, context), template)
}
