
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch, Emit } from "vue-property-decorator"
	import IcsIcon from "custom/ui/IcsIcon.vue"

	// TODO: Move this to portal-components later.
	@Component({
		components: {
			IcsIcon
		}
	})
	export default class IcsPasswordInput extends Vue {
		@Prop({ type: String, default: " "}) placeholder!: string
		@Prop({ type: Boolean, default: false }) required!: boolean
		@Prop({ type: Boolean, default: false }) readonly!: boolean
		@Prop({ type: Boolean, default: false }) inline!: boolean
		@Prop({ type: Boolean, default: false }) floatLabel!: boolean
		@Prop({ type: Boolean, default: false }) noLabel!: boolean
		@Prop({ type: Boolean, default: false }) disabled!: boolean
		@Prop({ type: Boolean, default: false }) appendLabel!: boolean
		@Prop({ type: Boolean, default: false }) tooltip!: boolean
		@Prop({ default: "" }) value!: string
		@Prop({ type: String, default: "" }) validationErrorMessage!: string

		internalType: "password" | "text" = "password"
		internalValue: string = ""

		@Watch("value", {immediate: true})
		valueChanged(): void {
			this.internalValue = this.value
		}

		@Emit("input")
		onChange(): string {
			return this.internalValue
		}

		toggleShowPassword(): void {
			this.internalType = this.internalType === "password" ? "text" : "password"
		}
	}
