import { CivilStatusType } from "@lib/types/personal/policyholder"

export interface Participant {
	externalIdentifier: string
	policyNumber?: string
	fullName: string
	dateOfBirth: Date
	civilStatus: CivilStatusType
	startDate: Date
	salary: number
	partTimePercentage: number
}

export interface EndEmployement {
	employmentEndDate: Date
}

export interface UpdateSalaryAndOrPartTimePercentage {
	newSalary: number
	newPartTimePercentage: number
	startDateNewChanges: Date
}

export type UpdateParticipant = Participant & UpdateSalaryAndOrPartTimePercentage

export interface OneOffExtraSavingsRequest {
	applicationDateTime: Date
	policyId: any
	name: string
	amount: number
	eventDate: Date
	participantId: string
}

export interface PeriodicExtraSavingsRequest extends OneOffExtraSavingsRequest {
	type: SavingsRequestType
	status: SavingsRequestStatus
}

export enum SavingsRequestType {
	START = "START",
	STOP = "STOP",
	CHANGE = "CHANGE"
}

export enum SavingsRequestStatus {
	PROCESSED = "PROCESSED",
	PENDING = "PENDING"
}
