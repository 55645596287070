import { ConversionMap, required } from "@lib/types/import"
import { alphanumeric } from "@lib/import/convert"
import { one, mandatory, many } from "@lib/import/extract"

import { User } from "./types"

const user: ConversionMap<User> = {
	id: ["id", alphanumeric, required],
	fullName: ["fullName", alphanumeric, required],
	roles: ["roles", many(alphanumeric), required],
	email: ["email", alphanumeric, required]
}

export default mandatory(one(user))
