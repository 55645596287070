import { Identifiable, Cardinality, PartialState } from "../types/model"
import Model from "../model/Model"

export default class ImmutableModel<T extends Identifiable, C extends Cardinality, R> extends Model<T, C, R> {
	protected state: PartialState<T> = this.defaultState()

	protected defaultState(): PartialState<T> {
		// We don't know anything about `T` so the cast is necessary. For immutable models, initial state should be provided.
		return {} as PartialState<T>
	}

	async save(): Promise<boolean> {
		return false
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	async delete(): Promise<void> {
	}
}
