import { ConversionFunction } from "../types/import"

/**
 * A function that performs a validation and returns whether it was successful.
 *
 * @this this The object being validated.
 * @param value The value of the field being validated.
 * @param context An object with context information that can be used by the validation.
 */
// The type F should actually be `F extends keyof T` (and the type of the value argument T[F]) but type inference fails if we do that.
export type Validator<F, T, C> = (this: Partial<T>, value: F, context: C) => boolean | Promise<boolean>

export type Scalar = string | number | Date | boolean | File

/**
 * Describes the validation rules for the fields in an object `T`. The rules may use the context object `C` for custom validation.
 */
export type ObjectRules<T, C = undefined> = {
	[F in keyof T]-?: FieldRules<F, T, C> | false
}

export enum ErrorCode {
	REQUIRED = "required",
	TYPE = "type",
	VALUES = "values",
	MAXLENGTH = "maxlength",
	MINLENGTH = "minlength",
	PATTERN = "pattern",
	MAXIMUM = "maximum",
	MINIMUM = "minimum"
}

/**
 * Describes the validation rules for a field `F` of object `T` in context `C`.
 */
export interface FieldRules<F extends keyof T, T, C> {
	readonly [ErrorCode.REQUIRED]: boolean | ((this: Partial<T>, context: C) => boolean)
	readonly [ErrorCode.TYPE]: Type<T[F]> | ConversionFunction<T[F]>
	readonly [ErrorCode.VALUES]?: ReadonlyArray<NonNullable<T[F]>>

	// String rules:
	readonly [ErrorCode.MAXLENGTH]?: T[F] extends string | undefined ? number : never
	readonly [ErrorCode.MINLENGTH]?: T[F] extends string | undefined ? number : never
	readonly [ErrorCode.PATTERN]?: T[F] extends string | undefined ? RegExp : never

	// Number or Date rules:
	readonly [ErrorCode.MAXIMUM]?: T[F] extends number | Date | undefined ? T[F] : never
	readonly [ErrorCode.MINIMUM]?: T[F] extends number | Date | undefined ? T[F] : never

	// Custom rules:
	readonly custom?: T[F] extends Scalar | undefined ? CustomRules<F, T, C> : never

	// Nested rules:
	// FIXME: this property should be mandatory for nested objects.
	readonly fields?: T[F] extends Scalar | undefined
		? never :
		T[F] extends Array<infer N> | undefined ? ObjectRules<N, C> :
		T[F] extends object | undefined ? ObjectRules<T[F], C> : never
}

export interface CustomRules<F extends keyof T, T, C> {
	// Custom validations are run after the default ones succeed. Therefore, the function
	// receives a value and a context that are both of the expected type. This is contrary to
	// the other validation functions, which receive an unknown value and a partial context.
	readonly [key: string]: Validator<NonNullable<T[F]>, T, C>
}

export const enum Datatype { STRING, NUMBER, DATE, BOOLEAN, FILE, ARRAY, OBJECT }

export type Type<T> =
	T extends string ? Datatype.STRING :
	T extends number ? Datatype.NUMBER :
	T extends Date ? Datatype.DATE :
	T extends boolean ? Datatype.BOOLEAN :
	T extends File ? Datatype.FILE :
	T extends Array<unknown> ? Datatype.ARRAY :
	T extends object ? Datatype.OBJECT :
	never

export type ScalarErrors = ReadonlyArray<string>
export type ArrayErrors<T> = Map<number, ObjectErrors<T>>

/**
 * Describes the validation result for a single field. The field can be scalar or complex (an array of `T` or an object `T`).
 */
export type FieldErrors<T = unknown> = ScalarErrors | ArrayErrors<T> | ObjectErrors<T> | undefined

/**
 * Describes the validation errors encountered in a context object `T`.
 */
export type ObjectErrors<T> = {
	[F in keyof T]?: FieldErrors
}

/**
 * Performs the validation for a field and returns the `FieldErrors` either synchronously or in a `Promise` if the validation is asynchronous.
 */
export type FieldValidator<F extends keyof T, T, C> = (this: Partial<T>, value: NonNullable<T[F]>, context: C) => Promise<FieldErrors>

export type ObjectValidator<T, C> = (object: unknown, context: C) => Promise<ObjectErrors<T> | undefined>
