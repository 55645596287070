import { ConversionMap, required, optional } from "@lib/types/import"
import { PremiumState, Transaction } from "store/premium/types"
import { numeric, alphanumeric, date } from "lib/import/convert"
import { many, mandatory, one } from "lib/import/extract"

const transaction: ConversionMap<Transaction> = {
	description: ["description", alphanumeric, required],
	cashAmount: ["cashAmount", numeric, required],
	date: ["date", date, required]
}

const premium: ConversionMap<PremiumState> = {
	balance: ["balance", numeric, required],
	transactions: ["transactions", many(transaction), optional]
}

export default mandatory(one(premium))
