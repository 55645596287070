
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"

	import eventBus from "lib/vue/eventBus"

	@Component
	export default class TabButton<T = any> extends Vue {
		@Prop({required: true}) value!: T
		@Prop({required: false, type: Boolean, default: false}) disabled!: boolean
		@Prop({required: false, type: String, default: ""}) event!: string

		clicked(): void {
			if (!this.disabled) {
				this.$emit("select", this.value)
			}
			if (this.event) {
				eventBus.emit(this.event)
			}
		}
	}
