var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"p-input p-input--number",class:{
		'p-input--inline': _vm.inline,
		'p-input--prepend': _vm.prepend,
		'p-input--append': _vm.append,
		'p-input--unit-no-border': _vm.noIndicatorBorder,
		'p-input--float-label': _vm.floatLabel,
		'p-input--no-label': _vm.noLabel,
		'p-input--label-hides': _vm.hideLabelOnInput,
		'p-input--required': _vm.required,
		'p-input--readonly': _vm.readonly,
		'p-input--disabled': _vm.disabled,
		'p-input--roundtrip': _vm.isLoading,
		'p-input--success': _vm.isCorrect,
		'p-input--error': _vm.$slots.error,
		'p-input--hint': !_vm.$slots.error && _vm.$slots.hint,
		'p-tooltip': _vm.$slots.tooltip
	}},[_c('div',{staticClass:"p-input__separate"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],ref:"internalInput",staticClass:"p-input__text",attrs:{"type":"number","required":_vm.required,"readonly":_vm.readonly,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"min":_vm.min,"max":_vm.max,"step":_vm.stepSize},domProps:{"value":(_vm.internalValue)},on:{"change":_vm.onChange,"input":function($event){if($event.target.composing)return;_vm.internalValue=$event.target.value}}}),_vm._v(" "),(_vm.prepend || _vm.append)?_c('div',{staticClass:"p-input__string"},[_vm._t("indicator")],2):_vm._e(),_vm._v(" "),_c('span',{staticClass:"p-input__label"},[_vm._t("default")],2),_vm._v(" "),_c('span',{staticClass:"p-tooltip__text"},[_vm._t("tooltip")],2)]),_vm._v(" "),_c('span',{staticClass:"p-input__validate-text"},[_vm._t("error"),_vm._v(" "),(!_vm.$slots.error)?_vm._t("hint"):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }