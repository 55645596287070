class StorageMock implements Storage {
	get length(): number {
		return 0
	}
	clear(): void {
		// No action.
	}
	getItem(_key: string): string | null {
		return null
	}
	key(_index: number): string | null {
		return null
	}
	removeItem(_key: string): void {
		// No action.
	}
	setItem(_key: string, _value: string): void {
		// No action.
	}
}

const storage = process.env.SERVER ? new StorageMock() : localStorage
export default storage