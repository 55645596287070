
	import { Prop } from "vue-property-decorator"
	import Component from "vue-class-component"
	import PAccordionItem from "@portal-components/ui/PAccordionItem.vue"
	import AccordionLocaleTranslations from "custom/accordion/AccordionLocaleTranslations.vue"
	import { SignUpEmployeesVariant } from "pages/SignUpEmployees.vue"

	@Component({
		components: {
			PAccordionItem
		}
	})
	export default class SignUpInstructions extends AccordionLocaleTranslations {
		@Prop({ type: String, required: true }) variant!: SignUpEmployeesVariant

		readonly baseTranslationkey = "signUpEmployees.provideEmployeeData.instructions"

		get internalVariant(): string {
			return this.variant === SignUpEmployeesVariant.YEARLY_SALARY_CHANGE ? "yearlySalaryChange" : "start"
		}

		get signUpIntructions(): Array<string> {
			const instructionFields = this.getAccordionLocaleTranslations(`${ this.baseTranslationkey }.${ this.internalVariant }`)
			return Object.keys(instructionFields)
		}
	}
