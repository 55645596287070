import Vue from "vue"

export enum ToastType {
	SUCCESS = "success",
	ERROR = "error",
	INFO = "info",
	CONFIRM = "confirm"
}

export interface Toast {
	title: string
	type: ToastType
	durationMs: number
}

export interface ConfirmationToast extends Toast {
	confirmationMessage: string
	rejectionMessage: string
}

export interface ToastConfig {
	title: string
	durationMs?: number
}

export interface ConfirmationToastConfig extends ToastConfig {
	confirmationMessage?: string
	rejectionMessage?: string
}

export const SHOW_TOAST = "SHOW_TOAST"
export const CONFIRM_TOAST = "CONFIRM_TOAST"
export const eventBus = new Vue()

class Toaster {
	private static readonly DEFAULT_DURATION = 3000
	private static readonly DEFAULT_CONFIRM_DURATION = 5000

	success(config: ToastConfig): void {
		const toast: Toast = {
			title: config.title,
			type: ToastType.SUCCESS,
			durationMs: config.durationMs || Toaster.DEFAULT_DURATION
		}

		eventBus.$emit(SHOW_TOAST, toast)
	}

	error(config: ToastConfig): void {
		const toast: Toast = {
			title: config.title,
			type: ToastType.ERROR,
			durationMs: config.durationMs || Toaster.DEFAULT_DURATION
		}

		eventBus.$emit(SHOW_TOAST, toast)
	}

	info(config: ToastConfig): void {
		const toast: Toast = {
			title: config.title,
			type: ToastType.INFO,
			durationMs: config.durationMs || Toaster.DEFAULT_DURATION
		}

		eventBus.$emit(SHOW_TOAST, toast)
	}

	confirm(config: ConfirmationToastConfig): Promise<boolean> {
		return new Promise(resolve => {
			const toast: ConfirmationToast = {
				title: config.title,
				type: ToastType.CONFIRM,
				durationMs: config.durationMs || Toaster.DEFAULT_CONFIRM_DURATION,
				confirmationMessage: config.confirmationMessage || "toast.confirm.confirm",
				rejectionMessage: config.rejectionMessage || "toast.confirm.reject"
			}

			eventBus.$once(CONFIRM_TOAST, (confirmed: boolean) => resolve(confirmed))
			eventBus.$emit(SHOW_TOAST, toast)
		})

	}
}

export default new Toaster()
