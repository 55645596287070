import { filterContainsValue, columnContainsValue } from "./utils"

export const filterFunctions = {
	equals,
	numberEquals,
	notEquals,
	contains,
	startsWith,
	endsWith
}

export type filterFn = (colValue: string, filterValue: string) => boolean

export function startsWith(colValue: string, filterValue: string): boolean {
	if (!filterContainsValue(filterValue)) {
		return true
	}

	if (!columnContainsValue(colValue)) {
		return false
	}

	return colValue.toLowerCase().slice(0, filterValue.length) === filterValue.toLowerCase()
}

export function contains(colValue: string, filterValue: string): boolean {
	if (!filterContainsValue(filterValue)) {
		return true
	}

	if (!columnContainsValue(colValue)) {
		return false
	}

	return colValue.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
}

export function endsWith(colValue: string, filterValue: string): boolean {
	if (!filterContainsValue(filterValue)) {
		return true
	}

	if (!columnContainsValue(colValue)) {
		return false
	}

	return colValue.toLowerCase().indexOf(filterValue.toLowerCase(), colValue.length - filterValue.length) !== -1
}

export function equals(colValue: string, filterValue: string): boolean {
	if (!filterContainsValue(filterValue)) {
		return true
	}

	if (!columnContainsValue(colValue)) {
		return false
	}

	return colValue.toLowerCase() === filterValue.toLowerCase()
}

export function numberEquals(colValue: number, filterValue: number): boolean {
	if (!filterValue) {
		return true
	}

	if (!colValue) {
		return false
	}

	return colValue === filterValue
}

export function notEquals(colValue: string, filterValue: string): boolean {
	if (!filterContainsValue(filterValue)) {
		return true
	}

	if (!columnContainsValue(colValue)) {
		return false
	}

	return colValue.toLowerCase() !== filterValue.toLowerCase()
}
