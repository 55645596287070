
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Ref, Emit } from "vue-property-decorator"

	import PCard from "@portal-components/ui/layout/PCard.vue"
	import PCardSection from "@portal-components/ui/layout/PCardSection.vue"
	import PButton from "@portal-components/ui/input/PButton.vue"

	import { isUndefined } from "lodash-es"

	export interface POutroItem {
		title: string
		description: string
		toStep?: string
		url?: string
		newTab?: boolean
	}

	@Component({
		components: {
			PCard,
			PCardSection,
			PButton
		}
	})
	export default class OutroItems extends Vue {
		@Prop({ required: true, type: Array }) items!: Array<POutroItem>

		@Ref()
		link!: HTMLAnchorElement

		onClick(item: POutroItem, event: MouseEvent): void {
			if (!isUndefined(item.toStep)) {
				this.resolveStep(item.toStep, event)
			} else {
				this.resolveLink(item)
			}
		}

		resolveLink(item: POutroItem): void {
			this.link.href = item.url || "#"
			this.link.target = item.newTab ? "_blank" : "_self"
			this.link.click()
		}

		resolveStep(stepRef: string, event: MouseEvent): void {
			this.goToStep(stepRef, event)
		}

		@Emit("to-step")
		goToStep(toStep: string, event: MouseEvent): [string, MouseEvent] {
			return [toStep, event]
		}
	}
