
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Inject } from "vue-property-decorator"

	import PButton from "@portal-components/ui/input/PButton.vue"
	import PPageSlide from "@portal-components/customer-journey/PPageSlide.vue"

	import PProgressCircle from "@portal-components/ui/PProgressCircle.vue"
	import { CustomerJourneyNamespace } from "@portal-components/customer-journey/configuration"
	import type { CustomerJourneyControls } from "@portal-components/customer-journey/configuration"

	@Component({
		components: {
			PButton,
			PPageSlide,
			PProgressCircle
		}
	})
	export default class PIntro extends Vue {
		@Inject(CustomerJourneyNamespace.CUSTOMER_JOURNEY) customerJourneyControls!: CustomerJourneyControls
	}
