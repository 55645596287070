
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	import PCollection from "@portal-components/generic/PCollection.vue"

	@Component({
		components: {
			PCollection
		}
	})
	export default class PWizard extends Vue {
		@Prop({ type: Number, default: 0 }) value!: number

		slides = 0

		mounted(): void {
			const slideWrapper = this.$refs.slides as Element
			this.slides = slideWrapper.children.length
		}
	}
