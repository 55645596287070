
	import Vue from "vue"
	import Component from "vue-class-component"
	import { PropSync } from "vue-property-decorator"

	import PButton from "@portal-components/ui/input/PButton.vue"
	import eventBus from "lib/vue/eventBus"

	@Component({
		components: {
			PButton
		}
	})
	export default class CustomModal extends Vue {
		@PropSync("value", { required: true, type: Boolean }) opened!: boolean

		readonly baseEmployeeTranslationKey = "employees.changes"

		handleModalClose() {
			this.opened = false
			eventBus.emit("modalCancelled")
		}

		handleModalConfirm() {
			this.opened = false
			eventBus.emit("retirementDateWarningModalConfirmed")
		}
	}
