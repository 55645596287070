import { Triple } from "../types/tuple"
import { Function1 } from "../types/function"

/**
 * Takes a value and returns a `T` if conversion is possible. Otherwise returns `undefined`.
 */
export type ConversionFunction<T> = Function1<any, T | undefined>

type Required<T> = T extends undefined ? false : true

/**
 * Maps all fields of an incoming object to an object of type `T`.
 *
 * Fields:
 * 1. The path to the field in the incoming data object. In most cases this is just the field name.
 * 2. The function to convert the incoming value to its proper type.
 * 3. Whether the field is required. Optional fields can be undefined.
 */
export type ConversionMap<T> = {
	[P in keyof T]-?: Triple<string | null, ConversionFunction<T[P]>, Required<T[P]>>
}

// Helper booleans for defining conversion maps in a more readable way.
export const required = true
export const optional = false
