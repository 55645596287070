
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	@Component
	export default class PPageSlide extends Vue {
		@Prop({ type: Boolean, default: true }) isVisible!: boolean
		@Prop({ type: String, default: "primary" }) type!: "primary" | "secondary"
	}
