import { StorageOptions } from "../types/storage"

import StorageController from "../storage/StorageController"

// Supply a StorageController and corresponding options using session storage.
const storage: Storage = !process.env.SERVER ?
	sessionStorage :
	{
		get length(): number {
			return 0
		},
		clear(): void {
			// No action.
		},
		getItem(_key: string): string | null {
			return null
		},
		key(_index: number): string | null {
			return null
		},
		removeItem(_key: string): void {
			// No action.
		},
		setItem(_key: string, _value: string): void {
			// No action.
		}
	}

export const storageController = new StorageController(storage)

export const storageOptions: StorageOptions = {
	storage: storageController
}
