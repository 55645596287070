import { ActionTree, ActionContext } from "vuex"
import { RootState } from "store/index"
import { Company, Contact, AuthorizeModel } from "./types"
import { api } from "api/index"
import { convertCompany, convertContactDetails, convertIntermediary } from "./conversion"
import { MutationType } from "@lib/model/store/mutations"
import { resources } from "api/resources"

type Context = ActionContext<Company, RootState>

async function storeResponse(context: Context, response: Response): Promise<void> {
	const state: Company | undefined = convertCompany(await response.json())
	if (!!state) {
		context.commit(MutationType.HYDRATE, state)
	}
}

const companyResources = resources.company
const agreementEndpoints = companyResources.agreements

const actions: ActionTree<Company, RootState> = {
	async getCompany(context: Context): Promise<void> {
		const response = await api.get(companyResources.company).response
		await storeResponse(context, response)
	},
	async updateCompanyDetails(context: Context, payload: Company): Promise<void> {
		const response = await api.put(companyResources.company, payload).response
		await storeResponse(context, response)
	},
	async updateContactDetails(context: Context, payload: Contact): Promise<void> {
		const response = await api.put(companyResources.contact, payload).response
		const contact = convertContactDetails(await response.json())

		context.commit(MutationType.HYDRATE, {
			...context.state,
			contact
		})
	},
	async authorizeAdvisor(context: Context, payload: AuthorizeModel): Promise<void> {
		const agreements = context.state.agreements || []
		const agreement = agreements.find(a => a.externalIdentifier === payload.agreementId)

		if (!agreement?.intermediary) {
			return
		}

		const url = agreementEndpoints.authorizeAdvisor(payload.agreementId, payload.intermediaryId)
		const response = await api.put(url, {
			viewParticipants: payload.viewParticipants,
			administrationForEmployer: payload.administrationForEmployer
		}).response

		agreement.intermediary = convertIntermediary(await response.json())

		context.commit(MutationType.HYDRATE, {
			...context.state,
			agreements
		})
	}
}

export default actions
