
	import Vue from "vue"
	import Component from "vue-class-component"

	import { get } from "lodash-es"

	import { Options, Texts } from "@lib/types/i18n"
	import { storageOptions } from "@lib/storage/session"

	@Component
	export default class AccordionLocaleTranslations extends Vue {

		private static readonly LOCALE_KEY = "locale"

		getAccordionLocaleTranslations(baseTranslationKey: string): [] | Texts {
			const localeOptions: Options = storageOptions.storage.retrieve(AccordionLocaleTranslations.LOCALE_KEY)
			if (!localeOptions) {
				return []
			}
			return get(localeOptions.texts, baseTranslationKey) as Texts || {}
		}

		getContent(item: string, baseTranslationkey: string): string {
			const translationKey = `${ baseTranslationkey }.${ item }.explanation`
			const translatedValue = Vue.filter("locale")(translationKey)
			const editedValue = Vue.filter("template")(translatedValue)

			return editedValue
		}
	}
