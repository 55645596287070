import { ActionTree, ActionContext } from "vuex"
import { RootState, namespaces } from "store/index"
import { JwtToken, UsernamePassword } from "@lib/types/security"
import { AuthenticationState } from "./types"
import { MutationType } from "./mutations"
import { save as saveLocale } from "@lib/i18n/session"
import load from "src/i18n/load"
import { AuthenticationMethod, sessionFactory, AUTHENTICATION_METHOD_KEY } from "utils/session-factory"
import { Context } from "vm"
import { AccountActivation } from "api/models/account"
import { USER_MUTATIONS } from "store/user"
import userClient from "api/clients/user-client"
import { ACCESS_DENIED, ACCESS_GRANTED } from "@lib/vue/events"
import eventBus from "@lib/vue/eventBus"
import localStorageClient from "api/clients/local-storage-client"

export interface LoginActionPayload {
	authenticationMethod: AuthenticationMethod
	credentials?: UsernamePassword
}

const actions: ActionTree<AuthenticationState, RootState> = {
	async activateUser(context: Context, payload: AccountActivation): Promise<void> {
		context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_ACTIVATION_STATUS  }`, true, { root: true })
		try {
			userClient.activateUser(payload).then((response) =>  {
				if (response.ok) {
					context.dispatch(`${ namespaces.authentication }/login`, {
						credentials: {
							username: payload.username,
							password: payload.password
						},
						authenticationMethod: AuthenticationMethod.CREDENTIALS
					}, { root: true })
				}
			})
		} finally {
			context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_ACTIVATION_STATUS  }`, false, { root: true })
		}
	},
	async login(_context: ActionContext<AuthenticationState, RootState>, payload: LoginActionPayload): Promise<void> {
		localStorageClient.setItem(AUTHENTICATION_METHOD_KEY, payload.authenticationMethod)
		localStorageClient.setItem("CHECK_IMPERSONATE",  "0")
		await sessionFactory.session.login(payload.credentials)
		await saveLocale(load, "nl-NL")
	},
	async logout(context: ActionContext<AuthenticationState, RootState>): Promise<boolean> {
		await sessionFactory.session.logout()
		context.commit(MutationType.LOGOUT)
		return true
	},
	async impersonate(_context, token: JwtToken): Promise<void> {
		if (sessionFactory.session.impersonate(token.username, token)) {
			eventBus.emit(ACCESS_GRANTED, token.username)
			localStorageClient.setItem(AUTHENTICATION_METHOD_KEY,  AuthenticationMethod.CONTROL_ROOM)
			localStorageClient.setItem("CHECK_IMPERSONATE",  "1")
			await saveLocale(load, "nl-NL")
		} else {
			eventBus.emit(ACCESS_DENIED)
		}
	}
}

export default actions
