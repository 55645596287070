import { AuthorizationStrategy, JwtToken, isJwtToken } from "../../types/security"
import { RequestMethod, Parameters, RequestOptions, ContentType, RequestHeader, Request } from "../../types/request"

import request from "../../request/request"
import HttpStatus from "../../request/status"
import openStream from "../../request/openStream"
import { headers } from "../../request/parameters"

import eventBus from "../../vue/eventBus"
import { AUTHORIZATION_FAILED, ACCESS_DENIED } from "../../vue/events"
import { MessageEventStream } from "../../types/stream"

export default class JwtAuthorization implements AuthorizationStrategy {
	private token: JwtToken | null = null

	constructor(private readonly refreshEndpoint?: string) { }

	get isAuthorized(): boolean {
		return !!this.token
	}

	authorize(data: any): boolean {
		if (isJwtToken(data)) {
			this.token = data
			return true
		}

		return false
	}

	unauthorize(): boolean {
		if (this.isAuthorized) {
			this.token = null
			return true
		}

		return false
	}

	isAuthorizedAll(roles: ReadonlyArray<string>): boolean {
		return this.isAuthorized && roles.every(role => this.token!.roles.includes(role))
	}

	isAuthorizedAny(roles: ReadonlyArray<string>): boolean {
		return this.isAuthorized && roles.some(role => this.token!.roles.includes(role))
	}

	request(method: RequestMethod, resource: string, data?: Parameters, options?: RequestOptions): Request {
		const fetchedRequest = this.fetch(method, resource, data, options)

		return {
			...fetchedRequest,
			response: fetchedRequest.response.then(async response => {
				switch (response.status) {
					case HttpStatus.UNAUTHORIZED: {
						const refreshed = await this.refreshToken()
						if (refreshed) {
							const retry = await this.fetch(method, resource, data).response
							if (retry.ok) {
								return retry
							}
						}
						eventBus.emit(AUTHORIZATION_FAILED)
						break
					}
					case HttpStatus.FORBIDDEN: {
						eventBus.emit(ACCESS_DENIED)
						break
					}
				}

				return response
			})
		}
	}

	private async refreshToken(): Promise<boolean> {
		if (!this.isAuthorized || !this.refreshEndpoint || this.token?.roles.includes("2FA")) {
			return false
		}

		const response = await this.fetch(
			RequestMethod.POST,
			this.refreshEndpoint,
			{
				/* eslint-disable camelcase */
				grant_type: "refresh_token",
				refresh_token: this.token!.refresh_token
				/* eslint-enable camelcase */
			},
			{
				headers: {
					[RequestHeader.CONTENT_TYPE]: ContentType.APPLICATION_FORM_URLENCODED
				},
				cache: "no-cache"
			}
		).response

		if (response.ok) {
			const data = await response.json()
			const token = {
				...this.token,
				...data
			}
			this.authorize(token)
			return true
		}

		return false
	}

	private fetch(method: RequestMethod, resource: string, data?: Parameters, options?: RequestOptions): Request {
		const requestHeaders = headers(options?.headers || new Headers())

		if (this.isAuthorized) {
			requestHeaders.set(RequestHeader.AUTHORIZATION, `${ this.token!.token_type } ${ this.token!.access_token }`)
		}

		if (!requestHeaders.has(RequestHeader.CONTENT_TYPE)) {
			requestHeaders.set(RequestHeader.CONTENT_TYPE, ContentType.APPLICATION_JSON)
		}

		return request(
			method,
			resource,
			data,
			{
				mode: "cors",
				credentials: "omit",
				...options,
				headers: requestHeaders
			}
		)
	}

	openStream(endpoint: string): MessageEventStream {
		// The WebSocket API doesn't support adding the Authorization header.
		const queryString = this.isAuthorized ? `?${ this.token!.token_type }=${ this.token!.access_token }` : ""
		return openStream(`${ endpoint }${ queryString }`)
	}
}
