export enum Add {
	Millis,
	Seconds,
	Minutes,
	Hours,
	Days,
	Weeks,
	Months,
	Quarters,
	Years
}

export default (add: Add, n: number, date: Date): Date => {
	const result = new Date(date)
	switch (add) {
		case Add.Millis:
			result.setMilliseconds(date.getMilliseconds() + n)
			break
		case Add.Seconds:
			result.setSeconds(date.getSeconds() + n)
			break
		case Add.Minutes:
			result.setMinutes(date.getMinutes() + n)
			break
		case Add.Hours:
			result.setHours(date.getHours() + n)
			break
		case Add.Days:
			result.setDate(date.getDate() + n)
			break
		case Add.Weeks:
			result.setDate(date.getDate() + n * 7)
			break
		case Add.Months:
			result.setMonth(date.getMonth() + n)
			break
		case Add.Quarters:
			result.setMonth(date.getMonth() + n * 3)
			break
		case Add.Years:
			result.setFullYear(date.getFullYear() + n)
			break
	}

	return result
}
