
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Emit } from "vue-property-decorator"
	import { HTMLEvent } from "@portal-components/ui/utils/events"

	type Target = "_self" | "_blank" | "_parent" | "_top"

	@Component
	export default class PQuickLink extends Vue {
		@Prop({ type: String, required: true }) href!: string
		@Prop({ type: String, default: "_self" }) target!: Target

		@Emit(HTMLEvent.CLICK)
		onClick(): void {
			return
		}
	}
